import { createSlice } from '@reduxjs/toolkit';

import { branchesActivitiesActions } from '../branchesActivities/branchesActivitiesSlice';
import { covenantsActions } from '../covenants/covenantsSlice';
import { personsActions } from '../persons/personsSlice';
import customersReducers from './customersReducers';

const initialState = {
  listLength: 0,
  detail: null,

  filter: null,
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,

  detailUpdatedOnce: false,
  isDetailLoading: false,
  loading: false,
  customerAddModalOpen: false,
  customerObservationModalOpen: false,
  customerFilterModalOpen: false,
  newBranchActivity: null,
  newCovenant: null,
  subResourceType: null,
  shouldOpenSubResourceModal: false,
  openFileDialog: false,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: customersReducers,
  extraReducers: builder => {
    builder.addCase(
      branchesActivitiesActions.createBranchActivitySuccess,
      (state, action) => {
        const { source, branchActivity } = action.payload;

        if (source === 'CUSTOMER') {
          state.newBranchActivity = branchActivity;
        }
      }
    );
    builder.addCase(covenantsActions.createCovenantSuccess, (state, action) => {
      const { source, covenant } = action.payload;

      if (source === 'CUSTOMER') {
        state.newCovenant = covenant;
      }
    });
    builder.addCase(personsActions.resetDetailsPerson, state => {
      state.customerObservationModalOpen = false;
    });
  },
});

export const { actions: customersActions } = customersSlice;
export default customersSlice.reducer;
