const billsToPayReducers = {
  createBillToPayRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (billToPay, subResourceType = null) => {
      return { payload: { billToPay, subResourceType } };
    },
  },
  createBillToPaySuccess: {
    reducer: state => {
      state.loading = false;
    },
    prepare: billToPay => {
      return { payload: { billToPay } };
    },
  },
  createBillToPayFailure: state => {
    state.loading = false;
  },
  updateBillToPayRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newBillToPay) => {
      return { payload: { id, newBillToPay } };
    },
  },
  updateBillToPaySuccess: {
    reducer: (state, action) => {
      const { newBillToPay } = action.payload;
      state.detail = newBillToPay;

      if (
        !state.detailUpdatedOnce &&
        state.page > 0 &&
        state.listLength === 1
      ) {
        state.page -= 1;
        state.detailUpdatedOnce = true;
      }

      state.billToPayObservationModalOpen = false;
      state.loading = false;
    },
    prepare: newBillToPay => {
      return { payload: { newBillToPay } };
    },
  },
  updateBillToPayFailure: state => {
    state.loading = false;
  },
  settlementBillToPayRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newBillToPay, fetchList = null) => {
      return { payload: { id, newBillToPay, fetchList } };
    },
  },
  settlementBillToPaySuccess: {
    reducer: (state, action) => {
      const { newBillToPay } = action.payload;
      state.detail = newBillToPay;

      if (
        !state.detailUpdatedOnce &&
        state.page > 0 &&
        state.listLength === 1
      ) {
        state.page -= 1;
        state.detailUpdatedOnce = true;
      }

      state.loading = false;

      state.settlementId = null;
      state.billToPaySettlementModalOpen = false;
    },
    prepare: newBillToPay => {
      return { payload: { newBillToPay } };
    },
  },
  settlementBillToPayFailure: state => {
    state.loading = false;
  },
  deleteBillToPayRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ id, fetchList = null, isFromDetail = false }) => {
      return { payload: { id, fetchList, isFromDetail } };
    },
  },
  deleteBillToPaySuccess: {
    reducer: (state, action) => {
      const { isFromDetail } = action.payload;

      if (isFromDetail) {
        if (state.page > 0 && state.listLength === 1) {
          state.page -= 1;
        }
      }

      state.loading = false;
      state.shouldFetchStatisticData = true;
    },
    prepare: (isFromDetail = false) => {
      return { payload: { isFromDetail } };
    },
  },
  deleteBillToPayFailure: state => {
    state.loading = false;
  },
  getDetailsBillToPayRequest: {
    reducer: state => {
      state.detail = null;
      state.isDetailLoading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  getDetailsBillToPaySuccess: {
    reducer: (state, action) => {
      state.detail = action.payload.billToPay;
      state.isDetailLoading = false;
    },
    prepare: billToPay => {
      return { payload: { billToPay } };
    },
  },
  getDetailsBillToPayFailure: () => {},
  resetListBillsToPay: state => {
    state.loading = false;
    state.isDetailLoading = false;
    state.filterSearch = '';
    state.filterColumnsOrder = null;
    state.filter = null;
    state.billToPayFilterModalOpen = false;

    state.shouldFetchStatisticData = false;
    state.rowsPerPage = 10;
    state.page = 0;
    state.listLength = 0;
  },
  resetDetailsBillToPay: state => {
    state.detail = null;
    state.billToPaySettlementModalOpen = false;
    state.billToPayObservationModalOpen = false;
    state.settlementId = null;
    state.sharedDetail = false;
    state.detailUpdatedOnce = false;
  },
  openModalSettlementBillToPay: {
    reducer: (state, action) => {
      state.settlementId = action.payload.id;
      state.sharedDetail = action.payload.sharedDetail;
      state.billToPaySettlementModalOpen = true;
    },
    prepare: (id, sharedDetail) => {
      return { payload: { id, sharedDetail } };
    },
  },
  closeModalSettlementBillToPay: state => {
    state.settlementId = null;
    state.sharedDetail = false;
    state.billToPaySettlementModalOpen = false;
  },
  openModalBillToPayObservation: state => {
    state.billToPayObservationModalOpen = true;
  },
  closeModalBillToPayObservation: state => {
    state.billToPayObservationModalOpen = false;
  },
  openModalBillToPayFilter: state => {
    state.billToPayFilterModalOpen = true;
  },
  closeModalBillToPayFilter: state => {
    state.billToPayFilterModalOpen = false;
  },
  setBillToPayTableFilters: (state, action) => {
    const {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      shouldFetchStatisticData,
      listLength,
    } = action.payload;

    if (typeof listLength !== 'undefined') {
      state.listLength = listLength;
    }

    if (filterColumnsOrder) {
      state.filterColumnsOrder = {
        field: filterColumnsOrder?.field,
        direction: filterColumnsOrder?.direction,
      };
    }

    if (typeof filterSearch !== 'undefined') {
      state.filterSearch = filterSearch;
    }

    if (typeof modalFilters !== 'undefined') {
      state.filter = modalFilters;
    }

    if (typeof rowsPerPage !== 'undefined') {
      state.rowsPerPage = rowsPerPage;
    }

    if (typeof currentPage !== 'undefined') {
      state.page = currentPage;
    }

    if (typeof shouldFetchStatisticData !== 'undefined') {
      state.shouldFetchStatisticData = shouldFetchStatisticData;
    }

    if (closeModal) {
      state.billToPayFilterModalOpen = false;
    }
  },
  setBillToPayInitialTableFilters: state => {
    state.filterSearch = '';
    state.filterColumnsOrder = null;
    state.filter = null;
    state.shouldFetchStatisticData = false;
    state.rowsPerPage = 10;
    state.page = 0;
  },
  setBillToPayIsDetailLoading: state => {
    state.isDetailLoading = true;
  },
  setSavingOnSubResource: {
    reducer: (state, action) => {
      state.shouldOpenSubResourceModal = true;
      state.subResourceType = action.payload.subResourceType;
    },
    prepare: subResourceType => {
      return { payload: { subResourceType } };
    },
  },
  resetSubResourceData: state => {
    state.shouldOpenSubResourceModal = false;
    state.subResourceType = null;
  },
};

export default billsToPayReducers;
