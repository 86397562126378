import { Box, styled } from '@mui/material';

export const Root = styled(Box)(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

export const AppContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const Content = styled(Box)(({ theme }) => ({
  transition: 'all 0.3s',
  flex: 1,
  backgroundColor: theme.body.dashboard.background,
  paddingTop: '3rem',
  paddingBottom: '2rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    // maxWidth: 1200,
    margin:
      !['/catalog', '/customer', '/customers'].some(path =>
        window.location.pathname.includes(path)
      ) && 'auto',
    maxWidth:
      !['/catalog', '/customer', '/customers'].some(path =>
        window.location.pathname.includes(path)
      ) && 1200,
    padding:
      window.location.pathname === '/catalog'
        ? '0px'
        : ['/customer', '/customers'].some(path =>
            window.location.pathname.includes(path)
          ) && '32px',
  },
  [theme.breakpoints.down(1550)]: {
    paddingLeft: window.location.pathname === '/catalog' ? '0rem' : '1.5rem',
    paddingRight: window.location.pathname === '/catalog' ? '0rem' : '1.5rem',
  },
}));
