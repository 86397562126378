const companyProfileReducers = {
  addFilterCompanyProfile: {
    reducer: (state, action) => {
      const { type } = action.payload;

      const findEqualType = state.filter.find(
        item => item.value === type.value
      );

      if (!findEqualType) {
        state.filter.push(type);
      }

      if (type.replaceValues) {
        const filteredByType = state.filter.filter(
          item => item.fromGroup !== type.fromGroup
        );

        state.filter = [...filteredByType, type];
      }
    },
    prepare: type => {
      return { payload: { type } };
    },
  },
  removeFilterCompanyProfile: {
    reducer: (state, action) => {
      const { type } = action.payload;

      state.filter = state.filter.filter(item => item.value !== type);
    },
    prepare: type => {
      return { payload: { type } };
    },
  },
  addMultipleProductsCounter: {
    reducer: (state, action) => {
      const { list } = action.payload;
      const productDict = {};
      list.forEach(product => {
        productDict[product.product.id] = {
          count: product.quantity,
          price: product.product.promotional_price || product.unit_value,
          title: product.description,
          ...product.product,
        };
      });

      state.productsCounter = productDict;
      state.redirectToCart = true; // Adiciona a flag
    },
    prepare: list => {
      return { payload: { list } };
    },
  },
  clearRedirectToCart: {
    reducer: state => {
      state.redirectToCart = false; // Reseta a flag
    },
  },
  addProductCounter: {
    reducer: (state, action) => {
      const { type, value } = action.payload;

      const productDict = {
        ...state.productsCounter,
      };
      if (!productDict[type.id]) {
        productDict[type.id] = {
          count: 0,
        };
      }
      productDict[type.id] = {
        ...type,
        count: value,
      };
      state.productsCounter = productDict;
    },
    prepare: (type, value) => {
      return { payload: { type, value } };
    },
  },
  removeProductCounter: {
    reducer: (state, action) => {
      const { type } = action.payload;

      const productDict = {
        ...state.productsCounter,
      };
      if (!productDict[type.id]) {
        productDict[type.id] = {
          count: 0,
        };
      }

      delete productDict[type.id];

      state.productsCounter = productDict;
    },
    prepare: type => {
      return { payload: { type } };
    },
  },
  setProductCounter: {
    reducer: (state, action) => {
      const { list } = action.payload;

      const productDict = {
        ...state.productsCounter,
      };

      Object.keys(productDict).forEach(key => {
        if (!list.some(product => product.product_id === key)) {
          delete productDict[key];
        }
      });
      list.forEach(item => {
        productDict[item.product_id] = {
          ...productDict[item.product_id],
          count: item.quantity,
        };
      });

      state.productsCounter = productDict;
    },
    prepare: list => {
      return { payload: { list } };
    },
  },
  clearProductCounter: {
    reducer: state => {
      state.productsCounter = {};
    },
    prepare: () => {
      return { payload: { clear: true } };
    },
  },
};

export default companyProfileReducers;
