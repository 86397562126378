const emailsReducers = {
  getEmails: {
    reducer: (state, action) => {
      state.list = action.payload.emails;
    },
    prepare: emails => {
      return { payload: { emails } };
    },
  },
  createEmailRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, email, person_route) => {
      return { payload: { person_id, email, person_route } };
    },
  },
  createEmailSuccess: {
    reducer: (state, action) => {
      const { is_main } = action.payload.email;

      if (is_main) {
        state.list = state.list.map(email => ({ ...email, is_main: false }));
      }

      state.list.unshift(action.payload.email);
      state.loading = false;
      state.emailModalOpen = false;
    },
    prepare: email => {
      return { payload: { email } };
    },
  },
  createEmailFailure: state => {
    state.loading = false;
  },
  updateEmailRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, email_id, newEmail, person_route) => {
      return { payload: { person_id, email_id, newEmail, person_route } };
    },
  },
  updateEmailSuccess: {
    reducer: (state, action) => {
      const { newEmail } = action.payload;
      const { is_main } = newEmail;
      let data = [];

      if (is_main) {
        const removeMain = state.list.map(email => ({
          ...email,
          is_main: false,
        }));

        data = removeMain;
      } else {
        data = state.list;
      }

      state.list = data.map(email =>
        email.id === newEmail.id ? newEmail : email
      );

      state.detail = null;
      state.loading = false;
      state.emailModalOpen = false;
    },
    prepare: newEmail => {
      return { payload: { newEmail } };
    },
  },
  updateEmailFailure: state => {
    state.loading = false;
  },
  deleteEmailRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, email_id, person_route) => {
      return { payload: { person_id, email_id, person_route } };
    },
  },
  deleteEmailSuccess: {
    reducer: (state, action) => {
      const { email_id } = action.payload;

      state.list = state.list.filter(email => email.id !== email_id);

      if (state.detail?.id && email_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: email_id => {
      return { payload: { email_id } };
    },
  },
  deleteEmailFailure: state => {
    state.loading = false;
  },
  resetListEmail: state => {
    state.list = [];
  },
  openModalEmail: {
    reducer: (state, action) => {
      const { email_id } = action.payload;

      state.detail = state.list.find(email => email.id === email_id) || null;

      state.emailModalOpen = true;
    },
    prepare: email_id => {
      return { payload: { email_id } };
    },
  },
  closeModalEmail: state => {
    state.emailModalOpen = false;
    state.detail = null;
  },
};

export default emailsReducers;
