import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import historyService from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { billToReceiveBilletsActions } from '../billToReceiveBillets/billToReceiveBilletsSlice';
import { billToReceiveChartAccountsActions } from '../billToReceiveChartAccounts/billToReceiveChartAccountsSlice';
import { billToReceivePixsActions } from '../billToReceivePixs/billToReceivePixsSlice';
import { billsToReceiveActions } from './billsToReceiveSlice';

export function* createBillToReceive({ payload }) {
  try {
    const { billToReceive, subResourceType } = payload;

    const { data } = yield call(api.post, 'bills', billToReceive);

    yield put(billsToReceiveActions.createBillToReceiveSuccess(data));

    yield put(billsToReceiveActions.setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    historyService.replace(`/bill-to-receive/${data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(billsToReceiveActions.createBillToReceiveFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToReceive({ payload }) {
  try {
    const { id, newBillToReceive } = payload;

    const { data } = yield call(api.put, `bills/${id}`, newBillToReceive);

    yield put(billsToReceiveActions.updateBillToReceiveSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(billsToReceiveActions.updateBillToReceiveFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* settlementBillToReceive({ payload }) {
  try {
    const { id, newBillToReceive, fetchList } = payload;

    const { data } = yield call(api.put, `bills/${id}`, newBillToReceive);

    yield put(billsToReceiveActions.settlementBillToReceiveSuccess(data));

    const { listLength } = yield select(state => state.billsToReceive);

    if (fetchList) {
      yield put(
        billsToReceiveActions.setBillToReceiveTableFilters({
          shouldFetchStatisticData: true,
        })
      );

      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(billsToReceiveActions.settlementBillToReceiveFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export function* deleteBillToReceive({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `bills/${id}`);

    yield put(billsToReceiveActions.deleteBillToReceiveSuccess(isFromDetail));

    historyService.push('/bills-to-receive');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.billsToReceive);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(billsToReceiveActions.deleteBillToReceiveFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsBillToReceive({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `bills/${id}`);

    const { chart_accounts, pixs, billets, ...rest } = data;

    const {
      billToReceiveSettlementModalOpen,
      subResourceType,
      shouldOpenSubResourceModal,
    } = yield select(state => state.billsToReceive);

    if (!billToReceiveSettlementModalOpen) {
      yield put(
        billToReceiveChartAccountsActions.getBillToReceiveChartAccounts(
          chart_accounts
        )
      );
    }
    yield put(billToReceivePixsActions.getBillToReceivePixs(pixs));

    yield put(billToReceiveBilletsActions.getBillToReceiveBillets(billets));

    yield put(billsToReceiveActions.getDetailsBillToReceiveSuccess(rest));

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'CHART_ACCOUNT') {
        yield put(
          billToReceiveChartAccountsActions.openModalBillToReceiveChartAccount()
        );
      }

      yield put(billsToReceiveActions.resetSubResourceData());
    }
  } catch (err) {
    yield put(billsToReceiveActions.getDetailsBillToReceiveFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(
    billsToReceiveActions.createBillToReceiveRequest,
    createBillToReceive
  ),
  takeLatest(
    billsToReceiveActions.updateBillToReceiveRequest,
    updateBillToReceive
  ),
  takeLatest(
    billsToReceiveActions.settlementBillToReceiveRequest,
    settlementBillToReceive
  ),
  takeLatest(
    billsToReceiveActions.deleteBillToReceiveRequest,
    deleteBillToReceive
  ),
  takeLatest(
    billsToReceiveActions.getDetailsBillToReceiveRequest,
    getDetailsBillToReceive
  ),
]);
