import { createSlice } from '@reduxjs/toolkit';

import phonesReducers from './phonesReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  phoneModalOpen: false,
};

const phonesSlice = createSlice({
  name: 'phones',
  initialState,
  reducers: phonesReducers,
});

export const { actions: phonesActions } = phonesSlice;
export default phonesSlice.reducer;
