const contactsReducers = {
  getContactsRequest: state => {
    state.loading = true;
  },
  getContactsSuccess: {
    reducer: (state, action) => {
      const { contacts } = action.payload;
      state.list = contacts;
      state.loading = false;
    },
    prepare: ({ contacts }) => {
      return {
        payload: {
          contacts,
        },
      };
    },
  },
  getContactsFailure: state => {
    state.loading = false;
  },

  getDetailsContactRequest: {
    reducer: state => {
      state.contactDetailModalLoading = true;
      state.detail = null;
    },
    prepare: id => {
      return {
        payload: {
          id,
        },
      };
    },
  },
  getDetailsContactSuccess: {
    reducer: (state, action) => {
      const { contact } = action.payload;

      state.detail = contact;
      state.contactDetailModalLoading = false;
    },
    prepare: ({ contact }) => {
      return {
        payload: {
          contact,
        },
      };
    },
  },
  getDetailsContactFailure: state => {
    state.contactDetailModalLoading = false;
  },

  resetDetailsContact: state => {
    state.loading = false;
    state.detail = null;
    state.detailModalSource = '';
    state.contactDetailModalOpen = false;
    state.contactDetailModalLoading = false;
  },
  resetContactList: state => {
    state.list = [];
    state.contactModalOpen = false;
  },

  openModalContact: state => {
    state.contactModalOpen = true;
  },
  closeModalContact: state => {
    state.contactModalOpen = false;
  },

  openModalDetailContact: {
    reducer: (state, action) => {
      const { source } = action.payload;

      state.contactDetailModalOpen = true;
      state.detailModalSource = source;
    },
    prepare: source => {
      return {
        payload: {
          source,
        },
      };
    },
  },
  closeModalDetailContact: state => {
    state.detailModalSource = '';
    state.detail = null;
    state.contactDetailModalOpen = false;
  },
};

export default contactsReducers;
