import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import { formatUrl } from '~/utils/formats';
import IntlMessages from '~/utils/IntlMessages';

import { companyActions } from './companySlice';

// TODO: Criar os reducers auxiliares da company (file, email, phone)
// TODO: Adicionar o reset details
export function* getCompanies({ payload }) {
  try {
    const { types } = payload;

    let url = 'companies';

    if (types) {
      url = formatUrl(`companies?type=[${types}]`);
    }

    const { data } = yield call(api.get, url);

    yield put(companyActions.getCompaniesSuccess({ companies: data }));
  } catch (err) {
    yield put(companyActions.getCompaniesFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getCompanyDetails({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `companies/${id}`);

    yield put(companyActions.getDetailsCompanySuccess(data));
  } catch (err) {
    yield put(companyActions.getDetailsCompanyFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest('companies/getCompaniesRequest', getCompanies),
  takeLatest('companies/getCompanyDetailRequest', getCompanyDetails),
]);
