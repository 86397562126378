const billToReceivePixsReducers = {
  getBillToReceivePixs: {
    reducer: (state, action) => {
      state.list = action.payload.pixs;
    },
    prepare: pixs => {
      return { payload: { pixs } };
    },
  },
  createBillToReceivePixRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (pix, pixType) => {
      return { payload: { pix, pixType } };
    },
  },
  createBillToReceivePixSuccess: {
    reducer: (state, action) => {
      const { pix, pixType } = action.payload;

      state.list.unshift(pix);

      state.detail = pix;

      if (pixType === 'DYNAMIC') {
        state.showNextPixRequestMessage = true;
        state.billToReceivePixModalOpen = true;
      } else if (pixType === 'STATIC') {
        state.selectedAccount = null;

        if (pix.status === 'ACTIVE') {
          state.billToReceivePixShareModalOpen = true;
          state.billToReceivePixModalOpen = false;
        } else {
          state.billToReceivePixDetailModalOpen = true;
          state.billToReceivePixModalOpen = false;
        }
      }

      state.loading = false;
    },
    prepare: (pix, pixType) => {
      return { payload: { pix, pixType } };
    },
  },
  createBillToReceivePixFailure: state => {
    state.loading = false;
  },
  updateBillToReceivePixRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: pix_id => {
      return { payload: { pix_id } };
    },
  },
  updateBillToReceivePixSuccess: {
    reducer: (state, action) => {
      const { newPix } = action.payload;

      state.list = state.list.map(pix => (pix.id === newPix.id ? newPix : pix));

      state.detail = newPix;
      state.loading = false;
    },
    prepare: newPix => {
      return { payload: { newPix } };
    },
  },
  updateBillToReceivePixFailure: state => {
    state.loading = false;
  },
  settlementBillToReceivePixRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (pix_id, newPix) => {
      return { payload: { pix_id, newPix } };
    },
  },
  settlementBillToReceivePixSuccess: {
    reducer: (state, action) => {
      const { newPix } = action.payload;

      state.detail = null;
      state.list = state.list.map(pix => (pix.id === newPix.id ? newPix : pix));

      state.loading = false;

      state.billToReceivePixSettlementModalOpen = false;
    },
    prepare: newPix => {
      return { payload: { newPix } };
    },
  },
  settlementBillToReceivePixFailure: state => {
    state.loading = false;
  },
  deleteBillToReceivePixRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: pix_id => {
      return { payload: { pix_id } };
    },
  },
  deleteBillToReceivePixSuccess: {
    reducer: (state, action) => {
      const { pix_id } = action.payload;

      state.list = state.list.filter(pix => pix.id !== pix_id);

      if (state.detail?.id && pix_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: pix_id => {
      return { payload: { pix_id } };
    },
  },
  deleteBillToReceivePixFailure: state => {
    state.loading = false;
  },
  resetListBillToReceivePix: state => {
    state.list = [];
  },
  openModalBillToReceivePix: {
    reducer: (state, action) => {
      const { id } = action.payload;

      state.detail = state.list.find(pix => pix.id === id) || null;

      state.billToReceivePixModalOpen = true;
    },
    prepare: ({ id, type }) => {
      return { payload: { id, type } };
    },
  },
  closeModalBillToReceivePix: state => {
    if (state.isCreatingPixFromList) {
      state.list = [];
      state.isCreatingPixFromList = false;
    }

    state.billToReceivePixModalOpen = false;
    state.showNextPixRequestMessage = false;
    state.detail = null;
    state.selectedAccount = null;
  },
  openModalBillToReceiveAddAccountPix: {
    reducer: (state, action) => {
      state.billToReceiveAddAccountModalOpen = true;

      if (action.payload.isCreatingPixFromList) {
        state.isCreatingPixFromList = true;
      }
    },
    prepare: (billData, isCreatingPixFromList) => {
      return { payload: { billData, isCreatingPixFromList } };
    },
  },
  closeModalBillToReceiveAddAccountPix: state => {
    state.billToReceiveAddAccountModalOpen = false;
    state.isCreatingPixFromList = false;
  },
  setSelectedAccountBillToReceivePix: {
    reducer: (state, action) => {
      state.selectedAccount = action.payload.account;
      state.billToReceiveAddAccountModalOpen = false;
      state.billToReceivePixModalOpen = true;
    },
    prepare: account => {
      return { payload: { account } };
    },
  },
  openModalBillToReceivePixDetail: {
    reducer: (state, action) => {
      const { id, pix: pixData } = action.payload;

      state.detail = null;

      if (pixData) {
        state.detail = pixData;

        state.list = state.list.map(pix =>
          pix.id === pixData.id ? pixData : pix
        );
      } else if (id) {
        state.detail = state.list.find(pix => pix.id === id) || null;
      }

      state.showNextPixRequestMessage = false;
      state.billToReceivePixModalOpen = false;
      state.billToReceivePixDetailModalOpen = true;
      state.selectedAccount = null;
    },
    prepare: (id, pix = null) => {
      return { payload: { id, pix } };
    },
  },
  closeModalBillToReceivePixDetail: state => {
    if (state.isCreatingPixFromList) {
      state.list = [];
      state.isCreatingPixFromList = false;
    }

    state.billToReceivePixDetailModalOpen = false;
    state.detail = null;
    state.selectedAccount = null;
  },
  openModalBillToReceivePixShare: {
    reducer: (state, action) => {
      const { id, pix: pixData } = action.payload;

      state.detail = null;

      if (pixData) {
        state.detail = pixData;

        state.list = state.list.map(pix =>
          pix.id === pixData.id ? pixData : pix
        );
      } else if (id) {
        state.detail = state.list.find(pix => pix.id === id) || null;
      }

      state.showNextPixRequestMessage = false;
      state.billToReceivePixModalOpen = false;
      state.billToReceivePixShareModalOpen = true;
      state.selectedAccount = null;
    },
    prepare: (id, pix = null) => {
      return { payload: { id, pix } };
    },
  },
  closeModalBillToReceivePixShare: state => {
    if (state.isCreatingPixFromList) {
      state.list = [];
      state.isCreatingPixFromList = false;
    }

    state.billToReceivePixShareModalOpen = false;
    state.detail = null;
    state.selectedAccount = null;
  },
  openModalBillToReceivePixSettlement: {
    reducer: (state, action) => {
      const { id } = action.payload;

      state.detail = null;

      if (id) {
        state.detail = state.list.find(pix => pix.id === id) || null;
      }

      state.billToReceivePixSettlementModalOpen = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  closeModalBillToReceivePixSettlement: state => {
    state.billToReceivePixSettlementModalOpen = false;
    state.detail = null;
    state.selectedAccount = null;
  },
};

export default billToReceivePixsReducers;
