import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { configureStore } from '@reduxjs/toolkit';

import reactotron from '~/config/ReactotronConfig';

import rootReducer from './modules/rootReducer';
// eslint-disable-next-line import/no-cycle
import rootSaga from './modules/rootSagas';
import persistReducer from './persistReducers';

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? reactotron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

function setStoreMiddlewares(getDefaultMiddleware) {
  const middleware = getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(sagaMiddleware);

  return middleware;
}

const enhancer =
  process.env.NODE_ENV === 'development' ? [reactotron.createEnhancer()] : null;

const store = configureStore({
  reducer: persistReducer(rootReducer),
  middleware: getDefaultMiddleware => setStoreMiddlewares(getDefaultMiddleware),
  enhancers: enhancer,
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
