import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { billToReceiveChartAccountsActions } from './billToReceiveChartAccountsSlice';

// TODO: Posteriormente, remover a condição para poder apenas adicionar um elemento
export function* createBillToReceiveChartAccount({ payload }) {
  try {
    const { bill_to_receive_id, chartAccount } = payload;

    const chartAccounts = yield select(
      state => state.billToReceiveChartAccounts.list
    );

    if (chartAccounts.length > 0) {
      yield put(
        billToReceiveChartAccountsActions.createBillToReceiveChartAccountFailure()
      );

      toast.error(
        <IntlMessages id="bills.chart-accounts.error-chart-account-create" />
      );

      return;
    }

    const { data } = yield call(
      api.post,
      `bills/${bill_to_receive_id}/chart-accounts`,
      chartAccount
    );

    yield put(
      billToReceiveChartAccountsActions.createBillToReceiveChartAccountSuccess(
        data
      )
    );

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(
      billToReceiveChartAccountsActions.createBillToReceiveChartAccountFailure()
    );

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToReceiveChartAccount({ payload }) {
  try {
    const { bill_to_receive_id, chart_account_id, newChartAccount } = payload;

    const { data } = yield call(
      api.put,
      `bills/${bill_to_receive_id}/chart-accounts/${chart_account_id}`,
      newChartAccount
    );

    yield put(
      billToReceiveChartAccountsActions.updateBillToReceiveChartAccountSuccess(
        data
      )
    );

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(
      billToReceiveChartAccountsActions.updateBillToReceiveChartAccountFailure()
    );

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBillToReceiveChartAccount({ payload }) {
  try {
    const { bill_to_receive_id, chart_account_id } = payload;

    yield call(
      api.delete,
      `bills/${bill_to_receive_id}/chart-accounts/${chart_account_id}`
    );

    yield put(
      billToReceiveChartAccountsActions.deleteBillToReceiveChartAccountSuccess(
        chart_account_id
      )
    );

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(
      billToReceiveChartAccountsActions.deleteBillToReceiveChartAccountFailure()
    );

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    billToReceiveChartAccountsActions.createBillToReceiveChartAccountRequest,
    createBillToReceiveChartAccount
  ),
  takeLatest(
    billToReceiveChartAccountsActions.updateBillToReceiveChartAccountRequest,
    updateBillToReceiveChartAccount
  ),
  takeLatest(
    billToReceiveChartAccountsActions.deleteBillToReceiveChartAccountRequest,
    deleteBillToReceiveChartAccount
  ),
]);
