const billsToReceiveReducers = {
  createBillToReceiveRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (billToReceive, subResourceType = null) => {
      return { payload: { billToReceive, subResourceType } };
    },
  },
  createBillToReceiveSuccess: {
    reducer: state => {
      state.loading = false;
    },
    prepare: billToReceive => {
      return { payload: { billToReceive } };
    },
  },
  createBillToReceiveFailure: state => {
    state.loading = false;
  },
  updateBillToReceiveRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newBillToReceive) => {
      return { payload: { id, newBillToReceive } };
    },
  },
  updateBillToReceiveSuccess: {
    reducer: (state, action) => {
      const { newBillToReceive } = action.payload;
      state.detail = newBillToReceive;

      if (
        !state.detailUpdatedOnce &&
        state.page > 0 &&
        state.listLength === 1
      ) {
        state.page -= 1;
        state.detailUpdatedOnce = true;
      }

      state.billToReceiveObservationModalOpen = false;
      state.loading = false;
    },
    prepare: newBillToReceive => {
      return { payload: { newBillToReceive } };
    },
  },
  updateBillToReceiveFailure: state => {
    state.loading = false;
  },
  settlementBillToReceiveRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newBillToReceive, fetchList) => {
      return { payload: { id, newBillToReceive, fetchList } };
    },
  },
  settlementBillToReceiveSuccess: {
    reducer: (state, action) => {
      const { newBillToReceive } = action.payload;
      state.detail = newBillToReceive;

      if (
        !state.detailUpdatedOnce &&
        state.page > 0 &&
        state.listLength === 1
      ) {
        state.page -= 1;
        state.detailUpdatedOnce = true;
      }

      state.loading = false;

      state.settlementId = null;
      state.billToReceiveSettlementModalOpen = false;
    },
    prepare: newBillToReceive => {
      return { payload: { newBillToReceive } };
    },
  },
  settlementBillToReceiveFailure: state => {
    state.loading = false;
  },
  deleteBillToReceiveRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ id, fetchList = null, isFromDetail = false }) => {
      return {
        payload: {
          id,
          fetchList,
          isFromDetail,
        },
      };
    },
  },
  deleteBillToReceiveSuccess: {
    reducer: (state, action) => {
      const { isFromDetail } = action.payload;

      if (isFromDetail) {
        if (state.page > 0 && state.listLength === 1) {
          state.page -= 1;
        }
      }

      state.loading = false;
      state.shouldFetchStatisticData = true;
    },
    prepare: (isFromDetail = false) => {
      return { payload: { isFromDetail } };
    },
  },
  deleteBillToReceiveFailure: state => {
    state.loading = false;
  },
  getDetailsBillToReceiveRequest: {
    reducer: state => {
      state.detail = null;
      state.isDetailLoading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  getDetailsBillToReceiveSuccess: {
    reducer: (state, action) => {
      state.detail = action.payload.billToReceive;
      state.isDetailLoading = false;
    },
    prepare: billToReceive => {
      return { payload: { billToReceive } };
    },
  },
  getDetailsBillToReceiveFailure: () => {},
  resetListBillsToReceive: state => {
    state.loading = false;
    state.isDetailLoading = false;
    state.isCreatingPixFromList = false;
    state.filterSearch = '';
    state.filterColumnsOrder = null;
    state.filter = null;
    state.billToReceiveFilterModalOpen = false;
    state.creatingFrom = '';

    state.shouldFetchStatisticData = false;
    state.rowsPerPage = 10;
    state.page = 0;
    state.listLength = 0;
  },
  resetDetailsBillToReceive: state => {
    state.detail = null;
    state.settlementId = null;
    state.sharedDetail = false;
    state.billToReceiveSettlementModalOpen = false;
    state.billToReceiveObservationModalOpen = false;
    state.detailUpdatedOnce = false;
  },
  openModalSettlementBillToReceive: {
    reducer: (state, action) => {
      state.settlementId = action.payload.id;
      state.sharedDetail = action.payload.sharedDetail;
      state.billToReceiveSettlementModalOpen = true;
    },
    prepare: (id, sharedDetail) => {
      return { payload: { id, sharedDetail } };
    },
  },
  closeModalSettlementBillToReceive: state => {
    state.settlementId = null;
    state.sharedDetail = false;

    state.billToReceiveSettlementModalOpen = false;
  },
  openModalBillToReceiveObservation: state => {
    state.billToReceiveObservationModalOpen = true;
  },
  closeModalBillToReceiveObservation: state => {
    state.billToReceiveObservationModalOpen = false;
  },
  openModalBillToReceiveFilter: state => {
    state.billToReceiveFilterModalOpen = true;
  },
  closeModalBillToReceiveFilter: state => {
    state.billToReceiveFilterModalOpen = false;
  },
  setBillToReceiveTableFilters: {
    reducer: (state, action) => {
      const {
        modalFilters,
        filterColumnsOrder,
        filterSearch,
        closeModal,
        rowsPerPage,
        currentPage,
        shouldFetchStatisticData,
        listLength,
      } = action.payload;

      if (state.creatingFrom) {
        state.creatingFrom = '';
      }

      if (typeof listLength !== 'undefined') {
        state.listLength = listLength;
      }

      if (filterColumnsOrder) {
        state.filterColumnsOrder = {
          field: filterColumnsOrder?.field,
          direction: filterColumnsOrder?.direction,
        };
      }

      if (typeof filterSearch !== 'undefined') {
        state.filterSearch = filterSearch;
      }

      if (typeof modalFilters !== 'undefined') {
        state.filter = modalFilters;
      }

      if (typeof rowsPerPage !== 'undefined') {
        state.rowsPerPage = rowsPerPage;
      }

      if (typeof currentPage !== 'undefined') {
        state.page = currentPage;
      }

      if (typeof shouldFetchStatisticData !== 'undefined') {
        state.shouldFetchStatisticData = shouldFetchStatisticData;
      }

      if (closeModal) {
        state.billToReceiveFilterModalOpen = false;
      }
    },
    prepare: ({
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal = false,
      rowsPerPage,
      currentPage,
      shouldFetchStatisticData = false,
      listLength,
    }) => {
      return {
        payload: {
          modalFilters,
          filterColumnsOrder,
          filterSearch,
          closeModal,
          rowsPerPage,
          currentPage,
          shouldFetchStatisticData,
          listLength,
        },
      };
    },
  },
  setBillToReceiveInitialTableFilters: state => {
    state.filterSearch = '';
    state.filterColumnsOrder = null;
    state.filter = null;
    state.shouldFetchStatisticData = false;
    state.rowsPerPage = 10;
    state.page = 0;
  },
  setBillToReceiveIsDetailLoading: state => {
    state.isDetailLoading = true;
  },
  setSavingOnSubResource: {
    reducer: (state, action) => {
      state.shouldOpenSubResourceModal = true;
      state.subResourceType = action.payload.subResourceType;
    },
    prepare: subResourceType => {
      return { payload: { subResourceType } };
    },
  },
  resetSubResourceData: state => {
    state.shouldOpenSubResourceModal = false;
    state.subResourceType = null;
  },
  setCreatingFromBillToReceive: {
    reducer: (state, action) => {
      const { creatingFrom } = action.payload;
      state.creatingFrom = creatingFrom;
    },
    prepare: creatingFrom => {
      return { payload: { creatingFrom } };
    },
  },
};

export default billsToReceiveReducers;
