import { createSlice } from '@reduxjs/toolkit';

import addressesReducers from './addressesReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  addressModalOpen: false,
};

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: addressesReducers,
});

export const { actions: addressesActions } = addressesSlice;
export default addressesSlice.reducer;
