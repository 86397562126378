import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import { store } from '~/store';
import { authActions } from '~/store/modules/auth/authSlice';
import { isShownAPIErrorMessages } from '~/utils/errorMessages';

/* TODO: Pegar o codigo da empresa ao logar ou criar uma empresa, melhorar a ideia,
   fazer um jeito de inteceptar no redux como foi feito no token  */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/** TODO: Fazer um tratamento de retentativas mas so tem que ser erro
 * 500 quando nao consegue acessar o servidor, os erros internos teria
 * que ser so uma tentativa mesmo. E tambem teria que aparecer somente
 * a mensagem na ultima passagem no interceptor response, mas nao foi
 * encontrado uma propriedade para isso
axiosRetry(api, { retries: 3 });
*/
let isRefreshing = false;
let failedRequestsQueue = [];

api.interceptors.request.use(config => {
  const { company } = store.getState().auth;

  const headers = { ...config.headers };

  if (headers.isPublicRoute) {
    delete headers.isPublicRoute;
  } else if (company?.id) {
    headers.COMPANY = company.id;
  }

  headers.LANGUAGE = 'pt-BR';

  headers.TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const smartCommerceToolId = 3;

  // headers.TOOL_ID = smartCommerceToolId;
  return { ...config, headers };
});

api.interceptors.response.use(
  response => {
    /** TODO: retirar - Do something with response data */
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      if (error?.response?.data?.code === 'token.expired') {
        const { refreshToken } = store.getState().auth;
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post('/refresh-session?type=WEB', { refresh_token: refreshToken })
            .then(response => {
              const { token } = response.data;

              store.dispatch(authActions.setRefreshToken({ token }));

              api.defaults.headers.Authorization = `Bearer ${token}`;

              failedRequestsQueue.forEach(request => request.onSuccess(token));

              failedRequestsQueue = [];
            })
            .catch(err => {
              failedRequestsQueue.forEach(request => request.onFailure(err));

              failedRequestsQueue = [];

              store.dispatch(authActions.signOut());
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: token => {
              originalConfig.headers.Authorization = `Bearer ${token}`;

              resolve(api(originalConfig));
            },
            onFailure: err => {
              reject(err);
            },
          });
        });
      }

      store.dispatch(authActions.signOut());
    }

    const isShown = isShownAPIErrorMessages(error);

    const errorData = {
      ...error,
      isShownAPIErrorMessages: isShown,
    };

    return Promise.reject(errorData);
  }
);

export default api;
