import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { phonesActions } from './phonesSlice';

export function* createPhone({ payload }) {
  try {
    const { phone } = payload;

    // const { data } = yield call(
    //   api.post,
    //   `${person_route}/${person_id}/phones`,
    //   phone
    // );

    yield put(phonesActions.createPhoneSuccess(phone));

    // toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(phonesActions.createPhoneFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updatePhone({ payload }) {
  try {
    const { person_id, phone_id, newPhone, person_route } = payload;

    const { data } = yield call(
      api.put,
      `${person_route}/${person_id}/phones/${phone_id}`,
      newPhone
    );

    yield put(phonesActions.updatePhoneSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(phonesActions.updatePhoneFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePhone({ payload }) {
  const { person_id, phone_id, person_route } = payload;

  const phones = yield select(state => state.phones.list);

  const phone = phones.find(data => data.id === phone_id);

  if (phone?.is_main && phones.length > 1) {
    yield put(phonesActions.deletePhoneFailure());

    toast.error(<IntlMessages id="phones.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `${person_route}/${person_id}/phones/${phone_id}`);

    yield put(phonesActions.deletePhoneSuccess(phone_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(phonesActions.deletePhoneFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default function* phonesSagas() {
  yield all([
    takeLatest(phonesActions.createPhoneRequest, createPhone),
    takeLatest(phonesActions.updatePhoneRequest, updatePhone),
    takeLatest(phonesActions.deletePhoneRequest, deletePhone),
  ]);
}
