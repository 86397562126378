module.exports = {
  // Addresses
  'addresses.title': 'Addresses',
  'addresses.new-title': 'New address',
  'addresses.edit-title': 'Edit address',

  'addresses.postal-code': 'Postal code',
  'addresses.address': 'Address',
  'addresses.number': 'Number',
  'addresses.complement': 'Complement',
  'addresses.neighborhood': 'Neighborhood',
  'addresses.city': 'City',
  'addresses.state': 'State',

  'addresses.error-postal-code':
    'Something went wrong while we were fetching your postal code info! 🤔',

  'addresses.type': 'Type',
  'addresses.types.general': 'General',
  'addresses.types.billing': 'Billing',
  'addresses.types.delivery': 'Delivery',
  'addresses.types.branch-office': 'Branch office',
  'addresses.types.headquarters': 'Headquarters',
  'addresses.types.residential': 'Residential',
  'addresses.types.work': 'Work',

  'addresses.is-main': 'Main',
  'addresses.is-main-address': 'Main address',
  'addresses.error-delete-is-main':
    'Main address cannot be deleted when you have more than one! 🤔',
  'addresses.google-maps': 'View in Google Maps',

  // Buttons
  'buttons.add': 'New',
  'buttons.save': 'Save',
  'buttons.cancel': 'Cancel',
  'buttons.ok': 'OK',
  'buttons.close': 'Close',
  'buttons.clear': 'Clear',
  'buttons.delete': 'Delete',
  'buttons.settlement': 'Settlement',
  'buttons.hide': 'Hide',
  'buttons.go-back': 'Go back',
  'buttons.change': 'Change',
  'buttons.continue': 'Continue',
  'buttons.print': 'Print',
  'buttons.copy': 'Copy',
  'buttons.update': 'Update',
  'buttons.select': 'Select',
  'buttons.download-app': 'Download App',
  'buttons.share': 'Share',
  'buttons.catalog': 'Catalog',
  'buttons.filter': 'Filter',
  'buttons.recalculate': 'Recalculate',
  'buttons.send': 'Send',
  'buttons.reset': 'Reset',
  'buttons.remove': 'Remove',
  'buttons.view': 'View',
  'buttons.search': 'Search',
  'buttons.save-and-new': 'Save and new',
  'buttons.confirm': 'Confirm',
  'buttons.agreed': 'Agreed',
  'buttons.register': 'Register',

  'buttons.configure': 'Configure',
  'buttons.generate-report': 'Generate report',
  'buttons.tooltip.cancel-changes': 'Cancel changes',
  'buttons.download': 'Download',
  'buttons.upload': 'Upload',

  // Company
  'company.title': 'Company',

  'company.user.name': 'Name',
  'company.user.email': 'Email',

  'company.type.main': 'Main',
  'company.type.parent': 'Parent',
  'company.type.subsidiary': 'Subsidiary',

  'company.disabled-company': 'Disabled company',

  'company.situation.normal': 'Normal',
  'company.situation.canceled': 'Canceled',

  // Dashboard
  'dashboard.title': 'Dashboard',

  'dashboard.welcome': 'Welcome, ',
  'dashboard.logged-company': 'You are logged in the company ',

  'dashboard.message.failed-to-load-user-company-name':
    'Failed to load the user company name!',

  // Datasmart
  'datasmart.name': 'Datasmart',
  'datasmart.copyright': '© {value} - Datasmart',
  'datasmart.website': 'https://www.datasmart.com.br',
  'datasmart.smartcommerce': 'Smart E-Commerce',

  'datasmart.email-company.email': 'datasmart@datasmart.com.br',
  'datasmart.email-company.contact': 'General subjects',
  'datasmart.email-company.subject-general-message': 'General matters',

  'datasmart.email-commercial.email': 'comercial@datasmart.com.br',
  'datasmart.email-commercial.contact': 'Commercial',
  'datasmart.email-commercial.subject-commercial-message': 'Business matters',

  'datasmart.email-support.email': 'suporte@datasmart.com.br',
  'datasmart.email-support.contact': 'Support',
  'datasmart.email-support.subject-support-message': 'Support',

  'datasmart.phone-fixed.contact': 'General subjects',
  'datasmart.phone-fixed.number.avare': '1437119090',
  'datasmart.phone-fixed.ddi': '55',
  'datasmart.phone-fixed.operator': 'Fixed',

  'datasmart.phone-mobile.contact': 'Support',
  'datasmart.phone-mobile.number': '14996641191',
  'datasmart.phone-mobile.number.lencois-paulista': '14997616504',
  'datasmart.phone-mobile.ddi': '55',
  'datasmart.phone-mobile.operator': 'Vivo',
  'datasmart.phone-mobile.whatsapp-message':
    "Hello, I'm in need of help from Datasmart support. Can you help me?",

  'datasmart.address.title': 'Address',
  'datasmart.address.state': 'SP',

  // Datasmart - Avare Address
  'datasmart.address.city.avare': 'Avaré',
  'datasmart.address.address-location.avare': 'Rua Alagoas',
  'datasmart.address.number.avare': '1633',
  'datasmart.address.neighborhood.avare': 'Centro',
  'datasmart.address.postal-code.avare': '18705-070',

  // Datasmart - Lencois Paulista Address
  'datasmart.address.city.lencois-paulista': 'Lençóis Paulista',
  'datasmart.address.address-location.lencois-paulista':
    'Avenida Geraldo Pereira de Barros',
  'datasmart.address.number.lencois-paulista': '336',
  'datasmart.address.neighborhood.lencois-paulista': 'Centro',
  'datasmart.address.postal-code.lencois-paulista': '18682-041',

  // Dialogs
  'dialogs.delete-title': 'Do you want to delete this record?',
  'dialogs.delete-text': "You won't be able to revert this!",

  'dialogs.data-loss-title': 'Do you wish to continue?',
  'dialogs.data-loss-text':
    'There are changes that have not yet been saved and will be lost if you continue.',

  'dialogs.change-person-kind-title': 'Do you want to change the person kind?',
  'dialogs.change-person-kind-text':
    'This action clears the completed documents.',

  // Documents
  'documents.cpf': 'CPF',
  'documents.cpf-data': 'CPF: {value}',
  'documents.valid-cpf': 'Valid CPF',
  'documents.invalid-cpf': 'Invalid CPF',

  'documents.cnpj': 'CNPJ',
  'documents.cnpj-data': 'CNPJ: {value}',
  'documents.valid-cnpj': 'Valid CNPJ',
  'documents.invalid-cnpj': 'Invalid CNPJ',

  'documents.cpf-cnpj': 'CPF/CNPJ',

  // Emails
  'emails.title': 'Emails',
  'emails.new-title': 'New email',
  'emails.edit-title': 'Edit email',

  'emails.is-main': 'Main',
  'emails.is-main-email': 'Main email',
  'emails.error-delete-is-main':
    'Main email cannot be deleted when you have more than one! 🤔',

  'emails.email': 'Email',
  'emails.contact': 'Contact',
  'emails.send': 'Send email to ',

  // Footer
  'footer.support': 'Support',
  'footer.help-center': 'Help Center',
  'footer.privacy-and-terms-of-service': 'Privacy and Terms of Service',

  // Forms
  'forms.informations': 'Informations',

  'forms.field-excluded': 'Excluded',
  'forms.field-disabled': 'Disabled',

  'forms.input.required-field': 'Required field',
  'forms.input.valid-email': 'The field must be a valid email',

  // Forms - autocomplete
  'forms.autocomplete.close': 'Close',
  'forms.autocomplete.open': 'Open',
  'forms.autocomplete.clear': 'Clear',
  'forms.autocomplete.no-options': 'No options',
  'forms.autocomplete.loading': 'Loading...',

  // Forms - input length
  'forms.input.field-greater-than': 'Field must be greater than {value}',

  'forms.input.field-greater-than-or-equal-to':
    'Field must be greater than or equal to {value}',

  // Header
  'header.search': 'Search',

  'header.profile': 'My profile',
  'header.switch-company': 'Switch company',
  'header.company-public-profile': 'Company public profile',
  'header.help': 'Help',
  'header.sign-out': 'Sign out',
  'header.user-avatar': 'User avatar',
  'header.open-drawer': 'Open drawer',
  'header.filled-cart': 'Go to cart',
  'header.empty-cart': 'Empty cart',

  // Help
  'help.title': 'Help',

  'help.contact-title': 'Contact us',
  'help.contact-description':
    'If you need help, please contact us through one of our channels.',

  // Contacts
  'contacts.title': 'Contacts',
  'contacts.contact-detail.title': 'Contact',

  // Material Table
  'material-table.add': 'Add',
  'material-table.duplicate': 'Duplicate Order',
  'material-table.view-and-edit': 'View and edit',
  'material-table.print': 'Print',
  'material-table.refresh': 'Refresh',
  'material-table.edit': 'Edit',
  'material-table.download': 'Download',
  'material-table.settlement': 'Settlement',
  'material-table.delete': 'Delete',
  'material-table.share': 'Share',
  'material-table.view': 'View',
  'material-table.filter': 'Filter',

  // Messages
  'messages.preposition.female': 'of',
  'messages.preposition.male': 'of',
  'messages.conjuction.and': 'and',

  'messages.get-failure': 'Failed to load records, please refresh the page! 😢',

  'messages.save-success': 'Successfully registered! 🎉',
  'messages.save-failure': 'Failed to save the record, please try again! 😢',

  'messages.edit-success': 'Successfully applied changes! 🎉',
  'messages.edit-failure': 'Failed to apply changes, please try again! 😢',

  'messages.change-success': 'Successfully applied changes! 🎉',
  'messages.change-failure': 'Failed to apply changes, please try again! 😢',

  'messages.update-success': 'Successfully updated! 🎉',
  'messages.update-failure':
    'Failed to update the record, please try again! 😢',

  'messages.delete-success': 'Successfully deleted! 🎉',
  'messages.delete-failure':
    'Failed to delete the record, please try again! 😢',

  'messages.settlement-success': 'Successfully settlemented! 🎉',
  'messages.settlement-failure':
    'Failed to settlement the record, please try again! 😢',

  'messages.get-details-failure':
    'Failed to load record details, please refresh the page! 😢',

  'messages.notice-title': 'Notice',

  // Messages - Internal error
  'messages.error-500.without-connection-server':
    'Without connection to the server! 🤔',
  'messages.error-500.internal-server-error': 'Internal server error! 🤔',
  'messages.error-500.database-connection-error':
    'Internal server error! Code: 001 🤔',
  'messages.error-504.gateway-timeout': 'Internal server error! Code: 002 🤔',

  // Order Items
  'order-items.title': 'Items',
  'order-items.new-title': 'New item',
  'order-items.edit-title': 'Edit item',

  'order-items.description': 'Description',
  'order-items.product': 'Product',
  'order-items.unit-measure': 'Unit measure',
  'order-items.unit-value': 'Unit value',
  'order-items.discount-percentage': 'Discount %',
  'order-items.discount-value': 'Discount value',
  'order-items.unit-value-discount-value': 'Unit value / Discount',
  'order-items.net-value': 'Net value',
  'order-items.quantity': 'Quantity',
  'order-items.total': 'Total',
  'order-items.observation': 'Observation',
  'order-items.stock-available': 'Available',
  'order-items.stock-unavailable': 'Unavailable',

  'order-items.situation': 'Situation',
  'order-items.situation.normal': 'Normal',
  'order-items.situation.blocked': 'Blocked',
  'order-items.situation.canceled': 'Canceled',
  'order-items.situation.undefined': 'Undefined',

  'order-items.product-request.title': 'Product request',

  'order-items.product-request.priority': 'Priority',
  'order-items.product-request.priority.low': 'Low',
  'order-items.product-request.priority.medium': 'Medium',
  'order-items.product-request.priority.high': 'High',

  'order-items.invalid-net-value-check': 'Error calculating net price! 🤔',

  'order-items.message.quantity-change':
    'To change the quantity, it is necessary to delete this item and post it again.',
  'order-items.message.item-total-exceeds-payment-method-maximum-amount':
    'The sum of the item and the existing record total exceeds the maximum value of the payment method!',

  'order-items.discount-percentage-readjusted':
    'The discount percentage will be readjusted as the discount amount exceeds 2 decimal places! 🤔',

  // Orders
  'orders.title': 'Orders',
  'orders.new-title': 'New order',

  'orders.request-number': 'Request number',
  'orders.payment-method': 'Payment method',
  'orders.registered-at': 'Registered at',
  'orders.total': 'Total',
  'orders.total-products': 'Total products',
  'orders.total-services': 'Total services',
  'orders.observation': 'Observation',

  'orders.type': 'Type',
  'orders.type.budget': 'Budget',
  'orders.type.order': 'Order',
  'orders.type.sale': 'Sale',
  'orders.type.service': 'Service',
  'orders.type.purchase': 'Purchase',
  'orders.type.undefined': 'Undefined',

  'orders.status': 'Status',
  'orders.status.duplicate': 'Duplicate',
  'orders.status.drafting': 'Drafting',
  'orders.status.releasing': 'Waiting for release',
  'orders.status.sorting': 'Waiting for separation',
  'orders.status.checking': 'Waiting for verification',
  'orders.status.released': 'Released',
  'orders.status.undefined': 'Undefined',

  'orders.situation': 'Situation',
  'orders.situation.opened': 'Opened',
  'orders.situation.finished': 'Finished',
  'orders.situation.canceled': 'Canceled',
  'orders.situation.undefined': 'Undefined',

  'orders.subtype': 'Subtype',
  'orders.subtype.budget': 'Budget',
  'orders.subtype.order': 'Order',
  'orders.subtype.pre-sale': 'Pre-sale',
  'orders.subtype.bonification': 'Bonification',
  'orders.subtype.shipping': 'Shipping',
  'orders.subtype.exchange': 'Exchange',
  'orders.subtype.purchase-order': 'Purchase order',
  'orders.subtype.sale': 'Sale',
  'orders.subtype.ready-delivery': 'Ready delivery',
  'orders.subtype.service-order': 'Service order',
  'orders.subtype.visit': 'Visit',
  'orders.subtype.purchase': 'Purchase',
  'orders.subtype.undefined': 'Undefined',

  'orders.message.product-already-created':
    'This product has been created previously!',

  'orders.message.confirm-order.title': 'Confirm order?',
  'orders.message.confirm-order.description':
    'Once the order is confirmed, it cannot be undone and your order cannot be changed.',

  'orders.message.product-request.title': 'Product unavailable',
  'orders.message.product-request.description':
    'This product is currently unavailable. We will arrange the purchase as soon as possible! Select one of the following options:',
  'orders.message.product-request.quantity': 'Add quantity:',
  'orders.message.product-request.please-select-one':
    'Select at least one of the options',
  'orders.message.product-request.success':
    'Notification successfully created!',
  'orders.message.product-request.failure':
    'Failed to create the notification! 😢',

  // Orders report
  'orders.report.document.rg': 'RG: {value}',
  'orders.report.document.ie': 'IE: {value}',
  'orders.report.request-number': 'Request number: {value}',
  'orders.report.date': 'Date: {value}',
  'orders.report.payment-method': 'Payment method: {value}',

  'orders.report.observation.title': 'Observation',
  'orders.report.signature.title': 'Signature',

  'orders.report.item.description': 'Description',
  'orders.report.item.quantity': 'Quantity',
  'orders.report.item.value': 'Value',
  'orders.report.item.total': 'Total',
  'orders.report.item.unit-measure': 'Unit.',
  'orders.report.item.total-product': 'Product total',
  'orders.report.item.total-service': 'Service total',
  'orders.report.item.discount': 'Discount',

  'orders.report.failure.get-customer-data': 'Failed to get customer data! 😢',

  'orders.report.failure.person-disabled':
    'Failed to create the report, disabled person! 😢',
  'orders.report.failure.person-canceled':
    'Failed to create the report, canceled person! 😢',

  'orders.report.failure.seller-deleted':
    'Failed to create the report, deleted seller! 😢',
  'orders.report.failure.buyer-deleted':
    'Failed to create the report, deleted buyer! 😢',

  'orders.report.failure.seller-disabled':
    'Failed to create the report, disabled seller! 😢',
  'orders.report.failure.buyer-disabled':
    'Failed to create the report, disabled buyer! 😢',

  'orders.report.failure.customer-deleted':
    'Failed to create the report, deleted customer! 😢',
  'orders.report.failure.supplier-deleted':
    'Failed to create the report, deleted supplier! 😢',

  'orders.report.failure.customer-disabled':
    'Failed to create the report, disabled customer! 😢',
  'orders.report.failure.supplier-disabled':
    'Failed to create the report, disabled supplier! 😢',

  'orders.report.failure.no-items-created':
    'Failed to create the report, there is no items created! 😢',

  'orders.report.failure.payment-method-deleted':
    'Failed to create the report, deleted payment method! 😢',

  // Page404
  'page404.title': 'Page not found',

  'page404.404': 'Error 404',
  'page404.description': 'The page you are looking for was not found.',
  'page404.return-to-website': 'Return to website',

  // Payment Methods
  'payment-methods.get-payment-methods-failure':
    'Failed to load the payment methods! 😢',

  // Phones
  'phones.title': 'Phones',
  'phones.new-title': 'New phone',
  'phones.edit-title': 'Edit phone',

  'phones.number': 'Number',
  'phones.operator': 'Operator',
  'phones.contact': 'Contact',
  'phones.call': 'Call to ',
  'phones.without-ddi': 'Without DDI',
  'phones.select-ddi': 'Select',

  'phones.type': 'Type',
  'phones.types.general': 'General',
  'phones.types.cell-phone': 'Cell phone',
  'phones.types.contact': 'Contact',
  'phones.types.fax': 'Fax',
  'phones.types.branch-office': 'Branch office',
  'phones.types.landline': 'Landline',
  'phones.types.headquarters': 'Headquarters',
  'phones.types.residential': 'Residential',
  'phones.types.work': 'Work',

  'phones.is-whatsapp': 'WhatsApp',
  'phones.open-whatsapp': 'Open in WhatsApp',

  'phones.is-main': 'Main',
  'phones.is-main-phone': 'Main phone',
  'phones.error-delete-is-main':
    'Main phone cannot be deleted when you have more than one! 🤔',
  'phones.error.is-without-ddi-with-is-whatsapp':
    'It is not possible to save a phone without ddi with WhatsApp!',

  // Products
  'products.deleted-product': 'Deleted product',
  'products.canceled-product': 'Canceled product',

  'products.message.promotion-date': 'On promotion from {start} to {end}',

  'products.get-products-failure': 'Failed to load the products! 😢',

  // Profile
  'profile.title': 'Profile',

  'profile.change-image': 'Change image',
  'profile.full-name': 'Full name',
  'profile.username': 'Username',
  'profile.email': 'Email',

  'profile.current-password': 'Current password',
  'profile.new-password': 'New password',
  'profile.confirm-password': 'Confirm your new password',
  'profile.equal-passwords':
    'New password and confirmation fields should be equal',
  'profile.different-passwords':
    'New password and the current password must be different! 🤔',

  // Reports
  'reports.failed-to-generate-report': 'Failed to generate the report! 😢',

  // Settings
  'settings.get-settings-failure': 'Failed to load the settings! 😢',

  // Sidebar
  'sidebar.menu': 'Menu',

  'sidebar.movements': 'Movements',
  'sidebar.movements.orders': 'Orders',
  'sidebar.movements.catalog': 'Catalog',

  'sidebar.contacts': 'Contacts',
  'sidebar.contacts.customers': 'Customers',
  'sidebar.contacts.suppliers': 'Suppliers',
  'sidebar.contacts.sellers': 'Sellers',
  'sidebar.contacts.employees': 'Employees',
  'sidebar.contacts.promoters': 'Promoters',
  'sidebar.contacts.transporters': 'Transporters',
  'sidebar.contacts.covenants': 'Covenants',
  'sidebar.contacts.owners': 'Owners',
  'sidebar.contacts.supervisor': 'Supervisors',
  'sidebar.contacts.branches-activities': 'Branches Activities',
  'sidebar.contacts.dispatching-agencies': 'Dispatching Agencies',

  // Sign In
  'sign-in.title': 'Welcome!',

  'sign-in.description': 'Sign in to your account to continue',
  'sign-in.login': 'Email or username',
  'sign-in.password': 'Password',
  'sign-in.remind-me': 'Remind me',
  'sign-in.forgot-password': 'Forgot password?',
  'sign-in.sign-in': 'Sign in',

  'sign-in.toggle-password': 'Toggle password visibility',
  'sign-in.authentication-failed': 'Authentication failed, check your data! 🤔',
  'sign-in.company-failed': 'User does not belong to any company! 🤔',

  // Sign Up
  'sign-up.title': 'Register',

  'sign-up.description': 'Enter your information to register in the system',
  'sign-up.full-name': 'Full name',
  'sign-up.username': 'Username',
  'sign-up.email': 'Email',

  'sign-up.password': 'Password',
  'sign-up.confirm-password': 'Confirm your password',
  'sign-up.equal-passwords':
    'The password and confirmation fields should be equal!',

  'sign-up.sign-up': 'Sign up',
  'sign-up.return': 'Return',

  'sign-up.register-success': 'Registered successfully! 🎉',
  'sign-up.register-failed': 'Register failed, check your data! 🤔',

  // Switch company
  'switch-company.title': 'Switch companies',

  'switch-company.title-member':
    'You are member in {value, plural, =0 {0 company!} one {# company!} other {# companies!}}',

  'switch-company.description': 'Select the company that you want to access.',
  'switch-company.new-company': 'New company',
  'switch-company.type': 'Type',
  'switch-company.sign-out': 'Sign out',

  // Table Localization
  'table-localization.body.add-tooltip': 'Add',
  'table-localization.body.edit-tooltip': 'Edit',
  'table-localization.body.delete-tooltip': 'Delete',
  'table-localization.body.empty-data-source-message': 'No records found',
  'table-localization.body.delete-text':
    'Are you sure you want to delete this record?',
  'table-localization.body.save-tooltip': 'Save',
  'table-localization.body.cancel-tooltip': 'Cancel',
  'table-localization.body.filter-tooltip': 'Filter',

  'table-localization.grouping.grouped-by': 'Grouped by',
  'table-localization.grouping.placeholder': 'Drag headers to group',

  'table-localization.header.actions': 'Actions',

  'table-localization.pagination.first-aria-label': 'First page',
  'table-localization.pagination.first-tooltip': 'First page',
  'table-localization.pagination.label-displayed-rows': 'of',
  'table-localization.pagination.label-rows-select': 'Records',
  'table-localization.pagination.last-aria-label': 'Last page',
  'table-localization.pagination.last-tooltip': 'Last page',
  'table-localization.pagination.next-aria-label': 'Next page',
  'table-localization.pagination.next-tooltip': 'Next page',
  'table-localization.pagination.previous-tooltip': 'Previous page',
  'table-localization.pagination.previous-aria-label': 'Previous page',

  'table-localization.toolbar.add-remove-columns': 'Add or remove columns',
  'table-localization.toolbar.clear-search-aria-label': 'Clear search',
  'table-localization.toolbar.export-aria-label': 'Export',
  'table-localization.toolbar.export-title': 'Export',
  'table-localization.toolbar.export-csv-name': 'Export as CSV',
  'table-localization.toolbar.export-pdf-name': 'Export as PDF',
  'table-localization.toolbar.n-rows-selected': 'Selected row(s)',
  'table-localization.toolbar.search-tooltip': 'Search',
  'table-localization.toolbar.search-placeholder': 'Search',
  'table-localization.toolbar.show-columns-title': 'Show columns',
  'table-localization.toolbar.show-columns-aria-label': 'Show columns',

  // Product-Catalog

  'company-profile.no-product-found': 'No products were found.',
  'company-profile.product-found': '{value} product found.',
  'company-profile.products-found': '{value} products found.',
  'company-profile.filtered-by': 'Filtered by:',

  'company-profile.search-products': 'Search products...',

  'company-profile.filter-by.condition': 'Condition',
  'company-profile.filter-by.brand': 'Brand',

  'company-profile.filter-by.category': 'Category',
  'company-profile.filter-by.category.drawer-title': 'Select a category',
  'company-profile.filter-by.category.return': 'Return',
  'company-profile.filter-by.category.return-to': 'Return to {value}',

  'company-profile.is-hot': 'Hot',
  'company-profile.is-release': 'New',
  'company-profile.is-on-promotion': 'Promotion',

  'company-profile.navigation.previous': 'Previous',
  'company-profile.navigation.next': 'Next',

  'company-profile.get-products-failure': 'Failed to load the products! 😢',
  'company-profile.get-company-failure':
    'Failed to load the company informations! 😢',

  'company-profile.error.title': 'Error listing products!',
  'company-profile.error.message':
    'Please try again later or contact the responsible company.',

  // Customers
  'customers.title': 'Customers',
  'customers.new-title': 'New customer',

  'customers.deleted-customer': 'Deleted customer',
  'customers.disabled-customer': 'Disabled customer',

  'customers.check-exists.become-customer': 'Become customer',
  'customers.check-exists.exists-view-title':
    'A customer with this document was found:',
  'customers.check-exists.exists-become-title':
    'A person with this document was found, but is not yet a customer:',
  'customers.check-exists.exists-outside-become-title':
    'A person with this document was found, but is not yet a customer at this company:',

  'customers.check-exists.exists-not-shared-title':
    'This person is already registered as customer at company:',

  'customers.get-customer-failure': 'Failed to load the customer! 😢',
  'customers.get-customers-failure': 'Failed to load the customers! 😢',

  // Persons
  'persons.title': 'Persons',
  'persons.form-title.personal-information': 'Personal information',
  'persons.form-title.additional-information': 'Additional information',
  'persons.form-title.credit': 'Credit',
  'persons.form-title.personal-documents': 'Personal documents',
  'persons.form-title.additional-documents': 'Additional documents',
  'persons.form-title.occupation': 'Occupation',
  'persons.form-title.contact': 'Contact',
  'persons.form-title.values': 'Values',

  'persons.add-modal.title': 'Document verification',

  'persons.name': 'Full name',
  'persons.nickname': 'Nickname',
  'persons.trading-name': 'Trading name',
  'persons.born-at': 'Birth date',
  'persons.is-rural-producer': 'Rural producer',
  'persons.foundation-date': 'Foundation date',
  'persons.profession': 'Profession',
  'persons.nationality': 'Nationality',
  'persons.contact': 'Contact',
  'persons.website': 'Website',
  'persons.occupation': 'Occupation',
  'persons.workplace': 'Workplace',
  'persons.credit-limit': 'Credit limit',
  'persons.without-credit-limit': 'Without limit',
  'persons.price-list': 'Price list',
  'persons.payment-method': 'Payment method',
  'persons.fixed-discount-percentage': 'Discount %',
  'persons.observation': 'Observation',
  'persons.registered-at': 'Registered at',
  'persons.branch-activity': 'Branch activity',
  'persons.covenant': 'Covenant',
  'persons.address': 'Address',
  'persons.email': 'Email',
  'persons.phone': 'Phone',
  'persons.is-main-phone-email': 'Phone / Email',
  'persons.maximum-discount-percentage': 'Maximum discount %',
  'persons.commission-percentage': 'Commission %',
  'persons.slug': 'Slug',
  'persons.is-blocked-installment-sale': 'Block installment sales',

  'persons.document': 'Document',
  'persons.document.valid': 'Valid',
  'persons.document.invalid': 'Invalid',
  'persons.document.undefined': 'Undefined',
  'persons.documents.dispatching-agency': 'Dispatching agency',
  'persons.documents.state': 'State',

  'persons.ie': 'IE',
  'persons.set-exempt': 'Set exempt',
  'persons.rg': 'RG',
  'persons.required-rg': 'The RG field is required',
  'persons.im': 'IM',

  'persons.person-kind': 'Person kind',
  'persons.natural-person': 'Natural',
  'persons.legal-person': 'Legal',

  'persons.parents-name': 'Parents name',
  'persons.father-filiation': "Father's name",
  'persons.mother-filiation': "Mother's name",

  'persons.gender': 'Gender',
  'persons.genders.female': 'Female',
  'persons.genders.male': 'Male',

  'persons.situation': 'Situation',
  'persons.situation.normal': 'Normal',
  'persons.situation.blocked': 'Blocked',
  'persons.situation.canceled': 'Canceled',
  'persons.situation.debtor': 'Debtor',
  'persons.situation.undefined': 'Undefined',

  'persons.type': 'Type',
  'persons.type.supplier': 'Supplier',
  'persons.type.customer': 'Customer',
  'persons.type.company': 'Company',
  'persons.type.employee': 'Employee',
  'persons.type.seller': 'Seller',
  'persons.type.driver': 'Driver',
  'persons.type.owner': 'Owner',
  'persons.type.property': 'Property',
  'persons.type.technical': 'Technical',
  'persons.type.transporter': 'Transporter',
  'persons.type.supervisor': 'Supervisor',
  'persons.type.promoter': 'Promoter',

  'persons.is-enabled': 'Enabled',
  'persons.is-enabled.enabled': 'Enabled',
  'persons.is-enabled.disabled': 'Disabled',
  'persons.is-enabled.undefined': 'Undefined',

  'persons.marital-status': 'Marital status',
  'persons.marital-status.married': 'Married',
  'persons.marital-status.single': 'Single',
  'persons.marital-status.divorced': 'Divorced',
  'persons.marital-status.stable-union': 'Stable Union',
  'persons.marital-status.widowed': 'Widowed',

  'persons.without-nickname': '(Not registered)',

  'persons.deleted-person': 'Deleted person',
  'persons.disabled-person': 'Disabled person',

  'persons.check-exists.not-verified-title': 'Verification by CPF/CNPJ',
  'persons.check-exists.not-verified-description':
    'Inform to check for an existing person or ignore leaving empty.',

  'persons.check-exists.invalid-document-title': 'Validation Error!',
  'persons.check-exists.invalid-document-description':
    'The CPF/CNPJ did not pass the document validation.',

  'persons.check-exists.connection-error-title': 'Connection Error!',
  'persons.check-exists.connection-error-description':
    'Without connection to the server.',

  'persons.check-exists.not-exists-title': 'Success!',
  'persons.check-exists.not-exists-description':
    'No person was found with this document.',

  'persons.check-exists.complete-registration': 'Complete registration',
  'persons.check-exists.view-registration': 'View registration',

  'persons.check-exists.edit-document-title': 'Warning!',
  'persons.check-exists.edit-document-description':
    'No person was found with this document. When changing the document and changing the type of person, the remaining documents will be cleaned.',
  'persons.check-exists.empty-edit-document-description':
    'Inform to check for an existing person or ignore leaving empty. When changing the document and changing the type of person, the remaining documents will be cleaned.',
  'persons.check-exists.edit-document': 'Change document',

  'persons.check-exists.exists-valid-document-title': 'Valid document!',
  'persons.check-exists.exists-valid-document-description':
    'This document is valid and registered for this person.',

  'persons.check-exists.exists-not-shared-title':
    'This person is already registered at company:',

  // Reports
  'reports.title': 'Reports',
  'reports.filter-title': 'Filters',

  'reports.data-uninformed': 'Uninformed',

  'reports.sections.customer-title': 'Customers',

  'reports.sections.customers-by-cities.report-title': 'Customers by cities',
  'reports.sections.customers-by-cities.without-city': 'Without city',
  'reports.sections.customers-by-cities.name': 'Name',
  'reports.sections.customers-by-cities.phone-email': 'Phone / Email',
  'reports.sections.customers-by-cities.contact': 'Contact',
  'reports.sections.customers-by-cities.address': 'Address',

  'reports.sections.product-title': 'Products',

  'reports.sections.products-by-prices.report-title': 'Products by prices',
  'reports.sections.products-by-prices.title': 'Title',
  'reports.sections.products-by-prices.table.title': 'Name',
  'reports.sections.products-by-prices.ean': 'EAN: {value}',
  'reports.sections.products-by-prices.table.ean': 'EAN',
  'reports.sections.products-by-prices.internal-code': 'Internal code: {value}',
  'reports.sections.products-by-prices.table.internal-code': 'Internal code',
  'reports.sections.products-by-prices.name': 'Name',
  'reports.sections.products-by-prices.cost-value': 'Cost value: {value}',
  'reports.sections.products-by-prices.table.cost-value': 'Cost value',
  'reports.sections.products-by-prices.percentage-applied':
    'Percentage applied',
  'reports.sections.products-by-prices.price': 'Price',

  'reports.sections.products-by-prices.filter.minimum-cost-value':
    'Minimum cost value',
  'reports.sections.products-by-prices.filter.maximum-cost-value':
    'Maximum cost value',
  'reports.sections.products-by-prices.filter.minimum-price': 'Minimum price',
  'reports.sections.products-by-prices.filter.maximum-price': 'Maximum price',

  'reports.sections.products-top-selling.report-title': 'Top selling products',
  'reports.sections.products-top-selling.title': 'Title',
  'reports.sections.products-top-selling.average-unit-value':
    'Average unit value',
  'reports.sections.products-top-selling.total': 'Total',
  'reports.sections.products-top-selling.quantity': 'Quantity',
  'reports.sections.products-top-selling.total-average':
    'Total average unit value',
  'reports.sections.products-top-selling.total-quantity': 'Total quantity',

  'reports.sections.products-requests.report-title': 'Requested products',
  'reports.sections.products-requests.product': 'Product',
  'reports.sections.products-requests.quantity': 'Quantity',
  'reports.sections.products-requests.priority': 'Priority',
  'reports.sections.products-requests.created-at': 'Created at',

  'reports.sections.products-requests.filter.date': 'Date',

  'reports.sections.products-top-selling.filter.type': 'Type',
  'reports.sections.products-top-selling.filter.date': 'Date',
  'reports.sections.products-top-selling.filter.seller': 'Seller',
  'reports.sections.products-top-selling.filter.customer': 'Customer',
  'reports.sections.products-top-selling.filter.list-by': 'List by',
  'reports.sections.products-top-selling.filter.listed-by': 'Listed by',

  'reports.generating-report-message':
    'Report being prepared, please wait a moment!',

  'reports.of': 'of',
  'reports.empty-data': 'There is no information to be shown.',
  'reports.unavailable-report': 'Report unavailable at moment.',
  'reports.customized-date': 'From {start} to {end}',
  'reports.searched': 'Searched: {value}',
  'reports.ordered-by': 'Ordered by: {value} {direction}',
  'reports.direction.ascending': 'ascending',
  'reports.direction.descending': 'descending',

  'reports.failed-to-load-image': 'Failed to load the image! 😢',

  // Forms
  'forms.select.dropdown-select': 'Select',

  'forms.date-picker.invalid-date': 'Invalid date',
  'forms.date-picker.min-date': 'Date should not be before minimal date',
  'forms.date-picker.max-date': 'Date should not be after maximal date',
  'forms.date-picker.clear': 'Clear',

  'forms.date-filter.today': 'Today',
  'forms.date-filter.last-7-days': 'Last 7 days',
  'forms.date-filter.last-week': 'Last week',
  'forms.date-filter.last-30-days': 'Last 30 days',
  'forms.date-filter.last-60-days': 'Last 60 days',
  'forms.date-filter.last-month': 'Last month',
  'forms.date-filter.this-month': 'This month',
  'forms.date-filter.next-month': 'Next month',
  'forms.date-filter.this-year': 'This year',
  'forms.date-filter.last-year': 'Last year',
  'forms.date-filter.customized': 'Customized',

  'forms.label.from': 'From',
  'forms.label.to': 'To',
  'forms.label.creation': 'Creation: ',
  'forms.label.last-update': 'Last update: ',

  'forms.input.search': 'Search',
  'forms.input.invalid-email': 'Invalid email',
  'forms.input.invalid-document': 'Invalid document',
  'forms.input.field-less-than': 'Field must be less than {value}',
  'forms.input.field-less-than-or-equal-to':
    'Field must be less than or equal to {value}',
  'forms.input.field-greater-than-zero': 'Field must be greater than 0',
  'forms.input.field-exactly-characters':
    'Value must be exactly {value} characters',
  'forms.input.total-is-below-to-cost-price': 'Total is below to cost price',

  'forms.input.custom-validation.is-correct-minimum-value':
    'The minimum value must be less than the maximum value',
  'forms.input.custom-validation.is-correct-maximum-value':
    'The maximum value must be greater than the minimum value',

  // Files
  'files.add-image': 'Add image',
  'files.change-image': 'Change image',
  'files.delete-image': 'Delete image',

  'files.error-file-size-too-big':
    'The file has exceeded the limit of {value}!',
  'files.error-wrong-file-type': 'Wrong file type. Only {value} are valids!',

  // Avatar
  'avatar.crop-modal.title': 'Crop image',

  // Dates
  'dates.created-by': 'Created by',
  'dates.updated-by': 'Last updated by',
  'dates.on': 'on',
  'dates.at': 'at',
  'dates.date-interval': 'From {start} to {end}',

  // Modals - Observation
  'modals.observation': 'Observation',
};
