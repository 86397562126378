import { createSlice } from '@reduxjs/toolkit';

import { contactsActions } from '../contacts/contactsSlice';
import contactEmailsReducers from './contactEmailsReducers';

const initialState = {
  list: [],
  loading: false,
};

const contactEmailsSlice = createSlice({
  name: 'contactEmails',
  initialState,
  reducers: contactEmailsReducers,
  extraReducers: builder => {
    builder.addCase(contactsActions.closeModalDetailContact, state => {
      state.list = [];
      state.loading = false;
    });

    builder.addCase(contactsActions.resetDetailsContact, state => {
      state.list = [];
      state.loading = false;
    });
  },
});

export const { actions: contactEmailsActions } = contactEmailsSlice;
export default contactEmailsSlice.reducer;
