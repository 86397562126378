import { authRoutes } from './categories/auth';
import { landingRoutes } from './categories/landing';
import { movementsRoutes } from './categories/movements';
import { privateRoutes } from './categories/private';
import { contactsRoutes } from './categories/contacts';

const others = [movementsRoutes, contactsRoutes];

export const auth = [authRoutes];

export const landing = [landingRoutes];

export const dashboard = [privateRoutes, ...others];

export default others;
