import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';
import { round10 } from '~/utils/match';

import { orderActions } from '../orders/orderSlice';
import { orderItemActions } from './orderItemSlice';
import { companyProfileActions } from '../companyProfile/companyProfileSlice';

function* updateOrderValues(recalculateValues) {
  if (!recalculateValues) {
    return;
  }

  const orderItemsList = yield select(state => state.orderItems.list);
  const orderDetail = yield select(state => state.orders.detail);

  let totalProductRounded = 0;
  let totalServiceRounded = 0;

  const filteredProductItems = orderItemsList.filter(
    productItem =>
      productItem.type === 'PRODUCT' && productItem.situation !== 'C'
  );

  if (filteredProductItems.length > 0) {
    const productItems = filteredProductItems.map(
      filteredProduct => filteredProduct.total
    );

    const totalProductValue = productItems.reduce(
      (total, value) => total + value,
      0
    );

    totalProductRounded = round10(totalProductValue, -2);
  }

  const filteredServiceItems = orderItemsList.filter(
    serviceItem =>
      serviceItem.type === 'SERVICE' && serviceItem.situation !== 'C'
  );

  if (filteredServiceItems.length > 0) {
    const serviceItems = filteredServiceItems.map(
      filteredService => filteredService.total
    );

    const totalServiceValue = serviceItems.reduce(
      (total, value) => total + value,
      0
    );

    totalServiceRounded = round10(totalServiceValue, -2);
  }

  const { taxes, shipping_fee, shipping_by } = orderDetail;

  const taxesRounded = round10(taxes, -2);

  const shippingFeeRounded =
    shipping_by === 2 || shipping_by === 3 ? round10(shipping_fee, -2) : 0;

  const total =
    totalProductRounded +
    totalServiceRounded +
    taxesRounded +
    shippingFeeRounded;

  const totalRounded = round10(total, -2);

  const data = {
    ...orderDetail,
    total_services: totalServiceRounded,
    total_products: totalProductRounded,
    total: totalRounded,
    discount_value: 0,
    discount_percentage: 0,
  };

  yield put(
    orderActions.updateOrderRequest({
      id: orderDetail.id,
      newOrder: data,
      updatingItem: true,
    })
  );
}

export function* createOrderItem({ payload }) {
  try {
    const { orderId, item, isSaveAndNew, resetFormOnSaveAndNew } = payload;

    const response = yield call(api.post, `orders/${orderId}/items`, item);
    yield put(
      orderItemActions.createOrderItemSuccess({
        item: response.data,
        isSaveAndNew,
      })
    );

    if (isSaveAndNew && resetFormOnSaveAndNew) {
      resetFormOnSaveAndNew();
    }

    yield updateOrderValues(true);

    if (!window.location.pathname.includes('cart')) {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(orderItemActions.createOrderItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* createMultipleOrderItems({ payload }) {
  const { items } = payload;

  for (let i = 0; i < items.length; i++) {
    const { item, orderId } = items[i];

    yield call(createOrderItem, {
      payload: {
        orderId,
        item,
        isSaveAndNew: true,
        resetFormOnSaveAndNew: () => {},
      },
    });
  }
}

export function* updateOrderItem({ payload }) {
  try {
    const { orderId, itemId, newItem, recalculateValues } = payload;

    const response = yield call(
      api.put,
      `orders/${orderId}/items/${itemId}`,
      newItem
    );

    yield put(
      orderItemActions.updateOrderItemSuccess({ newItem: response.data })
    );

    yield updateOrderValues(recalculateValues);

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(orderItemActions.updateOrderItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOrderItem({ payload }) {
  try {
    const { orderId, itemId, updateCart } = payload;
    const orderItemsList = yield select(state => state.orderItems.list);

    yield call(api.delete, `orders/${orderId}/items/${itemId}`);

    yield put(orderItemActions.deleteOrderItemSuccess({ itemId, updateCart }));

    yield updateOrderValues(true);
    if (updateCart) {
      yield put(
        companyProfileActions.setProductCounter(
          orderItemsList.filter(orderItem => orderItem.id !== itemId)
        )
      );
    }

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(orderItemActions.deleteOrderItemFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest('orderItems/createOrderItemRequest', createOrderItem),
  takeLatest('orderItems/updateOrderItemRequest', updateOrderItem),
  takeLatest('orderItems/deleteOrderItemRequest', deleteOrderItem),
  takeLatest('orderItems/createMultipleOrderItems', createMultipleOrderItems),
]);
