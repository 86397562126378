const contactEmailsReducers = {
  getContactEmails: {
    reducer: (state, action) => {
      const { emails } = action.payload;

      state.list = emails;
    },
    prepare: emails => {
      return {
        payload: {
          emails,
        },
      };
    },
  },

  resetListContactEmails: state => {
    state.list = [];
  },
};

export default contactEmailsReducers;
