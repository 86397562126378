import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import historyService from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { filesActions } from '../files/filesSlice';
import { addressesActions } from '../addresses/addressesSlice';
import { emailsActions } from '../emails/emailsSlice';
import { personsActions } from '../persons/personsSlice';
import { phonesActions } from '../phones/phonesSlice';
import { employeesActions } from './employeesSlice';

export function* getEmployees({ payload }) {
  try {
    const { queryParams } = payload;

    const { data } = yield call(api.get, `employees${queryParams}`);

    yield put(employeesActions.getEmployeesSuccess({ data }));
  } catch (err) {
    yield put(employeesActions.getEmployeesFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createEmployee({ payload }) {
  try {
    const { employee, subResourceType } = payload;

    const { data } = yield call(api.post, 'employees', employee);

    yield put(employeesActions.createEmployeeSuccess(data));

    yield put(employeesActions.setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    historyService.replace(`/employee/${data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(employeesActions.createEmployeeFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateEmployee({ payload }) {
  try {
    const { id, newEmployee } = payload;

    const { data } = yield call(api.put, `employees/${id}`, newEmployee);

    yield put(employeesActions.updateEmployeeSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(employeesActions.updateEmployeeFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteEmployee({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `employees/${id}`);

    yield put(employeesActions.deleteEmployeeSuccess(id));

    historyService.push('/employees');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(employeesActions.deleteEmployeeFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeEmployee({ payload }) {
  try {
    const { id } = payload;

    const person_type = 'EMPLOYEE';

    const { data } = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );

    yield put(employeesActions.becomeEmployeeSuccess(data));

    yield put(personsActions.resetFoundPerson());

    historyService.push(`/employee/${id}`);
  } catch (err) {
    yield put(employeesActions.becomeEmployeeFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsEmployee({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `employees/${id}`);

    const { phones, addresses, emails, files, ...rest } = data;

    yield put(phonesActions.getPhones(phones));

    yield put(addressesActions.getAddresses(addresses));

    yield put(emailsActions.getEmails(emails));

    yield put(filesActions.getFiles(files));

    yield put(employeesActions.getDetailsEmployeeSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.employees
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(phonesActions.openModalPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(emailsActions.openModalEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(addressesActions.openModalAddress());
      } else if (subResourceType === 'FILE') {
        yield put(employeesActions.setOpenFileDialog());
      }

      yield put(employeesActions.resetSubResourceData());
    }
  } catch (err) {
    yield put(employeesActions.getDetailsEmployeeFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsEmployee() {
  yield put(phonesActions.resetListPhone());

  yield put(addressesActions.resetListAddress());

  yield put(emailsActions.resetListEmail());

  yield put(filesActions.resetListFile());
}

export default all([
  takeLatest(employeesActions.getEmployeesRequest, getEmployees),
  takeLatest(employeesActions.createEmployeeRequest, createEmployee),
  takeLatest(employeesActions.updateEmployeeRequest, updateEmployee),
  takeLatest(employeesActions.deleteEmployeeRequest, deleteEmployee),
  takeLatest(employeesActions.becomeEmployeeRequest, becomeEmployee),
  takeLatest(employeesActions.getDetailsEmployeeRequest, getDetailsEmployee),
  takeLatest(employeesActions.resetDetailsEmployee, resetDetailsEmployee),
]);
