const billToReceiveChartAccountsReducers = {
  getBillToReceiveChartAccounts: {
    reducer: (state, action) => {
      state.list = action.payload.chartAccounts;
    },
    prepare: chartAccounts => {
      return { payload: { chartAccounts } };
    },
  },
  createBillToReceiveChartAccountRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_receive_id, chartAccount) => {
      return { payload: { bill_to_receive_id, chartAccount } };
    },
  },
  createBillToReceiveChartAccountSuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.chartAccount);
      state.loading = false;
      state.billToReceiveChartAccountModalOpen = false;
    },
    prepare: chartAccount => {
      return { payload: { chartAccount } };
    },
  },
  createBillToReceiveChartAccountFailure: state => {
    state.loading = false;
  },
  updateBillToReceiveChartAccountRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_receive_id, chart_account_id, newChartAccount) => {
      return {
        payload: { bill_to_receive_id, chart_account_id, newChartAccount },
      };
    },
  },
  updateBillToReceiveChartAccountSuccess: {
    reducer: (state, action) => {
      const { newChartAccount } = action.payload;

      state.list = state.list.map(chartAccount =>
        chartAccount.id === newChartAccount.id ? newChartAccount : chartAccount
      );

      state.detail = null;
      state.loading = false;
      state.billToReceiveChartAccountModalOpen = false;
    },
    prepare: newChartAccount => {
      return { payload: { newChartAccount } };
    },
  },
  updateBillToReceiveChartAccountFailure: state => {
    state.loading = false;
  },
  deleteBillToReceiveChartAccountRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_receive_id, chart_account_id) => {
      return { payload: { bill_to_receive_id, chart_account_id } };
    },
  },
  deleteBillToReceiveChartAccountSuccess: {
    reducer: (state, action) => {
      const { chart_account_id } = action.payload;

      state.list = state.list.filter(
        chartAccount => chartAccount.id !== chart_account_id
      );

      if (state.detail?.id && chart_account_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: chart_account_id => {
      return { payload: { chart_account_id } };
    },
  },
  deleteBillToReceiveChartAccountFailure: state => {
    state.loading = false;
  },
  resetListBillToReceiveChartAccount: state => {
    state.list = [];
  },
  openModalBillToReceiveChartAccount: {
    reducer: (state, action) => {
      const { chart_account_id } = action.payload;

      state.detail =
        state.list.find(chartAccount => chartAccount.id === chart_account_id) ||
        null;

      state.billToReceiveChartAccountModalOpen = true;
    },
    prepare: chart_account_id => {
      return { payload: { chart_account_id } };
    },
  },
  closeModalBillToReceiveChartAccount: state => {
    state.billToReceiveChartAccountModalOpen = false;
    state.detail = null;
  },
};

export default billToReceiveChartAccountsReducers;
