import { createSlice } from '@reduxjs/toolkit';

import companyProfileReducers from './companyProfileReducers';

const initialState = {
  filter: [],
  productsCounter: {},
};

const companyProfileSlice = createSlice({
  name: 'companyProfile',
  initialState,
  reducers: companyProfileReducers,
});

export const { actions: companyProfileActions } = companyProfileSlice;
export default companyProfileSlice.reducer;
