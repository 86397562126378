import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import historyService from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { personsActions } from './personsSlice';

export function* updatePerson({ payload }) {
  try {
    const { id, newPerson } = payload;

    const { data } = yield call(api.put, `persons/${id}`, newPerson);

    yield put(personsActions.updatePersonSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(personsActions.updatePersonFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deletePerson({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `persons/${id}`);

    yield put(personsActions.deletePersonSuccess(id));

    historyService.push('/catalog');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(personsActions.deletePersonFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* checkExistsPerson({ payload }) {
  try {
    const { document, type } = payload;

    const { data } = yield call(api.get, `persons/check-exists/${document}`, {
      params: {
        type,
      },
    });

    yield put(personsActions.getCheckExistsPersonSuccess(data));
  } catch (err) {
    yield put(personsActions.getCheckExistsPersonFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsPerson({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `persons/${id}`);

    yield put(personsActions.getDetailsPersonSuccess(data));
  } catch (err) {
    yield put(personsActions.getDetailsPersonFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(personsActions.updatePersonRequest, updatePerson),
  takeLatest(personsActions.deletePersonRequest, deletePerson),
  takeLatest(personsActions.getCheckExistsPersonRequest, checkExistsPerson),
  takeLatest(personsActions.getDetailsPersonRequest, getDetailsPerson),
]);
