import { createSlice } from '@reduxjs/toolkit';

import { billsToReceiveActions } from '../billsToReceive/billsToReceiveSlice';
import billToReceiveChartAccountsReducers from './billToReceiveChartAccountsReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  billToReceiveChartAccountModalOpen: false,
};

const billToReceiveChartAccountsSlice = createSlice({
  name: 'billToReceiveChartAccounts',
  initialState,
  reducers: billToReceiveChartAccountsReducers,
  extraReducers: builder => {
    builder.addCase(billsToReceiveActions.resetDetailsBillToReceive, state => {
      state.billToReceiveChartAccountModalOpen = false;
      state.detail = null;
      state.list = [];
    });
  },
});

export const { actions: billToReceiveChartAccountsActions } =
  billToReceiveChartAccountsSlice;
export default billToReceiveChartAccountsSlice.reducer;
