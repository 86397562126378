import { createSlice } from '@reduxjs/toolkit';

import { billsToReceiveActions } from '../billsToReceive/billsToReceiveSlice';
import { billsToPayActions } from '../billsToPay/billsToPaySlice';
import contactReducers from './contactsReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  detailModalSource: '',
  contactDetailModalOpen: false,
  contactDetailModalLoading: false,
  contactModalOpen: false,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: contactReducers,
  extraReducers: builder => {
    builder.addCase(billsToPayActions.resetDetailsBillToPay, state => {
      state.detail = null;
      state.contactDetailModalLoading = false;
      state.contactDetailModalOpen = false;
      state.detailModalSource = '';
    });

    builder.addCase(billsToReceiveActions.resetDetailsBillToReceive, state => {
      state.detail = null;
      state.contactDetailModalOpen = false;
    });
  },
});

export const { actions: contactsActions } = contactsSlice;
export default contactsSlice.reducer;
