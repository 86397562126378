import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import historyService from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { filesActions } from '../files/filesSlice';
import { addressesActions } from '../addresses/addressesSlice';
import { emailsActions } from '../emails/emailsSlice';
import { personsActions } from '../persons/personsSlice';
import { phonesActions } from '../phones/phonesSlice';
import { customersActions } from './customersSlice';

export function* createCustomer({ payload }) {
  try {
    const { customer, subResourceType } = payload;

    const { data } = yield call(
      api.post,
      'customers-users?tool_id=2',
      customer
    );

    yield put(customersActions.createCustomerSuccess(data));
    // yield put(customersActions.createCustomerSuccess(customer));

    yield put(customersActions.setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    historyService.replace(`/customer/${data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(customersActions.createCustomerFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCustomer({ payload }) {
  try {
    const { id, newCustomer } = payload;

    // const { data } = yield call(api.put, `customers/${id}`, newCustomer);
    const { data } = yield call(
      api.put,
      `customers-users/${id}/?tool_id=2`,
      newCustomer
    );

    // yield put(customersActions.updateCustomerSuccess({ newCustomer }));
    yield put(customersActions.updateCustomerSuccess({ data }));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(customersActions.updateCustomerFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCustomer({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `customers/${id}`);

    yield put(customersActions.deleteCustomerSuccess(isFromDetail));

    historyService.push('/customers');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.customers);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isDeletingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(customersActions.deleteCustomerFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* becomeCustomer({ payload }) {
  try {
    const { id } = payload;
    const person_type = 'CUSTOMER';

    const { data } = yield call(
      api.put,
      `persons/${id}/become-person-type/${person_type}`
    );
    yield put(customersActions.becomeCustomerSuccess(data));

    yield put(personsActions.resetFoundPerson());

    historyService.push(`/customer/${id}`);
  } catch (err) {
    yield put(customersActions.becomeCustomerFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsCustomer({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `customers/${id}`);

    const { phones, addresses, emails, files, ...rest } = data;

    yield put(phonesActions.getPhones(phones));

    yield put(addressesActions.getAddresses(addresses));

    yield put(emailsActions.getEmails(emails));

    yield put(filesActions.getFiles(files));

    yield put(customersActions.getDetailsCustomerSuccess(rest));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.customers
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'PHONE') {
        yield put(phonesActions.openModalPhone());
      } else if (subResourceType === 'EMAIL') {
        yield put(emailsActions.openModalEmail());
      } else if (subResourceType === 'ADDRESS') {
        yield put(addressesActions.openModalAddress());
      } else if (subResourceType === 'FILE') {
        yield put(customersActions.setOpenFileDialog());
      }

      yield put(customersActions.resetSubResourceData());
    }
  } catch (err) {
    yield put(customersActions.getDetailsCustomerFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsCustomer() {
  yield put(phonesActions.resetListPhone());

  yield put(addressesActions.resetListAddress());

  yield put(emailsActions.resetListEmail());

  yield put(filesActions.resetListFile());
}

export default all([
  takeLatest(customersActions.createCustomerRequest, createCustomer),
  takeLatest(customersActions.updateCustomerRequest, updateCustomer),
  takeLatest(customersActions.deleteCustomerRequest, deleteCustomer),
  takeLatest(customersActions.becomeCustomerRequest, becomeCustomer),
  takeLatest(customersActions.getDetailsCustomerRequest, getDetailsCustomer),
  takeLatest(customersActions.resetDetailsCustomer, resetDetailsCustomer),
]);
