import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { billToReceivePixsActions } from '../billToReceivePixs/billToReceivePixsSlice';
import billsToReceiveReducers from './billsToReceiveReducers';

const initialState = {
  listLength: 0,
  detail: null,

  filter: null,
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,
  shouldFetchStatisticData: false,

  creatingFrom: '',

  detailUpdatedOnce: false,
  isDetailLoading: false,
  loading: false,
  sharedDetail: false,
  settlementId: null,
  billToReceiveSettlementModalOpen: false,
  billToReceiveObservationModalOpen: false,
  billToReceiveFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  isCreatingPixFromList: false,
};

const billsToReceiveSlice = createSlice({
  name: 'billsToReceive',
  initialState,
  reducers: billsToReceiveReducers,
  extraReducers: builder => {
    builder.addCase(
      billToReceivePixsActions.updateBillToReceivePixSuccess,
      state => {
        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          state.page -= 1;
          state.detailUpdatedOnce = true;
        }
      }
    );
    builder.addCase(
      billToReceivePixsActions.settlementBillToReceivePixSuccess,
      state => {
        if (
          !state.detailUpdatedOnce &&
          state.page > 0 &&
          state.listLength === 1
        ) {
          state.page -= 1;
          state.detailUpdatedOnce = true;
        }
      }
    );
    builder.addCase(
      billToReceivePixsActions.openModalBillToReceiveAddAccountPix,
      (state, action) => {
        if (action.payload.billData) {
          state.detail = action.payload.billData;

          state.isCreatingPixFromList = true;
        }
      }
    );
    builder.addCase(
      billToReceivePixsActions.closeModalBillToReceiveAddAccountPix,
      state => {
        if (state.isCreatingPixFromList) {
          state.detail = null;
          state.isCreatingPixFromList = false;
        }
      }
    );
    builder.addCase(
      billToReceivePixsActions.closeModalBillToReceivePixDetail,
      state => {
        if (state.isCreatingPixFromList) {
          state.detail = null;
          state.isCreatingPixFromList = false;
        }
      }
    );
    builder.addCase(
      billToReceivePixsActions.closeModalBillToReceivePixShare,
      state => {
        if (state.isCreatingPixFromList) {
          state.detail = null;
          state.isCreatingPixFromList = false;
        }
      }
    );
    builder.addCase(
      billToReceivePixsActions.closeModalBillToReceivePix,
      state => {
        if (state.isCreatingPixFromList) {
          state.detail = null;
          state.isCreatingPixFromList = false;
        }
      }
    );
  },
});

export const { actions: billsToReceiveActions } = billsToReceiveSlice;
export default billsToReceiveSlice.reducer;
