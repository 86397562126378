import IntlMessages from '~/utils/IntlMessages';
import BaseIcon from '~/components/Base/Icon';
import loadAsynchronousComponent from '~/components/loadAsynchronousComponent';
import DashboardLayout from '~/layouts/Dashboard';

import { PrivateRoute } from '../RoutesElements';

const CustomersList = loadAsynchronousComponent(() =>
  import('~/pages/Customers/List')
);

export const contactsRoutes = {
  id: <IntlMessages id="sidebar.contacts" />,
  path: '/contacts',
  icon: <BaseIcon name="Groups" />,
  children: [
    {
      path: '/customers',
      name: <IntlMessages id="sidebar.contacts.customers" />,
      component: CustomersList,
      element: PrivateRoute,
      layout: DashboardLayout,
    },
  ],
};
