import { createSlice } from '@reduxjs/toolkit';

import { billetsActions } from '../billets/billetsSlice';
import { billsToReceiveActions } from '../billsToReceive/billsToReceiveSlice';
import billToReceiveBilletsReducers from './billToReceiveBilletsReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  billToReceiveBilletModalOpen: false,
};

const billToReceiveBilletsSlice = createSlice({
  name: 'billToReceiveBillets',
  initialState,
  reducers: billToReceiveBilletsReducers,
  extraReducers: builder => {
    builder.addCase(billetsActions.issueBilletSuccess, (state, action) => {
      if (action.payload.data) {
        state.list = action.payload.data;
      }
    });
    builder.addCase(billsToReceiveActions.resetDetailsBillToReceive, state => {
      state.billToReceiveBilletModalOpen = false;

      state.detail = null;
      state.list = [];
    });
  },
});

export const { actions: billToReceiveBilletsActions } =
  billToReceiveBilletsSlice;

export default billToReceiveBilletsSlice.reducer;
