import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Grid } from '@mui/material';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Badge from '@mui/material/Badge';

import Tooltip from '~/components/Base/Tooltip';

export default function CartButton() {
  const companyProfile = useSelector(state => state.companyProfile);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const isCartEmpty = Object.keys(companyProfile.productsCounter).length === 0;

  const renderButton = () => {
    if (isCartEmpty) {
      return (
        <Tooltip
          arrow
          PopperProps={{ disablePortal: true }}
          title={formatMessage({ id: 'header.empty-cart' })}
        >
          <ShoppingCartCheckoutIcon
            style={{
              fontSize: 'max(1.7rem, 25px)',
              cursor: 'not-allowed',
            }}
          />
        </Tooltip>
      );
    }

    const productTotal = Object.values(companyProfile.productsCounter).length;

    return (
      <span>
        <Tooltip
          arrow
          PopperProps={{ disablePortal: true }}
          title={formatMessage({ id: 'header.filled-cart' })}
        >
          <Badge badgeContent={productTotal} color="error">
            <ShoppingCartCheckoutIcon
              style={{
                fontSize: 'max(1.7rem, 25px)',
                cursor: 'pointer',
              }}
              onClick={() => {
                return navigate('/cart');
              }}
            />
          </Badge>
        </Tooltip>
      </span>
    );
  };

  return (
    <span
      style={{
        display: 'flex',
        marginRight: '12px',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          marginLeft: 1,
        }}
      >
        {renderButton()}
      </Grid>
    </span>
  );
}
