const branchesActivitiesReducers = {
  getBranchesActivitiesRequest: {
    reducer: state => {
      state.loading = true;
      state.isDetailLoading = true;
    },
    prepare: ({ queryParams }) => {
      return {
        payload: { queryParams },
      };
    },
  },
  getBranchesActivitiesSuccess: {
    reducer: (state, action) => {
      const { data } = action.payload;

      state.list = data;
      state.loading = false;
      state.isDetailLoading = false;
    },
    prepare: ({ data }) => {
      return { payload: { data } };
    },
  },
  getBranchesActivitiesFailure: state => {
    state.loading = false;
  },
  createBranchActivityRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (branchActivity, source) => {
      return { payload: { branchActivity, source } };
    },
  },
  createBranchActivitySuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.branchActivity);
      state.loading = false;
      state.branchActivityModalOpen = false;
    },
    prepare: (branchActivity, source) => {
      return { payload: { branchActivity, source } };
    },
  },
  createBranchActivityFailure: state => {
    state.loading = false;
  },
  updateBranchActivityRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newBranchActivity) => {
      return { payload: { id, newBranchActivity } };
    },
  },
  updateBranchActivitySuccess: {
    reducer: (state, action) => {
      const { newBranchActivity } = action.payload;

      state.list = state.list.map(branchActivity =>
        branchActivity.id === newBranchActivity.id
          ? newBranchActivity
          : branchActivity
      );

      state.detail = null;
      state.loading = false;
      state.branchActivityModalOpen = false;
    },
    prepare: newBranchActivity => {
      return { payload: { newBranchActivity } };
    },
  },
  updateBranchActivityFailure: state => {
    state.loading = false;
  },
  deleteBranchActivityRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteBranchActivitySuccess: {
    reducer: (state, action) => {
      state.list = state.list.filter(
        branchActivity => branchActivity.id !== action.payload.id
      );

      state.loading = false;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteBranchActivityFailure: state => {
    state.loading = false;
  },
  resetListBranchesActivities: state => {
    state.loading = false;
    state.list = [];
    state.isDetailLoading = false;
    state.filterSearch = '';
  },
  openModalBranchActivity: {
    reducer: (state, action) => {
      const { id } = action.payload;

      state.detail =
        state.list.find(branchActivity => branchActivity.id === id) || null;

      state.branchActivityModalOpen = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  closeModalBranchActivity: state => {
    state.branchActivityModalOpen = false;
    state.detail = null;
  },

  setBranchesActivitiesTableFilters: (state, action) => {
    const { filterSearch } = action.payload;

    if (typeof filterSearch !== 'undefined') {
      state.filterSearch = filterSearch;
    }
  },
};

export default branchesActivitiesReducers;
