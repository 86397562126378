import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import historyService from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { billToPayChartAccountsActions } from '../billToPayChartAccounts/billToPayChartAccountsSlice';
import { billToPayCostCentersActions } from '../billToPayCostCenters/billToPayCostCentersSlice';
import { billsToPayActions } from './billsToPaySlice';

export function* createBillToPay({ payload }) {
  try {
    const { billToPay, subResourceType } = payload;

    const { data } = yield call(api.post, 'bills', billToPay);

    yield put(billsToPayActions.createBillToPaySuccess(data));

    yield put(billsToPayActions.setSavingOnSubResource(subResourceType));

    toast.success(<IntlMessages id="messages.save-success" />);

    historyService.replace(`/bill-to-pay/${data.id}`, {
      creatingResource: true,
    });
  } catch (err) {
    yield put(billsToPayActions.createBillToPayFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToPay({ payload }) {
  try {
    const { id, newBillToPay } = payload;

    const { data } = yield call(api.put, `bills/${id}`, newBillToPay);

    yield put(billsToPayActions.updateBillToPaySuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(billsToPayActions.updateBillToPayFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* settlementBillToPay({ payload }) {
  try {
    const { id, newBillToPay, fetchList } = payload;

    const { data } = yield call(api.put, `bills/${id}`, newBillToPay);

    yield put(billsToPayActions.settlementBillToPaySuccess(data));

    const { listLength } = yield select(state => state.billsToPay);

    if (fetchList) {
      yield put(
        billsToPayActions.setBillToPayTableFilters({
          shouldFetchStatisticData: true,
        })
      );

      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(billsToPayActions.settlementBillToPayFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export function* deleteBillToPay({ payload }) {
  try {
    const { id, fetchList, isFromDetail } = payload;

    yield call(api.delete, `bills/${id}`);

    yield put(billsToPayActions.deleteBillToPaySuccess(isFromDetail));

    historyService.push('/bills-to-pay');

    toast.success(<IntlMessages id="messages.delete-success" />);

    const { listLength } = yield select(state => state.billsToPay);

    if (fetchList) {
      if (listLength === 1) {
        fetchList({ isEditingLastPageElement: true });
      } else {
        fetchList();
      }
    }
  } catch (err) {
    yield put(billsToPayActions.deleteBillToPayFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsBillToPay({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `bills/${id}`);

    const { chart_accounts, cost_centers, ...rest } = data;

    const {
      billToPaySettlementModalOpen,
      subResourceType,
      shouldOpenSubResourceModal,
    } = yield select(state => state.billsToPay);

    if (!billToPaySettlementModalOpen) {
      yield put(
        billToPayChartAccountsActions.getBillToPayChartAccounts(chart_accounts)
      );

      yield put(
        billToPayCostCentersActions.getBillToPayCostCenters(cost_centers)
      );
    }

    yield put(billsToPayActions.getDetailsBillToPaySuccess(rest));

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'CHART_ACCOUNT') {
        yield put(
          billToPayChartAccountsActions.openModalBillToPayChartAccount()
        );
      } else if (subResourceType === 'COST_CENTER') {
        yield put(billToPayCostCentersActions.openModalBillToPayCostCenter());
      }

      yield put(billsToPayActions.resetSubResourceData());
    }
  } catch (err) {
    yield put(billsToPayActions.getDetailsBillToPayFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(billsToPayActions.createBillToPayRequest, createBillToPay),
  takeLatest(billsToPayActions.updateBillToPayRequest, updateBillToPay),
  takeLatest(billsToPayActions.settlementBillToPayRequest, settlementBillToPay),
  takeLatest(billsToPayActions.deleteBillToPayRequest, deleteBillToPay),
  takeLatest(billsToPayActions.getDetailsBillToPayRequest, getDetailsBillToPay),
]);
