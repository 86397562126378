const filesReducers = {
  getFiles: {
    reducer: (state, action) => {
      const { files: fileData } = action.payload;

      state.detail =
        fileData.find(
          file => file.type === 'image/profile' && file.is_main === true
        ) || null;

      state.list = fileData;
    },
    prepare: files => {
      return { payload: { files } };
    },
  },
  createFileRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, file, person_route) => {
      return { payload: { person_id, file, person_route } };
    },
  },
  createFileSuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.file);
      state.detail = action.payload.file;
      state.loading = false;
      state.fileModalOpen = false;
    },
    prepare: file => {
      return { payload: { file } };
    },
  },
  createFileFailure: state => {
    state.loading = false;
  },
  updateFileRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, file_id, newFile, person_route) => {
      return { payload: { person_id, file_id, newFile, person_route } };
    },
  },
  updateFileSuccess: {
    reducer: (state, action) => {
      const { newFile } = action.payload;

      state.list = state.list.map(file =>
        file.id === newFile.id ? newFile : file
      );

      state.detail = newFile;
      state.loading = false;
      state.fileModalOpen = false;
    },
    prepare: newFile => {
      return { payload: { newFile } };
    },
  },
  updateFileFailure: state => {
    state.loading = false;
  },
  deleteFileRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, file_id, person_route) => {
      return { payload: { person_id, file_id, person_route } };
    },
  },
  deleteFileSuccess: {
    reducer: (state, action) => {
      const { file_id } = action.payload;

      state.list = state.list.filter(file => file.id !== file_id);

      state.detail = null;
      state.loading = false;
    },
    prepare: file_id => {
      return { payload: { file_id } };
    },
  },
  deleteFileFailure: state => {
    state.loading = false;
  },
  resetListFile: state => {
    state.list = [];

    state.detail = null;
  },
  openModalFile: state => {
    state.fileModalOpen = true;
  },
  closeModalFile: state => {
    state.fileModalOpen = false;
  },
};

export default filesReducers;
