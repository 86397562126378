const covenantsReducers = {
  getCovenantsRequest: {
    reducer: state => {
      state.loading = true;
      state.isDetailLoading = true;
    },
    prepare: ({ queryParams }) => {
      return { payload: { queryParams } };
    },
  },
  getCovenantsSuccess: (state, action) => {
    const { data } = action.payload;
    state.list = data;
    state.loading = false;
    state.isDetailLoading = false;
  },
  getCovenantsFailure: state => {
    state.loading = false;
  },
  createCovenantRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (covenant, source) => {
      return { payload: { covenant, source } };
    },
  },
  createCovenantSuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.covenant);
      state.loading = false;
      state.covenantModalOpen = false;
    },
    prepare: (covenant, source) => {
      return { payload: { covenant, source } };
    },
  },
  createCovenantFailure: state => {
    state.loading = false;
  },
  updateCovenantRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newCovenant) => {
      return { payload: { newCovenant, id } };
    },
  },
  updateCovenantSuccess: {
    reducer: (state, action) => {
      const { newCovenant } = action.payload;
      state.list = state.list.map(covenant => {
        return covenant.id === newCovenant.id ? newCovenant : covenant;
      });
      state.detail = null;
      state.loading = false;
      state.covenantModalOpen = false;
    },
    prepare: newCovenant => {
      return { payload: { newCovenant } };
    },
  },
  updateCovenantFailure: state => {
    state.loading = false;
  },
  deleteCovenantRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteCovenantSuccess: {
    reducer: (state, action) => {
      state.list = state.list.filter(covenant => {
        return covenant.id !== action.payload.id;
      });
      state.loading = false;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteCovenantFailure: state => {
    state.loading = false;
  },
  resetListCovenants: state => {
    state.loading = false;
    state.list = [];
    state.isDetailLoading = false;
    state.filterSearch = '';
  },
  openModalCovenant: {
    reducer: (state, action) => {
      const { id } = action.payload;

      state.detail = state.list.find(covenant => covenant.id === id) || null;

      state.covenantModalOpen = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  closeModalCovenant: state => {
    state.covenantModalOpen = false;
    state.detail = null;
  },

  setCovenantsTableFilters: (state, action) => {
    const { filterSearch } = action.payload;

    if (typeof filterSearch !== 'undefined') {
      state.filterSearch = filterSearch;
    }
  },
};

export default covenantsReducers;
