import { createSlice } from '@reduxjs/toolkit';

import { billsToPayActions } from '../billsToPay/billsToPaySlice';
import billToPayChartAccountsReducers from './billToPayChartAccountsReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  billToPayChartAccountModalOpen: false,
};

const billToPayChartAccountsSlice = createSlice({
  name: 'billToPayChartAccounts',
  initialState,
  reducers: billToPayChartAccountsReducers,
  extraReducers: builder => {
    builder.addCase(billsToPayActions.resetDetailsBillToPay, state => {
      state.billToPayChartAccountModalOpen = false;
      state.detail = null;
      state.list = [];
    });
  },
});

export const { actions: billToPayChartAccountsActions } =
  billToPayChartAccountsSlice;
export default billToPayChartAccountsSlice.reducer;
