import { createSlice } from '@reduxjs/toolkit';

import filesReducers from './filesReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  fileModalOpen: false,
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: filesReducers,
});

export const { actions: filesActions } = filesSlice;
export default filesSlice.reducer;
