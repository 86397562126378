import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { contactAddressesActions } from '../contactAddresses/contactAddressesSlice';
import { contactEmailsActions } from '../contactEmails/contactEmailsSlice';
import { contactFilesActions } from '../contactFiles/contactFilesSlice';
import { contactPhonesActions } from '../contactPhones/contactPhonesSlice';
import { contactsActions } from './contactsSlice';

export function* getContacts() {
  try {
    const { data } = yield call(api.get, 'persons?order=name&direction=ASC');

    yield put(contactsActions.getContactsSuccess({ contacts: data }));
  } catch (err) {
    yield put(contactsActions.getContactsFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* getDetailsContact({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `persons/${id}`);

    const { phones, addresses, emails, files, ...rest } = data;

    yield put(contactAddressesActions.getContactAddresses(addresses));

    yield put(contactEmailsActions.getContactEmails(emails));

    yield put(contactFilesActions.getContactFiles(files));

    yield put(contactPhonesActions.getContactPhones(phones));

    yield put(contactsActions.getDetailsContactSuccess({ contact: rest }));
  } catch (err) {
    yield put(contactsActions.getDetailsContactFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailsContact() {
  yield put(contactAddressesActions.resetListContactAddresses());

  yield put(contactEmailsActions.resetListContactEmails());

  yield put(contactPhonesActions.resetListContactPhone());

  yield put(contactFilesActions.resetListContactFile());

  yield put(contactsActions.resetContactList());
}

export default all([
  takeLatest(contactsActions.getContactsRequest, getContacts),
  takeLatest(contactsActions.getDetailsContactRequest, getDetailsContact),
  takeLatest(contactsActions.resetDetailsContact, resetDetailsContact),
]);
