import { createSlice } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { billsToReceiveActions } from '../billsToReceive/billsToReceiveSlice';
import billToReceivePixsReducers from './billToReceivePixsReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  billToReceiveAddAccountModalOpen: false,
  billToReceivePixModalOpen: false,
  billToReceivePixDetailModalOpen: false,
  billToReceivePixShareModalOpen: false,
  billToReceivePixSettlementModalOpen: false,
  showNextPixRequestMessage: false,
  selectedAccount: null,
  isCreatingPixFromList: false,
};

const billToReceivePixsSlice = createSlice({
  name: 'billToReceivePixs',
  initialState,
  reducers: billToReceivePixsReducers,
  extraReducers: builder => {
    builder.addCase(billsToReceiveActions.resetListBillsToReceive, state => {
      state.billToReceivePixModalOpen = false;
      state.billToReceivePixShareModalOpen = false;
      state.billToReceivePixSettlementModalOpen = false;
      state.billToReceivePixDetailModalOpen = false;
      state.billToReceiveAddAccountModalOpen = false;
      state.selectedAccount = null;
      state.showNextPixRequestMessage = false;
      state.detail = null;
      state.list = [];
    });
    builder.addCase(billsToReceiveActions.resetDetailsBillToReceive, state => {
      state.billToReceivePixModalOpen = false;
      state.billToReceivePixShareModalOpen = false;
      state.billToReceivePixSettlementModalOpen = false;
      state.billToReceivePixDetailModalOpen = false;
      state.billToReceiveAddAccountModalOpen = false;
      state.selectedAccount = null;
      state.showNextPixRequestMessage = false;
      state.detail = null;
      state.list = [];
      state.isCreatingPixFromList = false;
    });
  },
});

export const { actions: billToReceivePixsActions } = billToReceivePixsSlice;
export default billToReceivePixsSlice.reducer;
