import { createSlice } from '@reduxjs/toolkit';

import { contactsActions } from '../contacts/contactsSlice';
import contactPhonesReducer from './contactPhonesReducers';

const initialState = {
  list: [],
  loading: false,
};

const contactPhonesSlice = createSlice({
  name: 'contactPhones',
  initialState,
  reducers: contactPhonesReducer,
  extraReducers: builder => {
    builder.addCase(contactsActions.closeModalDetailContact, state => {
      state.list = [];
      state.loading = false;
    });

    builder.addCase(contactsActions.resetDetailsContact, state => {
      state.list = [];
      state.loading = false;
    });
  },
});

export const { actions: contactPhonesActions } = contactPhonesSlice;
export default contactPhonesSlice.reducer;
