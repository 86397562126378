module.exports = {
  // Addresses
  'addresses.title': 'Endereços',
  'addresses.new-title': 'Novo endereço',
  'addresses.edit-title': 'Edita endereço',

  'addresses.postal-code': 'CEP',
  'addresses.address': 'Endereço',
  'addresses.number': 'Número',
  'addresses.complement': 'Complemento',
  'addresses.neighborhood': 'Bairro',
  'addresses.city': 'Cidade',
  'addresses.state': 'Estado',

  'addresses.error-postal-code':
    'Erro ao consultar o CEP, verifique os dados! 🤔',

  'addresses.type': 'Tipo',
  'addresses.types.general': 'Geral',
  'addresses.types.billing': 'Cobrança',
  'addresses.types.delivery': 'Entrega',
  'addresses.types.branch-office': 'Filial',
  'addresses.types.headquarters': 'Matriz',
  'addresses.types.residential': 'Residencial',
  'addresses.types.work': 'Trabalho',

  'addresses.is-main': 'Principal',
  'addresses.is-main-address': 'Endereço principal',
  'addresses.error-delete-is-main':
    'Endereço principal não pode ser excluído quando tem mais de um! 🤔',
  'addresses.google-maps': 'Ver no Google Maps',

  // Buttons
  'buttons.add': 'Novo',
  'buttons.save': 'Salvar',
  'buttons.cancel': 'Cancelar',
  'buttons.ok': 'OK',
  'buttons.close': 'Fechar',
  'buttons.clear': 'Limpar',
  'buttons.delete': 'Excluir',
  'buttons.settlement': 'Baixar',
  'buttons.hide': 'Esconder',
  'buttons.go-back': 'Voltar',
  'buttons.change': 'Alterar',
  'buttons.continue': 'Continuar',
  'buttons.print': 'Imprimir',
  'buttons.copy': 'Copiar',
  'buttons.update': 'Atualizar',
  'buttons.select': 'Selecionar',
  'buttons.download-app': 'Baixar App',
  'buttons.share': 'Compartilhar',
  'buttons.catalog': 'Catálogo',
  'buttons.filter': 'Filtrar',
  'buttons.recalculate': 'Recalcular',
  'buttons.send': 'Enviar',
  'buttons.reset': 'Redefinir',
  'buttons.remove': 'Remover',
  'buttons.view': 'Visualizar',
  'buttons.search': 'Pesquisar',
  'buttons.save-and-new': 'Salvar e novo',
  'buttons.confirm': 'Confirmar',
  'buttons.agreed': 'Aceito',
  'buttons.register': 'Registrar',
  'buttons.configure': 'Configurar',
  'buttons.generate-report': 'Gerar relatório',
  'buttons.tooltip.cancel-changes': 'Cancelar alterações',
  'buttons.download': 'Download',
  'buttons.upload': 'Upload',

  // Company
  'company.title': 'Empresa',

  'company.user.name': 'Nome',
  'company.user.email': 'Email',

  'company.type.main': 'Principal',
  'company.type.parent': 'Matriz',
  'company.type.subsidiary': 'Filial',

  'company.disabled-company': 'Empresa desativada',

  'company.situation.normal': 'Normal',
  'company.situation.canceled': 'Cancelado',

  // Dashboard
  'dashboard.title': 'Início',

  'dashboard.welcome': 'Seja bem-vindo, ',
  'dashboard.logged-company': 'Você está logado na empresa ',

  'dashboard.message.failed-to-load-user-company-name':
    'Falha ao carregar o nome da empresa do usuário!',

  // Datasmart
  'datasmart.name': 'Datasmart',
  'datasmart.copyright': '© {value} - Datasmart',
  'datasmart.website': 'https://www.datasmart.com.br',
  'datasmart.smartcommerce': 'Smart E-Commerce',

  'datasmart.email-company.email': 'datasmart@datasmart.com.br',
  'datasmart.email-company.contact': 'Assuntos gerais',
  'datasmart.email-company.subject-general-message': 'Assuntos gerais',

  'datasmart.email-commercial.email': 'comercial@datasmart.com.br',
  'datasmart.email-commercial.contact': 'Comercial',
  'datasmart.email-commercial.subject-commercial-message':
    'Assuntos comerciais',

  'datasmart.email-support.email': 'suporte@datasmart.com.br',
  'datasmart.email-support.contact': 'Suporte',
  'datasmart.email-support.subject-support-message': 'Suporte',

  'datasmart.phone-fixed.contact': 'Assuntos gerais',
  'datasmart.phone-fixed.number.avare': '1437119090',
  'datasmart.phone-fixed.ddi': '55',
  'datasmart.phone-fixed.operator': 'Fixo',

  'datasmart.phone-mobile.contact': 'Suporte',
  'datasmart.phone-mobile.number': '14996641191',
  'datasmart.phone-mobile.number.lencois-paulista': '14997616504',
  'datasmart.phone-mobile.ddi': '55',
  'datasmart.phone-mobile.operator': 'Vivo',
  'datasmart.phone-mobile.whatsapp-message':
    'Olá, eu estou precisando da ajuda do suporte Datasmart. Você poderia me ajudar?',

  'datasmart.address.title': 'Endereço',
  'datasmart.address.state': 'SP',

  // Datasmart - Avare Address
  'datasmart.address.city.avare': 'Avaré',
  'datasmart.address.address-location.avare': 'Rua Alagoas',
  'datasmart.address.number.avare': '1633',
  'datasmart.address.neighborhood.avare': 'Centro',
  'datasmart.address.postal-code.avare': '18705-070',

  // Datasmart - Lencois Paulista Address
  'datasmart.address.city.lencois-paulista': 'Lençóis Paulista',
  'datasmart.address.address-location.lencois-paulista':
    'Avenida Geraldo Pereira de Barros',
  'datasmart.address.number.lencois-paulista': '336',
  'datasmart.address.neighborhood.lencois-paulista': 'Centro',
  'datasmart.address.postal-code.lencois-paulista': '18682-041',

  // Dialogs
  'dialogs.delete-title': 'Deseja excluir este registro?',
  'dialogs.delete-text':
    'Esta ação não poderá ser desfeita após sua confirmação!',

  'dialogs.data-loss-title': 'Deseja continuar?',
  'dialogs.data-loss-text':
    'Existem alterações que ainda não foram salvas e serão perdidas caso continue.',

  'dialogs.change-person-kind-title': 'Deseja alterar o tipo de pessoa?',
  'dialogs.change-person-kind-text':
    'Esta ação limpará os documentos preenchidos.',

  // Documents
  'documents.cpf': 'CPF',
  'documents.cpf-data': 'CPF: {value}',
  'documents.valid-cpf': 'CPF válido',
  'documents.invalid-cpf': 'CPF inválido',

  'documents.cnpj': 'CNPJ',
  'documents.cnpj-data': 'CNPJ: {value}',
  'documents.valid-cnpj': 'CNPJ válido',
  'documents.invalid-cnpj': 'CNPJ inválido',

  'documents.cpf-cnpj': 'CPF/CNPJ',

  // Emails
  'emails.title': 'Emails',
  'emails.new-title': 'Novo email',
  'emails.edit-title': 'Edita email',

  'emails.is-main': 'Principal',
  'emails.is-main-email': 'Email principal',
  'emails.error-delete-is-main':
    'Email principal não pode ser excluído quando tem mais de um! 🤔',

  'emails.email': 'Email',
  'emails.contact': 'Contato',
  'emails.send': 'Enviar email para ',

  // Footer
  'footer.support': 'Suporte',
  'footer.help-center': 'Central de Ajuda',
  'footer.privacy-and-terms-of-service': 'Privacidade e Termos de Serviço',

  // Forms
  'forms.informations': 'Informações',

  'forms.field-excluded': 'Excluído',
  'forms.field-disabled': 'Desativado',

  'forms.input.required-field': 'Campo obrigatório',
  'forms.input.valid-email': 'O campo precisa ser um email válido',

  // Forms - autocomplete
  'forms.autocomplete.close': 'Fechar',
  'forms.autocomplete.open': 'Abrir',
  'forms.autocomplete.clear': 'Apagar',
  'forms.autocomplete.no-options': 'Nenhuma opção',
  'forms.autocomplete.loading': 'Carregando...',

  // Forms - input length
  'forms.input.field-greater-than': 'Campo deve ser maior que {value}',

  'forms.input.field-greater-than-or-equal-to':
    'Campo deve ser maior ou igual a {value}',

  // Header
  'header.search': 'Pesquisar',

  'header.profile': 'Meu perfil',
  'header.switch-company': 'Trocar de empresa',
  'header.company-public-profile': 'Perfil público da empresa',
  'header.help': 'Ajuda',
  'header.sign-out': 'Sair',
  'header.user-avatar': 'Avatar do usuário',
  'header.open-drawer': 'Abrir o menu',
  'header.filled-cart': 'Ir ao carrinho',
  'header.empty-cart': 'Carrinho vazio',
  // Help
  'help.title': 'Ajuda',

  'help.contact-title': 'Fale conosco',
  'help.contact-description':
    'Caso precisar de ajuda, entre em contato conosco através de um de nossos canais.',

  // Contacts
  'contacts.title': 'Contatos',
  'contacts.contact-detail.title': 'Contato',

  // Material Table
  'material-table.add': 'Adicionar',
  'material-table.duplicate': 'Duplicar pedido',
  'material-table.view-and-edit': 'Ver e editar',
  'material-table.print': 'Imprimir',
  'material-table.refresh': 'Atualizar',
  'material-table.edit': 'Editar',
  'material-table.download': 'Download',
  'material-table.settlement': 'Baixar',
  'material-table.delete': 'Deletar',
  'material-table.share': 'Compartilhar',
  'material-table.view': 'Ver',
  'material-table.filter': 'Filtrar',

  // Messages
  'messages.preposition.female': 'da',
  'messages.preposition.male': 'do',
  'messages.conjuction.and': 'e',

  'messages.get-failure':
    'Falha ao carregar os registros, atualize a página! 😢',

  'messages.save-success': 'Cadastrado com sucesso! 🎉',
  'messages.save-failure': 'Falha ao salvar o registro, tente novamente! 😢',

  'messages.edit-success': 'Editado com sucesso! 🎉',
  'messages.edit-failure': 'Falha ao editar o registro, tente novamente! 😢',

  'messages.change-success': 'Alterado com sucesso! 🎉',
  'messages.change-failure': 'Falha ao alterar o registro, tente novamente! 😢',

  'messages.update-success': 'Atualizado com sucesso! 🎉',
  'messages.update-failure':
    'Falha ao atualizar o registro, tente novamente! 😢',

  'messages.delete-success': 'Excluído com sucesso! 🎉',
  'messages.delete-failure': 'Falha ao excluir o registro, tente novamente! 😢',

  'messages.settlement-success': 'Baixado com sucesso! 🎉',
  'messages.settlement-failure':
    'Falha ao baixar o registro, tente novamente! 😢',

  'messages.get-details-failure':
    'Falha ao carregar os detalhes do registro, atualize a página! 😢',

  'messages.notice-title': 'Aviso',

  // Messages - Internal error
  'messages.error-500.without-connection-server':
    'Sem conexão com o servidor! 🤔',
  'messages.error-500.internal-server-error': 'Erro interno no servidor! 🤔',
  'messages.error-500.database-connection-error':
    'Erro interno no servidor! Código: 001 🤔',
  'messages.error-504.gateway-timeout':
    'Erro interno no servidor! Código: 002 🤔',

  // Order Items
  'order-items.title': 'Itens',
  'order-items.new-title': 'Novo item',
  'order-items.edit-title': 'Edita item',

  'order-items.description': 'Descrição',
  'order-items.product': 'Produto',
  'order-items.unit-measure': 'Unidade de medida',
  'order-items.unit-value': 'Valor unitário',
  'order-items.discount-percentage': '% de desconto',
  'order-items.discount-value': 'Valor de desconto',
  'order-items.unit-value-discount-value': 'Valor unitário / Desconto',
  'order-items.net-value': 'Valor líquido',
  'order-items.quantity': 'Quantidade',
  'order-items.total': 'Total',
  'order-items.observation': 'Observação',
  'order-items.stock-available': 'Disponível',
  'order-items.stock-unavailable': 'Indisponível',

  'order-items.situation': 'Situação',
  'order-items.situation.normal': 'Normal',
  'order-items.situation.blocked': 'Bloqueado',
  'order-items.situation.canceled': 'Cancelado',
  'order-items.situation.undefined': 'Indefinido',

  'order-items.product-request.title': 'Pedido de produto',

  'order-items.product-request.priority': 'Prioridade',
  'order-items.product-request.priority.low': 'Baixa',
  'order-items.product-request.priority.medium': 'Média',
  'order-items.product-request.priority.high': 'Alta',

  'order-items.invalid-net-value-check': 'Erro ao calcular o preço líquido! 🤔',

  'order-items.message.quantity-change':
    'Para alterar a quantidade, é necessário excluir este item e lançar novamente.',
  'order-items.message.item-total-exceeds-payment-method-maximum-amount':
    'A soma do item com o total existente do registro excede o valor máximo do método de pagamento!',

  'order-items.discount-percentage-readjusted':
    'O percentual de desconto será reajustado pois o valor do desconto ultrapassa 2 casas decimais! 🤔',

  // Orders
  'orders.title': 'Pedidos',
  'orders.new-title': 'Novo pedido',

  'orders.request-number': 'Número do pedido',
  'orders.payment-method': 'Método de pagamento',
  'orders.registered-at': 'Data de registro',
  'orders.total': 'Total',
  'orders.total-products': 'Total de produtos',
  'orders.total-services': 'Total de serviços',
  'orders.observation': 'Observação',

  'orders.type': 'Tipo',
  'orders.type.budget': 'Orçamento',
  'orders.type.order': 'Pedido',
  'orders.type.sale': 'Venda',
  'orders.type.service': 'Serviço',
  'orders.type.purchase': 'Compra',
  'orders.type.undefined': 'Indefinido',

  'orders.status': 'Status',
  'orders.status.duplicate': 'Duplicar',
  'orders.status.drafting': 'Rascunho',
  'orders.status.releasing': 'Esperando liberação',
  'orders.status.sorting': 'Esperando separação',
  'orders.status.checking': 'Esperando verificação',
  'orders.status.released': 'Liberado',
  'orders.status.undefined': 'Indefinido',

  'orders.situation': 'Situação',
  'orders.situation.opened': 'Aberto',
  'orders.situation.finished': 'Finalizado',
  'orders.situation.canceled': 'Cancelado',
  'orders.situation.undefined': 'Indefinido',

  'orders.subtype': 'Subtipo',
  'orders.subtype.budget': 'Orçamento',
  'orders.subtype.order': 'Pedido',
  'orders.subtype.pre-sale': 'Pré-venda',
  'orders.subtype.bonification': 'Bonificação',
  'orders.subtype.shipping': 'Remessa',
  'orders.subtype.exchange': 'Troca',
  'orders.subtype.purchase-order': 'Pedido de compra',
  'orders.subtype.sale': 'Venda',
  'orders.subtype.ready-delivery': 'Pronta entrega',
  'orders.subtype.service-order': 'Ordem de serviço',
  'orders.subtype.visit': 'Visita',
  'orders.subtype.purchase': 'Compra',
  'orders.subtype.undefined': 'Indefinido',

  'orders.message.product-already-created':
    'Este produto já foi lançado anteriormente!',

  'orders.message.confirm-order.title': 'Confirmar pedido?',
  'orders.message.confirm-order.description':
    'Ao confirmar o pedido, não poderá ser desfeito e o seu pedido não poderá ser alterado.',

  'orders.message.product-request.title': 'Produto indisponível',
  'orders.message.product-request.description':
    'Este produto está indisponível no momento. Iremos providenciar a compra assim que possível! Selecione uma das opções a seguir:',
  'orders.message.product-request.quantity': 'Adicione a quantidade:',

  'orders.message.product-request.please-select-one':
    'Selecione ao menos uma das opções',
  'orders.message.product-request.success': 'Aviso cadastrado com sucesso!',
  'orders.message.product-request.failure': 'Falha ao cadastrar aviso! 😢',

  // Orders report
  'orders.report.document.rg': 'RG: {value}',
  'orders.report.document.ie': 'IE: {value}',
  'orders.report.request-number': 'Número do pedido: {value}',
  'orders.report.date': 'Data: {value}',
  'orders.report.payment-method': 'Método de pagamento: {value}',

  'orders.report.observation.title': 'Observação',
  'orders.report.signature.title': 'Assinatura',

  'orders.report.item.description': 'Descrição',
  'orders.report.item.quantity': 'Quantidade',
  'orders.report.item.value': 'Valor',
  'orders.report.item.total': 'Total',
  'orders.report.item.unit-measure': 'Unid.',
  'orders.report.item.total-product': 'Total dos produtos',
  'orders.report.item.total-service': 'Total dos serviços',
  'orders.report.item.discount': 'Desconto',

  'orders.report.failure.get-customer-data':
    'Falha ao buscar os dados do cliente! 😢',

  'orders.report.failure.person-disabled':
    'Falha ao gerar relatório, pessoa desativada! 😢',
  'orders.report.failure.person-canceled':
    'Falha ao gerar relatório, pessoa cancelada! 😢',

  'orders.report.failure.seller-deleted':
    'Falha ao gerar relatório, vendedor excluído! 😢',
  'orders.report.failure.buyer-deleted':
    'Falha ao gerar relatório, comprador excluído! 😢',

  'orders.report.failure.seller-disabled':
    'Falha ao gerar relatório, vendedor desativado! 😢',
  'orders.report.failure.buyer-disabled':
    'Falha ao gerar relatório, comprador desativado! 😢',

  'orders.report.failure.customer-deleted':
    'Falha ao gerar relatório, cliente excluído! 😢',
  'orders.report.failure.supplier-deleted':
    'Falha ao gerar relatório, fornecedor excluído! 😢',

  'orders.report.failure.customer-disabled':
    'Falha ao gerar relatório, cliente desativado! 😢',
  'orders.report.failure.supplier-disabled':
    'Falha ao gerar relatório, fornecedor desativado! 😢',

  'orders.report.failure.no-items-created':
    'Falha ao gerar relatório, não há itens lançados! 😢',

  'orders.report.failure.payment-method-deleted':
    'Falha ao gerar relatório, método de pagamento excluído! 😢',

  // Page404
  'page404.title': 'Página não encontrada',

  'page404.404': 'Erro 404',
  'page404.description':
    'A página que você está procurando não foi encontrada.',
  'page404.return-to-website': 'Voltar ao site',

  // Payment Methods
  'payment-methods.get-payment-methods-failure':
    'Falha ao carregar os métodos de pagamento! 😢',

  // Phones
  'phones.title': 'Telefones',
  'phones.new-title': 'Novo telefone',
  'phones.edit-title': 'Edita telefone',

  'phones.number': 'Número',
  'phones.operator': 'Operadora',
  'phones.contact': 'Contato',
  'phones.call': 'Ligar para ',
  'phones.without-ddi': 'Sem DDI',
  'phones.select-ddi': 'Selecionar',

  'phones.type': 'Tipo',
  'phones.types.general': 'Geral',
  'phones.types.cell-phone': 'Celular',
  'phones.types.contact': 'Contato',
  'phones.types.fax': 'Fax',
  'phones.types.branch-office': 'Filial',
  'phones.types.landline': 'Fixo',
  'phones.types.headquarters': 'Matriz',
  'phones.types.residential': 'Residencial',
  'phones.types.work': 'Trabalho',

  'phones.is-whatsapp': 'WhatsApp',
  'phones.open-whatsapp': 'Abrir no WhatsApp',

  'phones.is-main': 'Principal',
  'phones.is-main-phone': 'Telefone principal',
  'phones.error-delete-is-main':
    'Telefone principal não pode ser excluído quando tem mais de um! 🤔',
  'phones.error.is-without-ddi-with-is-whatsapp':
    'Não é possível salvar um telefone sem ddi com WhatsApp!',

  // Products
  'products.deleted-product': 'Produto excluído',
  'products.canceled-product': 'Produto cancelado',

  'products.message.promotion-date': 'Em promoção de {start} até {end}',

  'products.get-products-failure': 'Falha ao carregar os produtos! 😢',

  // Profile
  'profile.title': 'Perfil',

  'profile.change-image': 'Alterar imagem',
  'profile.full-name': 'Nome completo',
  'profile.username': 'Nome de usuário',
  'profile.email': 'Email',

  'profile.current-password': 'Senha atual',
  'profile.new-password': 'Nova senha',
  'profile.confirm-password': 'Confirme sua nova senha',
  'profile.equal-passwords': 'A nova senha e a confirmação devem ser iguais',
  'profile.different-passwords':
    'A nova senha e a senha atual devem ser diferentes',

  // Reports
  'reports.failed-to-generate-report': 'Falha ao gerar o relatório! 😢',

  // Settings
  'settings.get-settings-failure': 'Falha ao carregar as configurações! 😢',

  // Sidebar
  'sidebar.menu': 'Menu',

  'sidebar.movements': 'Movimentos',
  'sidebar.movements.orders': 'Pedidos',
  'sidebar.movements.catalog': 'Catálogo',

  'sidebar.contacts': 'Contatos',
  'sidebar.contacts.customers': 'Clientes',
  'sidebar.contacts.suppliers': 'Fornecedores',
  'sidebar.contacts.sellers': 'Vendedores',
  'sidebar.contacts.employees': 'Funcionários',
  'sidebar.contacts.promoters': 'Promotores',
  'sidebar.contacts.transporters': 'Transportadoras',
  'sidebar.contacts.covenants': 'Convênios',
  'sidebar.contacts.owners': 'Proprietários',
  'sidebar.contacts.supervisor': 'Supervisores',
  'sidebar.contacts.branches-activities': 'Ramos de Atividades',
  'sidebar.contacts.dispatching-agencies': 'Órgãos Expedidores',

  // Sign In
  'sign-in.title': 'Bem-vindo!',

  'sign-in.description': 'Faça o login na sua conta para continuar',
  'sign-in.login': 'Email ou nome de usuário',
  'sign-in.password': 'Senha',
  'sign-in.remind-me': 'Lembrar-me',
  'sign-in.forgot-password': 'Esqueceu a senha?',
  'sign-in.sign-in': 'Entrar',

  'sign-in.toggle-password': 'Alterna a visibilidade da senha',
  'sign-in.authentication-failed':
    'Falha na autenticação, verifique seus dados! 🤔',
  'sign-in.company-failed': 'Usuário não pertence a nenhuma empresa! 🤔',

  // Sign Up
  'sign-up.title': 'Cadastro',

  'sign-up.description':
    'Entre com as suas informações para realizar o cadastro no sistema',
  'sign-up.full-name': 'Nome completo',
  'sign-up.username': 'Nome de usuário',
  'sign-up.email': 'Email',

  'sign-up.password': 'Senha',
  'sign-up.confirm-password': 'Confirme sua senha',
  'sign-up.equal-passwords': 'A senha e a confirmação devem ser iguais!',

  'sign-up.sign-up': 'Cadastrar',
  'sign-up.return': 'Voltar',

  'sign-up.register-success': 'Cadastrado com sucesso! 🎉',
  'sign-up.register-failed': 'Falha no cadastro, verifique seus dados! 🤔',

  // Switch company
  'switch-company.title': 'Troca de empresas',

  'switch-company.title-member':
    'Você é membro em {value, plural, =0 {0 empresa!} one {# empresa!} other {# empresas!}}',

  'switch-company.description': 'Selecione a empresa que você deseja acessar.',
  'switch-company.new-company': 'Nova empresa',
  'switch-company.type': 'Tipo',
  'switch-company.sign-out': 'Sair',

  // Table Localization
  'table-localization.body.add-tooltip': 'Adicionar',
  'table-localization.body.edit-tooltip': 'Editar',
  'table-localization.body.delete-tooltip': 'Excluir',
  'table-localization.body.empty-data-source-message':
    'Nenhum registro encontrado',
  'table-localization.body.delete-text':
    'Tem certeza que deseja excluir este registro?',
  'table-localization.body.save-tooltip': 'Salvar',
  'table-localization.body.cancel-tooltip': 'Cancelar',
  'table-localization.body.filter-tooltip': 'Filtrar',

  'table-localization.grouping.grouped-by': 'Agrupado por',
  'table-localization.grouping.placeholder':
    'Arraste os cabeçalhos para agrupar',

  'table-localization.header.actions': 'Ações',

  'table-localization.pagination.first-aria-label': 'Primeira página',
  'table-localization.pagination.first-tooltip': 'Primeira página',
  'table-localization.pagination.label-displayed-rows': 'de',
  'table-localization.pagination.label-rows-select': 'Registros',
  'table-localization.pagination.last-aria-label': 'Última página',
  'table-localization.pagination.last-tooltip': 'Última página',
  'table-localization.pagination.next-aria-label': 'Próxima página',
  'table-localization.pagination.next-tooltip': 'Próxima página',
  'table-localization.pagination.previous-tooltip': 'Página anterior',
  'table-localization.pagination.previous-aria-label': 'Página anterior',

  'table-localization.toolbar.add-remove-columns':
    'Adicionar ou remover colunas',
  'table-localization.toolbar.clear-search-aria-label': 'Limpar pesquisa',
  'table-localization.toolbar.export-aria-label': 'Exportar',
  'table-localization.toolbar.export-title': 'Exportar',
  'table-localization.toolbar.export-csv-name': 'Exportar como CSV',
  'table-localization.toolbar.export-pdf-name': 'Exportar como PDF',
  'table-localization.toolbar.n-rows-selected': 'linhas(s) selecionadas',
  'table-localization.toolbar.search-tooltip': 'Pesquisar',
  'table-localization.toolbar.search-placeholder': 'Pesquisar',
  'table-localization.toolbar.show-columns-title': 'Mostrar colunas',
  'table-localization.toolbar.show-columns-aria-label': 'Mostrar colunas',

  // Product-Catalog

  'company-profile.no-product-found': 'Nenhum produto foi encontrado.',
  'company-profile.product-found': '{value} produto encontrado.',
  'company-profile.products-found': '{value} produtos encontrados.',
  'company-profile.filtered-by': 'Filtrado por:',

  'company-profile.search-products': 'Buscar produtos...',

  'company-profile.filter-by.condition': 'Condição',
  'company-profile.filter-by.brand': 'Marca',

  'company-profile.filter-by.category': 'Categoria',
  'company-profile.filter-by.category.drawer-title': 'Selecione uma categoria',
  'company-profile.filter-by.category.return': 'Retornar',
  'company-profile.filter-by.category.return-to': 'Retornar para {value}',

  'company-profile.is-hot': 'Destaque',
  'company-profile.is-release': 'Novo',
  'company-profile.is-on-promotion': 'Promoção',
  'company-profile.stock': 'Estoque',
  'company-profile.code': 'Cód',

  'company-profile.navigation.previous': 'Anterior',
  'company-profile.navigation.next': 'Próxima',

  'company-profile.get-products-failure': 'Falha ao carregar os produtos! 😢',
  'company-profile.get-company-failure':
    'Falha ao carregar as informações da empresa! 😢',

  'company-profile.error.title': 'Erro ao listar produtos!',
  'company-profile.error.message':
    'Tente novamente mais tarde ou entre em contato com a empresa responsável.',

  // Cart
  'cart.go-back': 'Voltar ao Catálogo',

  // Customers
  'customers.title': 'Clientes',
  'customers.new-title': 'Novo cliente',

  'customers.deleted-customer': 'Cliente excluído',
  'customers.disabled-customer': 'Cliente desativado',

  'customers.check-exists.become-customer': 'Tornar cliente',
  'customers.check-exists.exists-view-title':
    'Foi encontrado um cliente com esse documento:',
  'customers.check-exists.exists-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é cliente:',
  'customers.check-exists.exists-outside-become-title':
    'Foi encontrado uma pessoa com esse documento, mas ainda não é um cliente nesta empresa:',

  'customers.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada como cliente na empresa:',

  'customers.get-customer-failure': 'Falha ao carregar o cliente! 😢',
  'customers.get-customers-failure': 'Falha ao carregar os clientes! 😢',

  // Persons
  'persons.title': 'Pessoas',
  'persons.form-title.personal-information': 'Informações pessoais',
  'persons.form-title.additional-information': 'Informações adicionais',
  'persons.form-title.credit': 'Crédito',
  'persons.form-title.personal-documents': 'Documentos pessoais',
  'persons.form-title.additional-documents': 'Documentos adicionais',
  'persons.form-title.occupation': 'Ocupação',
  'persons.form-title.contact': 'Contato',
  'persons.form-title.values': 'Valores',

  'persons.add-modal.title': 'Verificação de documento',

  'persons.name': 'Nome',
  'persons.nickname': 'Apelido',
  'persons.trading-name': 'Nome fantasia',
  'persons.born-at': 'Data de nascimento',
  'persons.is-rural-producer': 'Produtor rural',
  'persons.foundation-date': 'Data de fundação',
  'persons.profession': 'Profissão',
  'persons.nationality': 'Nacionalidade',
  'persons.contact': 'Contato',
  'persons.website': 'Site',
  'persons.occupation': 'Ocupação',
  'persons.workplace': 'Local de trabalho',
  'persons.credit-limit': 'Limite de crédito',
  'persons.without-credit-limit': 'Sem limite',
  'persons.price-list': 'Tabela de preço',
  'persons.payment-method': 'Método de pagamento',
  'persons.fixed-discount-percentage': '% de desconto',
  'persons.observation': 'Observação',
  'persons.registered-at': 'Data de registro',
  'persons.branch-activity': 'Ramo de atividade',
  'persons.covenant': 'Convênio',
  'persons.address': 'Endereço',
  'persons.email': 'Email',
  'persons.phone': 'Telefone',
  'persons.is-main-phone-email': 'Telefone / Email',
  'persons.maximum-discount-percentage': '% máxima de desconto',
  'persons.commission-percentage': '% de comissão',
  'persons.slug': 'Slug',
  'persons.is-blocked-installment-sale': 'Bloquear venda a prazo',

  'persons.document': 'Documento',
  'persons.document.valid': 'Válido',
  'persons.document.invalid': 'Inválido',
  'persons.document.undefined': 'Indefinido',
  'persons.documents.dispatching-agency': 'Órgão expedidor',
  'persons.documents.state': 'Estado',

  'persons.ie': 'IE',
  'persons.set-exempt': 'Definir isento',
  'persons.rg': 'RG',
  'persons.required-rg': 'O campo RG é obrigatório',
  'persons.im': 'IM',

  'persons.person-kind': 'Tipo de pessoa',
  'persons.natural-person': 'Física',
  'persons.legal-person': 'Jurídica',

  'persons.parents-name': 'Filiação',
  'persons.father-filiation': 'Nome do pai',
  'persons.mother-filiation': 'Nome da mãe',

  'persons.gender': 'Sexo',
  'persons.genders.female': 'Feminino',
  'persons.genders.male': 'Masculino',

  'persons.situation': 'Situação',
  'persons.situation.normal': 'Normal',
  'persons.situation.blocked': 'Bloqueado',
  'persons.situation.canceled': 'Cancelado',
  'persons.situation.debtor': 'Devedor',
  'persons.situation.undefined': 'Indefinido',

  'persons.type': 'Tipo',
  'persons.type.supplier': 'Fornecedor',
  'persons.type.customer': 'Cliente',
  'persons.type.company': 'Empresa',
  'persons.type.employee': 'Funcionário',
  'persons.type.seller': 'Vendedor',
  'persons.type.driver': 'Motorista',
  'persons.type.owner': 'Proprietário',
  'persons.type.property': 'Propriedade',
  'persons.type.technical': 'Técnico',
  'persons.type.transporter': 'Transportadora',
  'persons.type.supervisor': 'Supervisor',
  'persons.type.promoter': 'Promotor',

  'persons.is-enabled': 'Ativo',
  'persons.is-enabled.enabled': 'Ativado',
  'persons.is-enabled.disabled': 'Desativado',
  'persons.is-enabled.undefined': 'Indefinido',

  'persons.marital-status': 'Estado civil',
  'persons.marital-status.married': 'Casado(a)',
  'persons.marital-status.single': 'Solteiro(a)',
  'persons.marital-status.divorced': 'Divorciado(a)',
  'persons.marital-status.stable-union': 'União estável',
  'persons.marital-status.widowed': 'Viúvo(a)',

  'persons.without-nickname': '(Não informado)',

  'persons.deleted-person': 'Pessoa excluída',
  'persons.disabled-person': 'Pessoa desativada',

  'persons.check-exists.not-verified-title': 'Verificação por CPF/CNPJ',
  'persons.check-exists.not-verified-description':
    'Informe para verificar se há uma pessoa existente ou ignore deixando vazio.',

  'persons.check-exists.invalid-document-title': 'Erro de validação!',
  'persons.check-exists.invalid-document-description':
    'O CPF/CNPJ não passou na validação do documento.',

  'persons.check-exists.connection-error-title': 'Erro de conexão!',
  'persons.check-exists.connection-error-description':
    'Sem conexão com o servidor.',

  'persons.check-exists.not-exists-title': 'Sucesso!',
  'persons.check-exists.not-exists-description':
    'Não foi encontrado nenhuma pessoa com esse documento.',

  'persons.check-exists.complete-registration': 'Completar cadastro',
  'persons.check-exists.view-registration': 'Visualizar cadastro',

  'persons.check-exists.edit-document-title': 'Aviso!',
  'persons.check-exists.edit-document-description':
    'Não foi encontrado nenhuma pessoa com esse documento. Ao alterar o documento e trocar o tipo de pessoa, os demais documentos serão limpados.',
  'persons.check-exists.empty-edit-document-description':
    'Informe para verificar se há uma pessoa existente ou ignore deixando vazio. Ao alterar o documento e trocar o tipo de pessoa, os demais documentos serão limpados.',
  'persons.check-exists.edit-document': 'Alterar documento',

  'persons.check-exists.exists-valid-document-title': 'Documento válido!',
  'persons.check-exists.exists-valid-document-description':
    'Este documento é válido e está cadastrado para esta pessoa.',

  'persons.check-exists.exists-not-shared-title':
    'Esta pessoa já está cadastrada na empresa:',

  // Reports
  'reports.title': 'Relatórios',
  'reports.filter-title': 'Filtros',

  'reports.data-uninformed': 'Não informado',

  'reports.sections.customer-title': 'Clientes',

  'reports.sections.customers-by-cities.report-title': 'Clientes por cidades',
  'reports.sections.customers-by-cities.without-city': 'Sem cidade',
  'reports.sections.customers-by-cities.name': 'Nome',
  'reports.sections.customers-by-cities.phone-email': 'Telefone / Email',
  'reports.sections.customers-by-cities.contact': 'Contato',
  'reports.sections.customers-by-cities.address': 'Endereço',

  'reports.sections.product-title': 'Produtos',

  'reports.sections.products-by-prices.report-title': 'Produtos por preços',
  'reports.sections.products-by-prices.title': 'Título',
  'reports.sections.products-by-prices.table.title': 'Nome',
  'reports.sections.products-by-prices.ean': 'EAN: {value}',
  'reports.sections.products-by-prices.table.ean': 'EAN',
  'reports.sections.products-by-prices.internal-code':
    'Código interno: {value}',
  'reports.sections.products-by-prices.table.internal-code': 'Código interno',
  'reports.sections.products-by-prices.name': 'Nome',
  'reports.sections.products-by-prices.cost-value': 'Valor de custo: {value}',
  'reports.sections.products-by-prices.table.cost-value': 'Valor de custo',
  'reports.sections.products-by-prices.percentage-applied':
    'Porcentagem aplicada',
  'reports.sections.products-by-prices.price': 'Preço',

  'reports.sections.products-by-prices.filter.minimum-cost-value':
    'Mínimo valor de custo',
  'reports.sections.products-by-prices.filter.maximum-cost-value':
    'Máximo valor de custo',
  'reports.sections.products-by-prices.filter.minimum-price': 'Preço mínimo',
  'reports.sections.products-by-prices.filter.maximum-price': 'Preço máximo',

  'reports.sections.products-top-selling.report-title':
    'Produtos mais vendidos',
  'reports.sections.products-top-selling.title': 'Título',
  'reports.sections.products-top-selling.average-unit-value':
    'Média valor unitário',
  'reports.sections.products-top-selling.total': 'Total',
  'reports.sections.products-top-selling.quantity': 'Quantidade',
  'reports.sections.products-top-selling.total-average':
    'Total média valor unitário',
  'reports.sections.products-top-selling.total-quantity': 'Total quantidade',

  'reports.sections.products-requests.report-title': 'Produtos requisitados',
  'reports.sections.products-requests.product': 'Produto',
  'reports.sections.products-requests.quantity': 'Quantidade',
  'reports.sections.products-requests.priority': 'Prioridade',
  'reports.sections.products-requests.created-at': 'Criado em',

  'reports.sections.products-requests.filter.date': 'Data',

  'reports.sections.products-top-selling.filter.type': 'Tipo',
  'reports.sections.products-top-selling.filter.date': 'Data',
  'reports.sections.products-top-selling.filter.seller': 'Vendedor',
  'reports.sections.products-top-selling.filter.customer': 'Cliente',
  'reports.sections.products-top-selling.filter.list-by': 'Listar por',
  'reports.sections.products-top-selling.filter.listed-by': 'Listado por',

  'reports.generating-report-message':
    'Relatório sendo preparado, aguarde um momento!',

  'reports.of': 'de',
  'reports.empty-data': 'Não há informações para ser exibidas.',
  'reports.unavailable-report': 'Relatório indisponível no momento.',
  'reports.customized-date': 'De {start} para {end}',
  'reports.searched': 'Pesquisado: {value}',
  'reports.ordered-by': 'Ordenado por: {value} {direction}',
  'reports.direction.ascending': 'ascendente',
  'reports.direction.descending': 'descendente',

  'reports.failed-to-load-image': 'Falha ao carregar a imagem! 😢',

  // Forms
  'forms.select.dropdown-select': 'Selecionar',

  'forms.date-picker.invalid-date': 'Data inválida',
  'forms.date-picker.min-date': 'A data não deve ser anterior à data mínima',
  'forms.date-picker.max-date': 'A data não deve ser posterior à data máxima',
  'forms.date-picker.clear': 'Apagar',

  'forms.date-filter.today': 'Hoje',
  'forms.date-filter.last-7-days': 'Últimos 7 dias',
  'forms.date-filter.last-week': 'Semana anterior',
  'forms.date-filter.last-30-days': 'Últimos 30 dias',
  'forms.date-filter.last-60-days': 'Últimos 60 dias',
  'forms.date-filter.last-month': 'Mês anterior',
  'forms.date-filter.this-month': 'Mês atual',
  'forms.date-filter.next-month': 'Próximo mês',
  'forms.date-filter.this-year': 'Ano atual',
  'forms.date-filter.last-year': 'Ano anterior',
  'forms.date-filter.customized': 'Customizado',

  'forms.label.from': 'De',
  'forms.label.to': 'Para',
  'forms.label.creation': 'Criação: ',
  'forms.label.last-update': 'Última alteração: ',

  'forms.input.search': 'Pesquisar',
  'forms.input.invalid-email': 'Email inválido',
  'forms.input.invalid-document': 'Documento inválido',
  'forms.input.field-less-than': 'Campo deve ser menor que {value}',
  'forms.input.field-less-than-or-equal-to':
    'Campo deve ser menor ou igual a {value}',
  'forms.input.field-greater-than-zero': 'Campo deve ser maior que zero',
  'forms.input.field-exactly-characters':
    'Campo deve ter exatamente {value} caracteres',
  'forms.input.total-is-below-to-cost-price':
    'O total está abaixo do valor de custo',

  'forms.input.custom-validation.is-correct-minimum-value':
    'O valor mínimo deve ser menor que o valor máximo',
  'forms.input.custom-validation.is-correct-maximum-value':
    'O valor máximo deve ser maior que o valor mínimo',

  // Files
  'files.add-image': 'Adicionar imagem',
  'files.change-image': 'Alterar imagem',
  'files.delete-image': 'Excluir imagem',

  'files.error-file-size-too-big': 'O arquivo excedeu o limite de {value}!',
  'files.error-wrong-file-type':
    'Formato de arquivo inválido. Apenas {value} são válidos!',

  // Avatar
  'avatar.crop-modal.title': 'Recortar imagem',

  // Dates
  'dates.created-by': 'Criado por',
  'dates.updated-by': 'Última alteração por',
  'dates.on': 'em',
  'dates.at': 'às',
  'dates.date-interval': 'De {start} para {end}',

  // Modals - Observation
  'modals.observation': 'Observação',
};
