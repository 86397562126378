const employeesReducers = {
  getEmployeesRequest: {
    reducer: state => {
      state.loading = true;
      state.isDetailLoading = true;
    },
    prepare: ({ queryParams }) => {
      return {
        payload: {
          queryParams,
        },
      };
    },
  },
  getEmployeesSuccess: (state, action) => {
    const { data } = action.payload;

    state.list = data;
    state.loading = false;
    state.isDetailLoading = false;
    state.employeeFilterModalOpen = false;
  },
  getEmployeesFailure: state => {
    state.loading = false;
  },
  createEmployeeRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (employee, subResourceType = null) => {
      return { payload: { employee, subResourceType } };
    },
  },
  createEmployeeSuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.employee);
      state.loading = false;
    },
    prepare: employee => {
      return { payload: { employee } };
    },
  },
  createEmployeeFailure: state => {
    state.loading = false;
  },
  updateEmployeeRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newEmployee) => {
      return { payload: { id, newEmployee } };
    },
  },
  updateEmployeeSuccess: {
    reducer: (state, action) => {
      const { newEmployee } = action.payload;
      state.detail = newEmployee;

      state.list = state.list.map(employee =>
        employee.id === newEmployee.id ? newEmployee : employee
      );

      state.employeeObservationModalOpen = false;
      state.loading = false;
    },
    prepare: newEmployee => {
      return { payload: { newEmployee } };
    },
  },
  updateEmployeeFailure: state => {
    state.loading = false;
  },
  deleteEmployeeRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteEmployeeSuccess: {
    reducer: (state, action) => {
      state.list = state.list.filter(
        employee => employee.id !== action.payload.id
      );

      state.loading = false;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteEmployeeFailure: state => {
    state.loading = false;
  },
  becomeEmployeeRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  becomeEmployeeSuccess: {
    reducer: state => {
      state.loading = false;
      state.employeeAddModalOpen = false;
    },
    prepare: employee => {
      return { payload: { employee } };
    },
  },
  becomeEmployeeFailure: state => {
    state.loading = false;
  },
  getDetailsEmployeeRequest: {
    reducer: state => {
      state.detail = null;
      state.isDetailLoading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  getDetailsEmployeeSuccess: {
    reducer: (state, action) => {
      state.detail = action.payload.employee;
      state.isDetailLoading = false;
    },
    prepare: employee => {
      return { payload: { employee } };
    },
  },
  getDetailsEmployeeFailure: () => {},
  resetListEmployees: state => {
    state.loading = false;
    state.list = [];
    state.isDetailLoading = false;

    state.employeeFilterModalOpen = false;
    state.filterSearch = '';
    state.filter = null;
  },
  resetDetailsEmployee: state => {
    state.detail = null;
    state.employeeAddModalOpen = false;
    state.employeeObservationModalOpen = false;
  },
  openModalAddEmployee: state => {
    state.employeeAddModalOpen = true;
  },
  closeModalAddEmployee: state => {
    state.employeeAddModalOpen = false;
  },
  openModalEmployeeObservation: state => {
    state.employeeObservationModalOpen = true;
  },
  closeModalEmployeeObservation: state => {
    state.employeeObservationModalOpen = false;
  },
  clearNewEmployeeBranchActivity: state => {
    state.newBranchActivity = null;
  },
  clearNewEmployeeCovenant: state => {
    state.newCovenant = null;
  },
  openModalEmployeeFilter: state => {
    state.employeeFilterModalOpen = true;
  },
  closeModalEmployeeFilter: state => {
    state.employeeFilterModalOpen = false;
  },
  setSavingOnSubResource: {
    reducer: (state, action) => {
      state.shouldOpenSubResourceModal = true;
      state.subResourceType = action.payload.subResourceType;
    },
    prepare: subResourceType => {
      return { payload: { subResourceType } };
    },
  },
  resetSubResourceData: state => {
    state.shouldOpenSubResourceModal = false;
    state.subResourceType = null;
    state.openFileDialog = false;
  },
  setOpenFileDialog: state => {
    state.openFileDialog = true;
  },

  setEmployeesTableFilters: (state, action) => {
    const { modalFilters, filterSearch, closeModal } = action.payload;

    if (typeof filterSearch !== 'undefined') {
      state.filterSearch = filterSearch;
    }

    if (typeof modalFilters !== 'undefined') {
      state.filter = modalFilters;
    }

    if (closeModal) {
      state.employeeFilterModalOpen = false;
    }
  },
};

export default employeesReducers;
