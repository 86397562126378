const billToReceiveBilletsReducers = {
  getBillToReceiveBillets: {
    reducer: (state, action) => {
      const { billets } = action.payload;

      state.list = billets;
    },
    prepare: billets => {
      return { payload: { billets } };
    },
  },

  createBillToReceiveBilletRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: billet => {
      return { payload: { billet } };
    },
  },
  createBillToReceiveBilletSuccess: {
    reducer: (state, action) => {
      const { billet } = action.payload;

      state.list.unshift(billet);

      state.loading = false;
      state.billToReceiveBilletModalOpen = false;
    },
    prepare: billet => {
      return { payload: { billet } };
    },
  },
  createBillToReceiveBilletFailure: state => {
    state.loading = false;
  },

  deleteBillToReceiveBilletRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ billet_id, external_billet_id }) => {
      return { payload: { billet_id, external_billet_id } };
    },
  },
  deleteBillToReceiveBilletSuccess: {
    reducer: (state, action) => {
      const { billet_id } = action.payload;

      state.list = state.list.filter(billet => billet.id !== billet_id);

      state.loading = false;
    },
    prepare: billet_id => {
      return { payload: { billet_id } };
    },
  },
  deleteBillToReceiveBilletFailure: state => {
    state.loading = false;
  },

  openModalBillToReceiveBillet: {
    reducer: (state, action) => {
      const { billet_id } = action.payload;

      state.detail = state.list.find(billet => billet.id === billet_id) || null;

      state.billToReceiveBilletModalOpen = true;
    },
    prepare: billet_id => {
      return { payload: { billet_id } };
    },
  },
  closeModalBillToReceiveBillet: state => {
    state.detail = null;
    state.billToReceiveBilletModalOpen = false;
  },
};

export default billToReceiveBilletsReducers;
