import { createSlice } from '@reduxjs/toolkit';

import covenantsReducers from './covenantsReducers';

const initialState = {
  list: [],

  filterSearch: '',

  detail: null,
  isDetailLoading: false,
  loading: false,
  covenantModalOpen: false,
};

const covenantsSlice = createSlice({
  name: 'covenants',
  initialState,
  reducers: covenantsReducers,
});

export const { actions: covenantsActions } = covenantsSlice;
export default covenantsSlice.reducer;
