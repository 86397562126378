import { createSlice } from '@reduxjs/toolkit';

import { contactsActions } from '../contacts/contactsSlice';
import contactAddressesReducers from './contactAddressesReducers';

const initialState = {
  list: [],
  loading: false,
};

const contactAddressesSlice = createSlice({
  name: 'contactAddresses',
  initialState,
  reducers: contactAddressesReducers,
  extraReducers: builder => {
    builder.addCase(contactsActions.closeModalDetailContact, state => {
      state.list = [];
      state.loading = false;
    });

    builder.addCase(contactsActions.resetDetailsContact, state => {
      state.list = [];
      state.loading = false;
    });
  },
});

export const { actions: contactAddressesActions } = contactAddressesSlice;
export default contactAddressesSlice.reducer;
