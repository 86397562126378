import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { addressesActions } from './addressesSlice';

export function* createAddress({ payload }) {
  try {
    const { address } = payload;
    // const { person_id, address, person_route } = payload;

    // const { data } = yield call(
    //   api.post,
    //   `${person_route}/${person_id}/addresses`,
    //   address
    // );

    yield put(addressesActions.createAddressSuccess(address));

    // toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(addressesActions.createAddressFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateAddress({ payload }) {
  try {
    const { person_id, address_id, newAddress, person_route } = payload;

    const { data } = yield call(
      api.put,
      `${person_route}/${person_id}/addresses/${address_id}`,
      newAddress
    );

    yield put(addressesActions.updateAddressSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(addressesActions.updateAddressFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteAddress({ payload }) {
  const { person_id, address_id, person_route } = payload;

  const addresses = yield select(state => state.addresses.list);

  const address = addresses.find(data => data.id === address_id);

  if (address?.is_main && addresses.length > 1) {
    yield put(addressesActions.deleteAddressFailure());

    toast.error(<IntlMessages id="addresses.error-delete-is-main" />);

    return;
  }

  try {
    yield call(
      api.delete,
      `${person_route}/${person_id}/addresses/${address_id}`
    );

    yield put(addressesActions.deleteAddressSuccess(address_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(addressesActions.deleteAddressFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(addressesActions.createAddressRequest, createAddress),
  takeLatest(addressesActions.updateAddressRequest, updateAddress),
  takeLatest(addressesActions.deleteAddressRequest, deleteAddress),
]);
