const bankBillingsReducers = {
  getBillToPayChartAccounts: {
    reducer: (state, action) => {
      state.list = action.payload.chartAccounts;
    },
    prepare: chartAccounts => {
      return { payload: { chartAccounts } };
    },
  },
  createBillToPayChartAccountRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_pay_id, chartAccount) => {
      return { payload: { bill_to_pay_id, chartAccount } };
    },
  },
  createBillToPayChartAccountSuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.chartAccount);
      state.loading = false;
      state.billToPayChartAccountModalOpen = false;
    },
    prepare: chartAccount => {
      return { payload: { chartAccount } };
    },
  },
  createBillToPayChartAccountFailure: state => {
    state.loading = false;
  },
  updateBillToPayChartAccountRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_pay_id, chart_account_id, newChartAccount) => {
      return { payload: { bill_to_pay_id, chart_account_id, newChartAccount } };
    },
  },
  updateBillToPayChartAccountSuccess: {
    reducer: (state, action) => {
      const { newChartAccount } = action.payload;

      state.list = state.list.map(chartAccount =>
        chartAccount.id === newChartAccount.id ? newChartAccount : chartAccount
      );

      state.detail = null;
      state.loading = false;
      state.billToPayChartAccountModalOpen = false;
    },
    prepare: newChartAccount => {
      return { payload: { newChartAccount } };
    },
  },
  updateBillToPayChartAccountFailure: state => {
    state.loading = false;
  },
  deleteBillToPayChartAccountRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_pay_id, chart_account_id) => {
      return { payload: { bill_to_pay_id, chart_account_id } };
    },
  },
  deleteBillToPayChartAccountSuccess: {
    reducer: (state, action) => {
      const { chart_account_id } = action.payload;

      state.list = state.list.filter(
        chartAccount => chartAccount.id !== chart_account_id
      );

      if (state.detail?.id && chart_account_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: chart_account_id => {
      return { payload: { chart_account_id } };
    },
  },
  deleteBillToPayChartAccountFailure: state => {
    state.loading = false;
  },
  resetListBillToPayChartAccount: state => {
    state.list = [];
  },
  openModalBillToPayChartAccount: {
    reducer: (state, action) => {
      const { chart_account_id } = action.payload;

      state.detail =
        state.list.find(chartAccount => chartAccount.id === chart_account_id) ||
        null;

      state.billToPayChartAccountModalOpen = true;
    },
    prepare: chart_account_id => {
      return { payload: { chart_account_id } };
    },
  },
  closeModalBillToPayChartAccount: state => {
    state.billToPayChartAccountModalOpen = false;
    state.detail = null;
  },
};

export default bankBillingsReducers;
