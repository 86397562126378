const contactFilesReducers = {
  getContactFiles: {
    reducer: (state, action) => {
      const { files } = action.payload;

      const contactFile = files.find(
        file => file.type === 'image/profile' && file.is_main
      );

      if (contactFile) {
        state.detail = contactFile;
      }

      state.list = files;
    },
    prepare: files => {
      return {
        payload: {
          files,
        },
      };
    },
  },

  resetListContactFile: state => {
    state.list = [];
  },
};

export default contactFilesReducers;
