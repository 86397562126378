const phonesReducers = {
  getPhones: {
    reducer: (state, action) => {
      state.list = action.payload.phones;
    },
    prepare: phones => {
      return { payload: { phones } };
    },
  },
  createPhoneRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, phone, person_route) => {
      return { payload: { person_id, phone, person_route } };
    },
  },
  createPhoneSuccess: {
    reducer: (state, action) => {
      const { is_main } = action.payload.phone;

      if (is_main) {
        state.list = state.list.map(phone => ({ ...phone, is_main: false }));
      }

      state.list.unshift(action.payload.phone);
      state.loading = false;
      state.phoneModalOpen = false;
    },
    prepare: phone => {
      return { payload: { phone } };
    },
  },
  createPhoneFailure: state => {
    state.loading = false;
  },
  updatePhoneRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, phone_id, newPhone, person_route) => {
      return { payload: { person_id, phone_id, newPhone, person_route } };
    },
  },
  updatePhoneSuccess: {
    reducer: (state, action) => {
      const { newPhone } = action.payload;
      const { is_main } = newPhone;
      let data = [];

      if (is_main) {
        const removeMain = state.list.map(phone => ({
          ...phone,
          is_main: false,
        }));

        data = removeMain;
      } else {
        data = state.list;
      }

      state.list = data.map(phone =>
        phone.id === newPhone.id ? newPhone : phone
      );

      state.detail = null;
      state.loading = false;
      state.phoneModalOpen = false;
    },
    prepare: newPhone => {
      return { payload: { newPhone } };
    },
  },
  updatePhoneFailure: state => {
    state.loading = false;
  },
  deletePhoneRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, phone_id, person_route) => {
      return { payload: { person_id, phone_id, person_route } };
    },
  },
  deletePhoneSuccess: {
    reducer: (state, action) => {
      const { phone_id } = action.payload;

      state.list = state.list.filter(phone => phone.id !== phone_id);

      if (state.detail?.id && phone_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: phone_id => {
      return { payload: { phone_id } };
    },
  },
  deletePhoneFailure: state => {
    state.loading = false;
  },
  resetListPhone: state => {
    state.list = [];
  },
  openModalPhone: {
    reducer: (state, action) => {
      const { phone_id } = action.payload;

      state.detail = state.list.find(phone => phone.id === phone_id) || null;

      state.phoneModalOpen = true;
    },
    prepare: phone_id => {
      return { payload: { phone_id } };
    },
  },
  closeModalPhone: state => {
    state.phoneModalOpen = false;
    state.detail = null;
  },
};

export default phonesReducers;
