import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { billToPayCostCentersActions } from './billToPayCostCentersSlice';

// TODO: Posteriormente, remover a condição para poder apenas adicionar um elemento
export function* createBillToPayCostCenter({ payload }) {
  try {
    const { bill_to_pay_id, costCenter } = payload;

    const costCenters = yield select(state => state.billToPayCostCenters.list);

    if (costCenters.length > 0) {
      yield put(billToPayCostCentersActions.createBillToPayCostCenterFailure());

      toast.error(
        <IntlMessages id="bills.cost-centers.error-cost-center-create" />
      );

      return;
    }

    const { data } = yield call(
      api.post,
      `bills/${bill_to_pay_id}/cost-centers`,
      costCenter
    );

    yield put(
      billToPayCostCentersActions.createBillToPayCostCenterSuccess(data)
    );

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(billToPayCostCentersActions.createBillToPayCostCenterFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToPayCostCenter({ payload }) {
  try {
    const { bill_to_pay_id, cost_center_id, newCostCenter } = payload;

    const { data } = yield call(
      api.put,
      `bills/${bill_to_pay_id}/cost-centers/${cost_center_id}`,
      newCostCenter
    );

    yield put(
      billToPayCostCentersActions.updateBillToPayCostCenterSuccess(data)
    );

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(billToPayCostCentersActions.updateBillToPayCostCenterFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBillToPayCostCenter({ payload }) {
  try {
    const { bill_to_pay_id, cost_center_id } = payload;
    yield call(
      api.delete,
      `bills/${bill_to_pay_id}/cost-centers/${cost_center_id}`
    );

    yield put(
      billToPayCostCentersActions.deleteBillToPayCostCenterSuccess(
        cost_center_id
      )
    );

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(billToPayCostCentersActions.deleteBillToPayCostCenterFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    billToPayCostCentersActions.createBillToPayCostCenterRequest,
    createBillToPayCostCenter
  ),
  takeLatest(
    billToPayCostCentersActions.updateBillToPayCostCenterRequest,
    updateBillToPayCostCenter
  ),
  takeLatest(
    billToPayCostCentersActions.deleteBillToPayCostCenterRequest,
    deleteBillToPayCostCenter
  ),
]);
