import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { productRequestActions } from './productRequestSlice';

export function* createProductRequest({ payload }) {
  try {
    const { data } = payload;

    yield call(api.post, 'product-requests', data);

    yield put(productRequestActions.createProductRequestSuccess());

    toast.success(<IntlMessages id="orders.message.product-request.success" />);
  } catch (err) {
    yield put(productRequestActions.createProductRequestFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="orders.message.product-request.failure" />);
  }
}

export default all([
  takeLatest(
    'productRequest/createProductRequestRequest',
    createProductRequest
  ),
]);
