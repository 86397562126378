const contactPhonesReducers = {
  getContactPhones: {
    reducer: (state, action) => {
      const { phones } = action.payload;

      state.list = phones;
    },
    prepare: phones => {
      return {
        payload: {
          phones,
        },
      };
    },
  },

  resetListContactPhone: state => {
    state.list = [];
  },
};

export default contactPhonesReducers;
