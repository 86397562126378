import { combineReducers } from '@reduxjs/toolkit';

import addresses from './addresses/addressesSlice';
import auth from './auth/authSlice';
import billsToPay from './billsToPay/billsToPaySlice';
import billsToReceive from './billsToReceive/billsToReceiveSlice';
import billsToReceivePixs from './billToReceivePixs/billToReceivePixsSlice';
import branchesActivities from './branchesActivities/branchesActivitiesSlice';
import companies from './companies/companySlice';
import companyProfile from './companyProfile/companyProfileSlice';
import contacts from './contacts/contactsSlice';
import covenants from './covenants/covenantsSlice';
import customers from './customers/customersSlice';
import emails from './emails/emailsSlice';
import employees from './employees/employeesSlice';
import files from './files/filesSlice';
import orders from './orders/orderSlice';
import orderItems from './orderItems/orderItemSlice';
import persons from './persons/personsSlice';
import phones from './phones/phonesSlice';
import productRequest from './productRequest/productRequestSlice';
import settings from './settings/settingsSlice';
import theme from './theme/themeSlice';
import user from './user/userSlice';
import userFiles from './userFiles/userFileSlice';
import billToPayChartAccounts from './billToPayChartAccounts/billToPayChartAccountsSlice';
import billToPayCostCenters from './billToPayCostCenters/billToPayCostCentersSlice';
import billToReceiveBillets from './billToReceiveBillets/billToReceiveBilletsSlice';
import billets from './billets/billetsSlice';
import billToReceiveChartAccounts from './billToReceiveChartAccounts/billToReceiveChartAccountsSlice';
import contactAddresses from './contactAddresses/contactAddressesSlice';
import contactEmails from './contactEmails/contactEmailsSlice';
import contactFiles from './contactFiles/contactFilesSlice';
import contactPhones from './contactPhones/contactPhonesSlice';

const rootReducer = combineReducers({
  auth,
  companies,
  orders,
  orderItems,
  productRequest,
  settings,
  theme,
  user,
  userFiles,
  companyProfile,
  customers,
  files,
  addresses,
  billsToPay,
  billsToReceive,
  billsToReceivePixs,
  contacts,
  covenants,
  emails,
  employees,
  persons,
  phones,
  branchesActivities,
  billToPayChartAccounts,
  billToPayCostCenters,
  billToReceiveBillets,
  billets,
  billToReceiveChartAccounts,
  contactAddresses,
  contactEmails,
  contactFiles,
  contactPhones,
});

export default rootReducer;
