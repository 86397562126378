import { createSlice } from '@reduxjs/toolkit';

import { branchesActivitiesActions } from '../branchesActivities/branchesActivitiesSlice';
import { covenantsActions } from '../covenants/covenantsSlice';
import { personsActions } from '../persons/personsSlice';
import employeesReducers from './employeesReducers';

const initialState = {
  list: [],
  detail: null,

  filter: null,
  filterSearch: '',

  isDetailLoading: false,
  loading: false,
  employeeAddModalOpen: false,
  employeeObservationModalOpen: false,
  employeeFilterModalOpen: false,
  newBranchActivity: null,
  newCovenant: null,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  openFileDialog: false,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: employeesReducers,
  extraReducers: builder => {
    builder.addCase(
      branchesActivitiesActions.createBranchActivitySuccess,
      (state, action) => {
        const { source, branchActivity } = action.payload;

        if (source === 'EMPLOYEE') {
          state.newBranchActivity = branchActivity;
        }
      }
    );
    builder.addCase(covenantsActions.createCovenantSuccess, (state, action) => {
      const { source, covenant } = action.payload;

      if (source === 'EMPLOYEE') {
        state.newCovenant = covenant;
      }
    });
    builder.addCase(personsActions.resetDetailsPerson, state => {
      state.employeeObservationModalOpen = false;
    });
  },
});

export const { actions: employeesActions } = employeesSlice;
export default employeesSlice.reducer;
