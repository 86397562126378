import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Business } from '@mui/icons-material';
import { Box, Typography, Avatar, ListItem, alpha } from '@mui/material';

export default function CompanySection() {
  const navigate = useNavigate();

  const { company } = useSelector(state => state.auth);

  const handleNavigateToCatalog = () => {
    navigate('/catalog');
  };

  const companyAvatar = company.files?.length > 0 ? company.files[0].url : '';

  return (
    <ListItem
      sx={{
        backgroundColor: theme => theme.dashboardSidebar.header.background,
        padding: 1.5,
        cursor: 'pointer',
      }}
      onClick={handleNavigateToCatalog}
    >
      <Avatar
        variant="rounded"
        sx={{ width: 50, height: 50, marginRight: 1.2 }}
        src={companyAvatar}
      >
        <Business sx={{ fontSize: 35 }} />
      </Avatar>

      <Box sx={{ padding: 0.5, overflow: 'hidden' }}>
        <Typography
          sx={{
            color: theme => theme.dashboardSidebar.header.color,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            WebkitLineClamp: 1,
          }}
        >
          {company.nickname ? company.nickname : company.name}
        </Typography>
        {company.nickname && (
          <Typography
            variant="caption"
            sx={{
              color: theme => alpha(theme.dashboardSidebar.header.color, 0.8),
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              WebkitLineClamp: 1,
              display: 'block',
            }}
          >
            {company.name}
          </Typography>
        )}
      </Box>
    </ListItem>
  );
}
