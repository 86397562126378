import { createSlice } from '@reduxjs/toolkit';

import { contactsActions } from '../contacts/contactsSlice';
import personsReducers from './personsReducers';

const initialState = {
  detail: null,
  found: null,
  statusCheck: false,
  loading: false,
  isDetailLoading: false,
};

const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: personsReducers,
  extraReducers: builder => {
    builder.addCase(contactsActions.resetDetailsContact, state => {
      state.found = null;
    });
  },
});

export const { actions: personsActions } = personsSlice;
export default personsSlice.reducer;
