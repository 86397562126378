import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { emailsActions } from './emailsSlice';

export function* createEmail({ payload }) {
  try {
    const { email } = payload;
    // const { person_id, email, person_route } = payload;

    // const { data } = yield call(
    //   api.post,
    //   `${person_route}/${person_id}/emails`,
    //   email
    // );

    yield put(emailsActions.createEmailSuccess(email));

    // toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(emailsActions.createEmailFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateEmail({ payload }) {
  try {
    const { person_id, email_id, newEmail, person_route } = payload;

    const { data } = yield call(
      api.put,
      `${person_route}/${person_id}/emails/${email_id}`,
      newEmail
    );

    yield put(emailsActions.updateEmailSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(emailsActions.updateEmailFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteEmail({ payload }) {
  const { person_id, email_id, person_route } = payload;
  const emails = yield select(state => state.emails.list);

  const email = emails.find(data => data.id === email_id);

  if (email?.is_main && emails.length > 1) {
    yield put(emailsActions.deleteEmailFailure());

    toast.error(<IntlMessages id="emails.error-delete-is-main" />);

    return;
  }

  try {
    yield call(api.delete, `${person_route}/${person_id}/emails/${email_id}`);

    yield put(emailsActions.deleteEmailSuccess(email_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(emailsActions.deleteEmailFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(emailsActions.createEmailRequest, createEmail),
  takeLatest(emailsActions.updateEmailRequest, updateEmail),
  takeLatest(emailsActions.deleteEmailRequest, deleteEmail),
]);
