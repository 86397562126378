import { createSlice } from '@reduxjs/toolkit';

import emailsReducers from './emailsReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  emailModalOpen: false,
};

const emailsSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: emailsReducers,
});

export const { actions: emailsActions } = emailsSlice;
export default emailsSlice.reducer;
