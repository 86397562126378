import { createSlice } from '@reduxjs/toolkit';

import branchesActivitiesReducers from './branchesActivitiesReducers';

const initialState = {
  list: [],

  detail: null,
  filterSearch: '',
  isDetailLoading: false,
  loading: false,
  branchActivityModalOpen: false,
};

const branchesActivitiesSlice = createSlice({
  name: 'branchesActivities',
  initialState,
  reducers: branchesActivitiesReducers,
});

export const { actions: branchesActivitiesActions } = branchesActivitiesSlice;
export default branchesActivitiesSlice.reducer;
