import { createSlice } from '@reduxjs/toolkit';

import billetsReducers from './billetsReducers';

const initialState = {
  loading: false,
  list: [],
  billet: null,
  detail: null,
  isDetailLoading: false,
  billetIssueModalOpen: false,
  billetPrintModalOpen: false,
};

const billetsSlice = createSlice({
  name: 'billets',
  initialState,
  reducers: billetsReducers,
});

export const { actions: billetsActions } = billetsSlice;
export default billetsSlice.reducer;
