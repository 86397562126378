import { all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import authSagas from './auth/authSagas';
import companySagas from './companies/companySagas';
import orderSagas from './orders/orderSagas';
import orderItemSagas from './orderItems/orderItemSagas';
import productRequestSagas from './productRequest/productRequestSagas';
import userSagas from './user/userSagas';
import customersSagas from './customers/customersSagas';
import addressesSagas from './addresses/addressesSagas';
import billsToPaySagas from './billsToPay/billsToPaySagas';
import billsToReceiveSagas from './billsToReceive/billsToReceiveSagas';
import billToReceivePixsSagas from './billToReceivePixs/billToReceivePixsSagas';
import branchesActivitiesSagas from './branchesActivities/branchesActivitiesSagas';
import contactsSagas from './contacts/contactsSagas';
import covenantsSagas from './covenants/covenantsSagas';
import emailsSagas from './emails/emailsSagas';
import employeesSagas from './employees/employeesSagas';
import filesSagas from './files/filesSagas';
import personsSagas from './persons/personsSagas';
import phonesSagas from './phones/phonesSagas';
import billToPayChartAccountsSagas from './billToPayChartAccounts/billToPayChartAccountsSagas';
import billToPayCostCentersSagas from './billToPayCostCenters/billToPayCostCentersSagas';
import billToReceiveBilletsSagas from './billToReceiveBillets/billToReceiveBilletsSagas';
import billetsSagas from './billets/billetsSagas';
import billToReceiveChartAccounts from './billToReceiveChartAccounts/billToReceiveChartAccountsSagas';

export default function* rootSaga() {
  return yield all([
    authSagas,
    companySagas,
    orderSagas,
    orderItemSagas,
    productRequestSagas,
    userSagas,
    customersSagas,
    addressesSagas,
    billsToPaySagas,
    billsToReceiveSagas,
    billToReceivePixsSagas,
    branchesActivitiesSagas,
    contactsSagas,
    covenantsSagas,
    emailsSagas,
    employeesSagas,
    filesSagas,
    personsSagas,
    phonesSagas,
    billToPayChartAccountsSagas,
    billToPayCostCentersSagas,
    billToReceiveBilletsSagas,
    billetsSagas,
    billToReceiveChartAccounts,
  ]);
}
