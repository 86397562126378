import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import historyService from '~/services/history';
import IntlMessages from '~/utils/IntlMessages';

import { billetsActions } from './billetsSlice';

export function* getBillets({ payload }) {
  try {
    const { queryParams } = payload;

    const { data } = yield call(api.get, `billets${queryParams}`);

    yield put(billetsActions.getBilletsSuccess({ data }));
  } catch (err) {
    yield put(billetsActions.getBilletsFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createBillet({ payload }) {
  try {
    const { data: billet } = payload;

    const { data } = yield call(api.post, 'billets', billet);

    if (data.success.length) {
      yield put(billetsActions.issueBilletSuccess(data.success));
      toast.success(<IntlMessages id="messages.save-success" />);
    } else if (data.failure.length) {
      yield put(billetsActions.issueBilletFailure());

      toast.error(<IntlMessages id="billets.failed-to-generate-billet" />);
    }
  } catch (err) {
    yield put(billetsActions.issueBilletFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* deleteBillet({ payload }) {
  try {
    const { id, external_billet_id } = payload;

    yield call(
      api.delete,
      `billets/${id}?external_billet_id=${external_billet_id}`
    );

    yield put(billetsActions.deleteBilletSuccess(id));

    historyService.push('/bank-billings');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(billetsActions.deleteBilletFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsBillet({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `billets/${id}`);

    yield put(billetsActions.getDetailsBilletSuccess(data));
  } catch (err) {
    yield put(billetsActions.getDetailsBilletFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest(billetsActions.getBilletsRequest, getBillets),
  takeLatest(billetsActions.getDetailsBilletRequest, getDetailsBillet),
  takeLatest(billetsActions.deleteBilletRequest, deleteBillet),
  takeLatest(billetsActions.issueBilletRequest, createBillet),
]);
