import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { covenantsActions } from './covenantsSlice';

export function* getCovenants({ payload }) {
  try {
    const { queryParams } = payload;

    const { data } = yield call(api.get, `covenants${queryParams}`);

    yield put(covenantsActions.getCovenantsSuccess({ data }));
  } catch (err) {
    yield put(covenantsActions.getCovenantsFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createCovenant({ payload }) {
  try {
    const { covenant, source } = payload;

    const { data } = yield call(api.post, 'covenants', covenant);

    yield put(covenantsActions.createCovenantSuccess(data, source));

    if (source) {
      toast.success(<IntlMessages id="covenants.create-covenant-success" />);
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(covenantsActions.createCovenantFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateCovenant({ payload }) {
  try {
    const { id, newCovenant } = payload;

    const { data } = yield call(api.put, `covenants/${id}`, newCovenant);

    yield put(covenantsActions.updateCovenantSuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(covenantsActions.updateCovenantFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteCovenant({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `covenants/${id}`);

    yield put(covenantsActions.deleteCovenantSuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(covenantsActions.deleteCovenantFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(covenantsActions.getCovenantsRequest, getCovenants),
  takeLatest(covenantsActions.createCovenantRequest, createCovenant),
  takeLatest(covenantsActions.updateCovenantRequest, updateCovenant),
  takeLatest(covenantsActions.deleteCovenantRequest, deleteCovenant),
]);
