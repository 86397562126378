import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { branchesActivitiesActions } from './branchesActivitiesSlice';

export function* getBranchesActivities({ payload }) {
  try {
    const { queryParams } = payload;

    const { data } = yield call(api.get, `branches-activities${queryParams}`);

    yield put(
      branchesActivitiesActions.getBranchesActivitiesSuccess({
        data,
      })
    );
  } catch (err) {
    yield put(branchesActivitiesActions.getBranchesActivitiesFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createBranchActivity({ payload }) {
  try {
    const { branchActivity, source } = payload;

    const { data } = yield call(
      api.post,
      'branches-activities',
      branchActivity
    );

    yield put(
      branchesActivitiesActions.createBranchActivitySuccess(data, source)
    );

    if (source) {
      toast.success(
        <IntlMessages id="branches-activities.create-branch-activity-success" />
      );
    } else {
      toast.success(<IntlMessages id="messages.save-success" />);
    }
  } catch (err) {
    yield put(branchesActivitiesActions.createBranchActivityFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBranchActivity({ payload }) {
  try {
    const { id, newBranchActivity } = payload;

    const { data } = yield call(
      api.put,
      `branches-activities/${id}`,
      newBranchActivity
    );

    yield put(branchesActivitiesActions.updateBranchActivitySuccess(data));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(branchesActivitiesActions.updateBranchActivityFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteBranchActivity({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `branches-activities/${id}`);

    yield put(branchesActivitiesActions.deleteBranchActivitySuccess(id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(branchesActivitiesActions.deleteBranchActivityFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    branchesActivitiesActions.getBranchesActivitiesRequest,
    getBranchesActivities
  ),
  takeLatest(
    branchesActivitiesActions.createBranchActivityRequest,
    createBranchActivity
  ),
  takeLatest(
    branchesActivitiesActions.updateBranchActivityRequest,
    updateBranchActivity
  ),
  takeLatest(
    branchesActivitiesActions.deleteBranchActivityRequest,
    deleteBranchActivity
  ),
]);
