import { createSlice } from '@reduxjs/toolkit';

import { contactsActions } from '../contacts/contactsSlice';
import contactFilesReducers from './contactFilesReducers';

const initialState = {
  list: [],
  loading: false,
  detail: null,
};

const contactFilesSlice = createSlice({
  name: 'contactFiles',
  initialState,
  reducers: contactFilesReducers,
  extraReducers: builder => {
    builder.addCase(contactsActions.closeModalDetailContact, state => {
      state.list = [];
      state.loading = false;
      state.detail = null;
    });

    builder.addCase(contactsActions.resetDetailsContact, state => {
      state.list = [];
      state.loading = false;
      state.detail = null;
    });
  },
});

export const { actions: contactFilesActions } = contactFilesSlice;
export default contactFilesSlice.reducer;
