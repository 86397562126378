import React from 'react';

import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

const BaseTooltip = React.forwardRef(function BaseTooltip(
  { children, ...rest },
  ref
) {
  return (
    <Tooltip {...rest} ref={ref}>
      {children}
    </Tooltip>
  );
});

BaseTooltip.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

BaseTooltip.defaultProps = {
  children: null,
  sx: {},
};

export default BaseTooltip;
