import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { billsToReceiveActions } from '../billsToReceive/billsToReceiveSlice';
import { billToReceivePixsActions } from './billToReceivePixsSlice';

export function* createBillToReceivePix({ payload }) {
  try {
    const { pix, pixType } = payload;

    const { data } = yield call(api.post, '/pixs', pix);

    yield put(
      billToReceivePixsActions.createBillToReceivePixSuccess(data, pixType)
    );

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(billToReceivePixsActions.createBillToReceivePixFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateBillToReceivePix({ payload }) {
  try {
    const { pix_id } = payload;

    const billToReceivePixDetail = yield select(
      state => state.billToReceivePixs.detail
    );

    const { data } = yield call(api.put, `pixs/${pix_id}`);

    if (billToReceivePixDetail?.status !== data.status) {
      const billsToReceiveId = yield select(
        state => state.billsToReceive.detail?.id
      );

      yield put(
        billsToReceiveActions.getDetailsBillToReceiveRequest(billsToReceiveId)
      );
    }

    yield put(billToReceivePixsActions.updateBillToReceivePixSuccess(data));

    toast.success(<IntlMessages id="messages.update-success" />);
  } catch (err) {
    yield put(billToReceivePixsActions.updateBillToReceivePixFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.update-failure" />);
  }
}

export function* settlementBillToReceivePix({ payload }) {
  try {
    const { pix_id, newPix } = payload;

    const { data } = yield call(api.put, `pixs/${pix_id}`, newPix);

    yield put(billToReceivePixsActions.settlementBillToReceivePixSuccess(data));

    const billsToReceiveId = yield select(
      state => state.billsToReceive.detail?.id
    );

    yield put(
      billsToReceiveActions.getDetailsBillToReceiveRequest(billsToReceiveId)
    );

    toast.success(<IntlMessages id="messages.settlement-success" />);
  } catch (err) {
    yield put(billToReceivePixsActions.settlementBillToReceivePixFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.settlement-failure" />);
  }
}

export function* deleteBillToReceivePix({ payload }) {
  try {
    const { pix_id } = payload;

    yield call(api.delete, `pixs/${pix_id}`);

    yield put(billToReceivePixsActions.deleteBillToReceivePixSuccess(pix_id));

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(billToReceivePixsActions.deleteBillToReceivePixFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    billToReceivePixsActions.createBillToReceivePixRequest,
    createBillToReceivePix
  ),
  takeLatest(
    billToReceivePixsActions.updateBillToReceivePixRequest,
    updateBillToReceivePix
  ),
  takeLatest(
    billToReceivePixsActions.settlementBillToReceivePixRequest,
    settlementBillToReceivePix
  ),
  takeLatest(
    billToReceivePixsActions.deleteBillToReceivePixRequest,
    deleteBillToReceivePix
  ),
]);
