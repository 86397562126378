const customersReducers = {
  createCustomerRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (customer, subResourceType = null) => {
      return { payload: { customer, subResourceType } };
    },
  },
  createCustomerSuccess: {
    reducer: state => {
      state.loading = false;
    },
    prepare: customer => {
      return { payload: { customer } };
    },
  },
  createCustomerFailure: state => {
    state.loading = false;
  },
  updateCustomerRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newCustomer) => {
      return { payload: { id, newCustomer } };
    },
  },
  updateCustomerSuccess: {
    reducer: (state, action) => {
      const { newCustomer } = action.payload;
      state.detail = newCustomer;

      if (
        !state.detailUpdatedOnce &&
        state.page > 0 &&
        state.listLength === 1
      ) {
        state.page -= 1;
        state.detailUpdatedOnce = true;
      }

      state.customerObservationModalOpen = false;
      state.loading = false;
    },
    prepare: newCustomer => {
      return { payload: { newCustomer } };
    },
  },
  updateCustomerFailure: state => {
    state.loading = false;
  },
  deleteCustomerRequest: {
    reducer: (state, action) => {
      const { isFromDetail } = action.payload;

      if (isFromDetail) {
        if (state.page > 0 && state.listLength === 1) {
          state.page -= 1;
        }
      }

      state.loading = true;
    },
    prepare: ({ id, fetchList = null, isFromDetail = false }) => {
      return { payload: { id, fetchList, isFromDetail } };
    },
  },
  deleteCustomerSuccess: {
    reducer: state => {
      state.loading = false;
    },
    prepare: (isFromDetail = false) => {
      return { payload: { isFromDetail } };
    },
  },
  deleteCustomerFailure: state => {
    state.loading = false;
  },
  becomeCustomerRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  becomeCustomerSuccess: {
    reducer: state => {
      state.loading = false;
      state.customerAddModalOpen = false;
    },
    prepare: customer => {
      return { payload: { customer } };
    },
  },
  becomeCustomerFailure: state => {
    state.loading = false;
  },
  getDetailsCustomerRequest: {
    reducer: state => {
      state.detail = null;
      state.isDetailLoading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  getDetailsCustomerSuccess: {
    reducer: (state, action) => {
      state.detail = action.payload.customer;
      state.isDetailLoading = false;
    },
    prepare: customer => {
      return { payload: { customer } };
    },
  },
  getDetailsCustomerFailure: () => {},
  resetListCustomers: state => {
    state.loading = false;
    state.isDetailLoading = false;
    state.customerFilterModalOpen = false;
    state.filterSearch = '';
    state.filterColumnsOrder = null;
    state.filter = null;

    state.page = 0;
    state.rowsPerPage = 10;
    state.listLength = 0;
  },
  resetDetailsCustomer: state => {
    state.detail = null;
    state.customerAddModalOpen = false;
    state.customerObservationModalOpen = false;
    state.detailUpdatedOnce = false;
  },
  openModalAddCustomer: state => {
    state.customerAddModalOpen = true;
  },
  closeModalAddCustomer: state => {
    state.customerAddModalOpen = false;
  },
  openModalCustomerObservation: state => {
    state.customerObservationModalOpen = true;
  },
  closeModalCustomerObservation: state => {
    state.customerObservationModalOpen = false;
  },
  clearNewCustomerBranchActivity: state => {
    state.newBranchActivity = null;
  },
  clearNewCustomerCovenant: state => {
    state.newCovenant = null;
  },
  setSavingOnSubResource: {
    reducer: (state, action) => {
      state.shouldOpenSubResourceModal = true;
      state.subResourceType = action.payload.subResourceType;
    },
    prepare: subResourceType => {
      return { payload: { subResourceType } };
    },
  },
  resetSubResourceData: state => {
    state.shouldOpenSubResourceModal = false;
    state.subResourceType = null;
    state.openFileDialog = false;
  },
  setOpenFileDialog: state => {
    state.openFileDialog = true;
  },
  openModalCustomerFilter: state => {
    state.customerFilterModalOpen = true;
  },
  closeModalCustomerFilter: state => {
    state.customerFilterModalOpen = false;
  },
  setCustomerTableFilters: (state, action) => {
    const {
      modalFilters,
      filterColumnsOrder,
      filterSearch,
      closeModal,
      rowsPerPage,
      currentPage,
      listLength,
    } = action.payload;

    if (typeof listLength !== 'undefined') {
      state.listLength = listLength;
    }

    if (filterColumnsOrder) {
      state.filterColumnsOrder = {
        field: filterColumnsOrder?.field,
        direction: filterColumnsOrder?.direction,
      };
    }

    if (typeof filterSearch !== 'undefined') {
      state.filterSearch = filterSearch;
    }

    if (typeof modalFilters !== 'undefined') {
      state.filter = modalFilters;
    }

    if (typeof rowsPerPage !== 'undefined') {
      state.rowsPerPage = rowsPerPage;
    }

    if (typeof currentPage !== 'undefined') {
      state.page = currentPage;
    }

    if (closeModal) {
      state.customerFilterModalOpen = false;
    }
  },
  setCustomerIsDetailLoading: state => {
    state.isDetailLoading = true;
  },
};

export default customersReducers;
