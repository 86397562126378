import React from 'react';

import PropTypes from 'prop-types';

import iconComponents from './icons';

export default function BaseIcon({ name, size = 'small', type, sx, ...rest }) {
  const Component = iconComponents[name];

  if (!Component) return null;

  const componentType = type && Component.type ? Component.type[type] : null;

  return (
    <Component.Name
      sx={[
        { ...Component.size[size || 'small'] },
        { ...componentType },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );
}

BaseIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

BaseIcon.defaultProps = {
  size: 'small',
  type: null,
  sx: {},
};
