const billToPayCostCentersReducers = {
  getBillToPayCostCenters: {
    reducer: (state, action) => {
      state.list = action.payload.costCenters;
    },
    prepare: costCenters => {
      return { payload: { costCenters } };
    },
  },
  createBillToPayCostCenterRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_pay_id, costCenter) => {
      return { payload: { bill_to_pay_id, costCenter } };
    },
  },
  createBillToPayCostCenterSuccess: {
    reducer: (state, action) => {
      state.list.unshift(action.payload.costCenter);
      state.loading = false;
      state.billToPayCostCenterModalOpen = false;
    },
    prepare: costCenter => {
      return { payload: { costCenter } };
    },
  },
  createBillToPayCostCenterFailure: state => {
    state.loading = false;
  },
  updateBillToPayCostCenterRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_pay_id, cost_center_id, newCostCenter) => {
      return { payload: { bill_to_pay_id, cost_center_id, newCostCenter } };
    },
  },
  updateBillToPayCostCenterSuccess: {
    reducer: (state, action) => {
      const { newCostCenter } = action.payload;

      state.list = state.list.map(costCenter =>
        costCenter.id === newCostCenter.id ? newCostCenter : costCenter
      );

      state.detail = null;
      state.loading = false;
      state.billToPayCostCenterModalOpen = false;
    },
    prepare: newCostCenter => {
      return { payload: { newCostCenter } };
    },
  },
  updateBillToPayCostCenterFailure: state => {
    state.loading = false;
  },
  deleteBillToPayCostCenterRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (bill_to_pay_id, cost_center_id) => {
      return { payload: { bill_to_pay_id, cost_center_id } };
    },
  },
  deleteBillToPayCostCenterSuccess: {
    reducer: (state, action) => {
      const { cost_center_id } = action.payload;

      state.list = state.list.filter(costCenter => {
        return costCenter.id !== cost_center_id;
      });

      if (state.detail?.id && cost_center_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: cost_center_id => {
      return { payload: { cost_center_id } };
    },
  },
  deleteBillToPayCostCenterFailure: state => {
    state.loading = false;
  },
  resetListBillToPayCostCenter: state => {
    state.list = [];
  },
  openModalBillToPayCostCenter: {
    reducer: (state, action) => {
      const { cost_center_id } = action.payload;

      state.detail =
        state.list.find(costCenter => costCenter.id === cost_center_id) || null;

      state.billToPayCostCenterModalOpen = true;
    },
    prepare: cost_center_id => {
      return { payload: { cost_center_id } };
    },
  },
  closeModalBillToPayCostCenter: state => {
    state.billToPayCostCenterModalOpen = false;
    state.detail = null;
  },
};

export default billToPayCostCentersReducers;
