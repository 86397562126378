const smallSize = {
  width: '20px',
  height: '20px',
};

const mediumSize = {
  width: '24px',
  height: '24px',
};

const largeSize = {
  width: '28px',
  height: '28px',
};

export default {
  small: smallSize,
  medium: mediumSize,
  large: largeSize,
};
