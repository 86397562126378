const companyReducers = {
  // Companies list
  getCompaniesRequest: {
    reducer: state => {
      state.loading = true;
      state.requestError = true;
    },
    prepare: ({ types }) => {
      return { payload: { types } };
    },
  },
  getCompaniesSuccess: {
    reducer: (state, action) => {
      const { companies } = action.payload;

      state.list = companies;
      state.loading = false;
      state.requestError = false;
    },
    prepare: ({ companies }) => {
      return { payload: { companies } };
    },
  },
  getCompaniesFailure: state => {
    state.loading = false;
  },
  // Company detail
  getCompanyDetailRequest: {
    reducer: state => {
      state.detail = {};
      state.requestError = true;
    },
    prepare: ({ companyId }) => {
      return { payload: { companyId } };
    },
  },
  getCompanyDetailSuccess: {
    reducer: (state, action) => {
      const { company } = action.payload;

      state.detail = company;
      state.requestError = false;
    },
    prepare: ({ company }) => {
      return { payload: { company } };
    },
  },
  getCompanyDetailFailure: () => {},
  // Reset detail
  resetCompanyDetail: state => {
    state.detail = {};
  },
  clearNewCompanyCovenant: state => {
    state.newCovenant = null;
  },
  clearNewCompanyBranchActivity: state => {
    state.newBranchActivity = null;
  },
};

export default companyReducers;
