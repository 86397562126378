const personsReducers = {
  updatePersonRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (id, newPerson) => {
      return { payload: { id, newPerson } };
    },
  },
  updatePersonSuccess: {
    reducer: (state, action) => {
      const { newPerson } = action.payload;
      state.detail = newPerson;

      state.loading = false;
    },
    prepare: newPerson => {
      return { payload: { newPerson } };
    },
  },
  updatePersonFailure: state => {
    state.loading = false;
  },
  deletePersonRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deletePersonSuccess: {
    reducer: state => {
      state.loading = false;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deletePersonFailure: state => {
    state.loading = false;
  },
  getCheckExistsPersonRequest: {
    reducer: state => {
      state.statusCheck = false;
      state.loading = true;
    },
    prepare: (document, type) => {
      return { payload: { document, type } };
    },
  },
  getCheckExistsPersonSuccess: {
    reducer: (state, action) => {
      state.statusCheck = true;
      state.found = action.payload.person;
      state.loading = false;
    },
    prepare: person => {
      return { payload: { person } };
    },
  },
  getCheckExistsPersonFailure: state => {
    state.statusCheck = false;
    state.loading = false;
  },
  getDetailsPersonRequest: {
    reducer: state => {
      state.detail = null;
      state.isDetailLoading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  getDetailsPersonSuccess: {
    reducer: (state, action) => {
      state.detail = action.payload.person;
      state.isDetailLoading = false;
    },
    prepare: person => {
      return { payload: { person } };
    },
  },
  getDetailsPersonFailure: () => {},
  resetFoundPerson: state => {
    state.found = null;
  },
  resetDetailsPerson: state => {
    state.found = null;
    state.statusCheck = false;
    state.loading = false;
    state.detail = null;
  },
};

export default personsReducers;
