import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { billToReceiveBilletsActions } from './billToReceiveBilletsSlice';

export function* createBillToReceiveBillet({ payload }) {
  try {
    const { billet } = payload;

    const { data } = yield call(api.post, '/billets', billet);

    yield put(
      billToReceiveBilletsActions.createBillToReceiveBilletSuccess(data)
    );

    toast.success(<IntlMessages id="messages.save-success" />);
  } catch (err) {
    yield put(billToReceiveBilletsActions.createBillToReceiveBilletFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* deleteBillToReceiveBillet({ payload }) {
  try {
    const { billet_id, external_billet_id } = payload;

    yield call(
      api.delete,
      `billets/${billet_id}?external_billet_id=${external_billet_id}`
    );

    yield put(
      billToReceiveBilletsActions.deleteBillToReceiveBilletSuccess(billet_id)
    );

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(billToReceiveBilletsActions.deleteBillToReceiveBilletFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(
    billToReceiveBilletsActions.createBillToReceiveBilletRequest,
    createBillToReceiveBillet
  ),
  takeLatest(
    billToReceiveBilletsActions.deleteBillToReceiveBilletRequest,
    deleteBillToReceiveBillet
  ),
]);
