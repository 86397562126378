import { createSlice } from '@reduxjs/toolkit';

import billsToPayReducers from './billsToPayReducers';

const initialState = {
  listLength: 0,
  detail: null,

  filter: null,
  filterColumnsOrder: null,
  filterSearch: '',
  rowsPerPage: 10,
  page: 0,

  detailUpdatedOnce: false,
  isDetailLoading: false,
  loading: false,
  sharedDetail: false,
  settlementId: null,
  billToPaySettlementModalOpen: false,
  billToPayObservationModalOpen: false,
  billToPayFilterModalOpen: false,
  shouldOpenSubResourceModal: false,
  subResourceType: null,
  shouldFetchStatisticData: false,
};

const billsToPaySlice = createSlice({
  name: 'billsToPay',
  initialState,
  reducers: billsToPayReducers,
});

export const { actions: billsToPayActions } = billsToPaySlice;
export default billsToPaySlice.reducer;
