import React from 'react';
import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { filesActions } from './filesSlice';

export function* createFile({ payload }) {
  const { person_id, file, person_route } = payload;
  try {
    const { data } = yield call(
      api.post,
      `${person_route}/${person_id}/files`,
      file
    );

    // yield put(filesActions.createFileSuccess({ file }));
    yield put(filesActions.createFileSuccess(data));

    // toast.success(<IntlMessages id="messages.save-suc cess" />);
  } catch (err) {
    yield put(filesActions.createFileFailure());
    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateFile({ payload }) {
  try {
    const { person_id, file_id, newFile, person_route } = payload;

    yield call(
      api.put,
      `${person_route}/${person_id}/files/${file_id}`,
      newFile
    );

    // const { data } = yield call(
    //   api.put,
    //   `${person_route}/${person_id}/files/${file_id}`,
    //   newFile
    // );

    // yield put(filesActions.updateFileSuccess({ newFile }));

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(filesActions.updateFileFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteFile({ payload }) {
  try {
    const { file_id } = payload;
    // const { person_id, file_id, person_route } = payload;

    yield put(filesActions.deleteFileSuccess(file_id));
  } catch (err) {
    yield put(filesActions.deleteFileFailure());

    const catchError = err;

    if (catchError.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export default all([
  takeLatest(filesActions.createFileRequest, createFile),
  takeLatest(filesActions.updateFileRequest, updateFile),
  takeLatest(filesActions.deleteFileRequest, deleteFile),
]);
