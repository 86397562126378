import {
  AccountBalance,
  AccountBox,
  Add,
  AddBox,
  Archive,
  ArrowBack,
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  Assignment,
  AttachFile,
  AttachMoney,
  Business,
  Cancel,
  CancelOutlined,
  CircleOutlined,
  Check,
  CheckOutlined,
  CheckBoxOutlineBlank,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  ContentCopy,
  ContentPasteOutlined,
  CreditCard,
  Delete,
  DeleteOutline,
  Description,
  Done,
  Download,
  Edit,
  Email,
  ErrorOutline,
  Event,
  ExpandMore,
  FilterList,
  Group,
  Groups,
  HelpOutline,
  InsertDriveFile,
  InsertDriveFileOutlined,
  Key,
  Language,
  Link,
  LightMode,
  LocalOfferOutlined,
  LocalShipping,
  LocationOn,
  LockOutlined,
  Loop,
  Logout,
  Map,
  Menu,
  MenuBook,
  MoneyOff,
  NightsStay,
  NotificationsOutlined,
  Payment,
  People,
  Percent,
  Person,
  PersonAddAlt1Outlined,
  PersonRemoveAlt1Outlined,
  Phone,
  PieChartOutline,
  Pix,
  PriceCheck,
  Print,
  QrCode2,
  Redeem,
  Refresh,
  Remove,
  RemoveCircleOutline,
  Repeat,
  RequestPage,
  SaveAlt,
  Search,
  Settings,
  Share,
  ShoppingBag,
  ShuffleOutlined,
  Star,
  SwapHoriz,
  Sync,
  Tag,
  TaskAltOutlined,
  TrendingDown,
  TrendingUp,
  ToggleOnOutlined,
  UploadFile,
  ViewInAr,
  Visibility,
  WhatsApp,
} from '@mui/icons-material';

import defaultSize from './defaultSize';

const icons = {
  AccountBalance: {
    Name: AccountBalance,
    size: defaultSize,
  },
  AddBox: {
    Name: AddBox,
    size: defaultSize,
    type: {
      info: {
        width: '15px',
        height: '15px',
      },
    },
  },
  Add: {
    Name: Add,
    size: defaultSize,
  },
  ArrowBack: {
    Name: ArrowBack,
    size: defaultSize,
  },
  AttachFile: {
    Name: AttachFile,
    size: defaultSize,
  },
  ViewInAr: {
    Name: ViewInAr,
    size: defaultSize,
    type: {
      report: {
        width: '30px',
        height: '30px',
      },
    },
  },
  Business: {
    Name: Business,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '8px',
      },
      avatarLarge: {
        width: '80px',
        height: '80px',
      },
      avatarSmall: {
        width: '36px',
        height: '36px',
      },
      avatarMedium: {
        width: '60px',
        height: '60px',
      },
    },
  },
  Event: {
    Name: Event,
    size: defaultSize,
    type: {
      infoBar: {
        padding: '2px',
      },
    },
  },
  Cancel: {
    Name: Cancel,
    size: defaultSize,
    type: {
      form: {
        color: theme => theme.palette.error.main,
      },
    },
  },
  MenuBook: {
    Name: MenuBook,
    size: defaultSize,
  },
  Check: {
    Name: Check,
    size: defaultSize,
    type: {
      form: {
        color: theme => theme.palette.success.main,
      },
    },
  },
  ChevronLeft: {
    Name: ChevronLeft,
    size: defaultSize,
  },
  ChevronRight: {
    Name: ChevronRight,
    size: defaultSize,
  },
  Clear: {
    Name: Clear,
    size: defaultSize,
  },
  Close: {
    Name: Close,
    size: defaultSize,
    type: {
      modal: {
        color: theme => theme.palette.common.white,
      },
    },
  },
  AccountBox: {
    Name: AccountBox,
    size: defaultSize,
  },
  ContentCopy: {
    Name: ContentCopy,
    size: defaultSize,
  },
  TrendingDown: {
    Name: TrendingDown,
    size: defaultSize,
    type: {
      tooltip: {
        width: '12px',
        height: '12px',
      },
    },
  },
  Delete: {
    Name: Delete,
    size: defaultSize,
  },
  DeleteOutline: {
    Name: DeleteOutline,
    size: defaultSize,
  },
  Description: {
    Name: Description,
    size: defaultSize,
  },
  Archive: {
    Name: Archive,
    size: defaultSize,
  },
  Edit: {
    Name: Edit,
    size: defaultSize,
  },
  Email: {
    Name: Email,
    size: defaultSize,
    type: {
      darker: {
        color: theme => theme.palette.info.main,
      },
      help: {
        color: theme =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[500]
            : theme.palette.grey[600],
        marginTop: '2px',
        transition: 'all 0.5s',

        '&:hover': {
          color: theme => theme.palette.info.main,
        },
      },
    },
  },
  ExpandMore: {
    Name: ExpandMore,
    size: defaultSize,
    type: {
      panel: {
        transition: 'transform 0.4s',
      },
    },
  },
  FilterList: {
    Name: FilterList,
    size: defaultSize,
  },
  Group: {
    Name: Group,
    size: defaultSize,
  },
  HelpOutline: {
    Name: HelpOutline,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '8px',
      },
    },
  },
  TrendingUp: {
    Name: TrendingUp,
    size: defaultSize,
    type: {
      tooltip: {
        width: '12px',
        height: '12px',
      },
      form: {
        padding: '2px',
        marginTop: '4px',
        marginRight: '2px',
        color: theme => theme.palette.grey[600],
      },
    },
  },
  InsertDriveFile: {
    Name: InsertDriveFile,
    size: defaultSize,
  },
  LocalShipping: {
    Name: LocalShipping,
    size: defaultSize,
  },
  LocationOn: {
    Name: LocationOn,
    size: defaultSize,
    type: {
      colored: {
        color: theme => theme.palette.error.main,
      },
      tableSmall: {
        color: theme => theme.palette.error.main,
        width: '16px',
        height: '16px',
      },
      help: {
        color: theme =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[500]
            : theme.palette.grey[600],
        marginTop: '2px',
        transition: 'all 0.5s',
        '&:hover': {
          color: theme => theme.palette.error.main,
        },
      },
    },
  },
  Map: {
    Name: Map,
    size: defaultSize,
  },
  Menu: {
    Name: Menu,
    size: defaultSize,
  },
  AttachMoney: {
    Name: AttachMoney,
    size: defaultSize,
  },
  MoneyOff: {
    Name: MoneyOff,
    size: defaultSize,
  },
  Done: {
    Name: Done,
    size: defaultSize,
  },
  People: {
    Name: People,
    size: defaultSize,
  },
  Phone: {
    Name: Phone,
    size: defaultSize,
    type: {
      help: {
        color: theme =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[500]
            : theme.palette.grey[600],
        marginTop: '2px',
        transition: 'all 0.5s',
        '&:hover': {
          color: theme => theme.palette.grey[900],
        },
      },
    },
  },
  Pix: {
    Name: Pix,
    size: defaultSize,
  },
  Print: {
    Name: Print,
    size: defaultSize,
  },
  QrCode2: {
    Name: QrCode2,
    size: defaultSize,
    type: {
      avatar: {
        width: '100%',
        height: '100%',
      },
    },
  },
  Refresh: {
    Name: Refresh,
    size: defaultSize,
  },
  Remove: {
    Name: Remove,
    size: defaultSize,
  },
  SaveAlt: {
    Name: SaveAlt,
    size: defaultSize,
  },
  Search: {
    Name: Search,
    size: defaultSize,
  },
  Share: {
    Name: Share,
    size: defaultSize,
  },
  PriceCheck: {
    Name: PriceCheck,
    size: defaultSize,
  },
  Star: {
    Name: Star,
    size: defaultSize,
    type: {
      active: {
        color: theme => theme.palette.warning.main,
      },
      disable: {
        color: theme => theme.palette.grey[400],
      },
    },
  },
  SwapHoriz: {
    Name: SwapHoriz,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '10px',
      },
    },
  },
  Assignment: {
    Name: Assignment,
    size: defaultSize,
  },
  Visibility: {
    Name: Visibility,
    size: defaultSize,
  },
  WhatsApp: {
    Name: WhatsApp,
    size: defaultSize,
    type: {
      active: {
        color: theme => theme.palette.success.light,
      },
      disable: {
        color: theme => theme.palette.grey[400],
      },
    },
  },
  CircleOutlined: {
    Name: CircleOutlined,
    size: defaultSize,
  },
  TaskAltOutlined: {
    Name: TaskAltOutlined,
    size: defaultSize,
  },
  CancelOutlined: {
    Name: CancelOutlined,
    size: defaultSize,
    type: {
      largestError: {
        width: '48px',
        height: '48px',
        color: theme => theme.palette.error.main,
      },
    },
  },
  LockOutlined: {
    Name: LockOutlined,
    size: defaultSize,
  },
  CreditCard: {
    Name: CreditCard,
    size: defaultSize,
  },
  ErrorOutline: {
    Name: ErrorOutline,
    size: defaultSize,
  },
  CheckCircleOutline: {
    Name: CheckCircleOutline,
    size: defaultSize,
  },
  RemoveCircleOutline: {
    Name: RemoveCircleOutline,
    size: defaultSize,
  },
  ArrowCircleLeftOutlined: {
    Name: ArrowCircleLeftOutlined,
    size: defaultSize,
  },
  ArrowCircleRightOutlined: {
    Name: ArrowCircleRightOutlined,
    size: defaultSize,
  },
  LocalOfferOutlined: {
    Name: LocalOfferOutlined,
    size: defaultSize,
  },
  CheckBoxOutlineBlank: {
    Name: CheckBoxOutlineBlank,
    size: defaultSize,
  },
  Redeem: {
    Name: Redeem,
    size: defaultSize,
  },
  Repeat: {
    Name: Repeat,
    size: defaultSize,
  },
  Loop: {
    Name: Loop,
    size: defaultSize,
  },
  ShoppingBag: {
    Name: ShoppingBag,
    size: defaultSize,
  },
  Settings: {
    Name: Settings,
    size: defaultSize,
  },
  PersonAddAlt1Outlined: {
    Name: PersonAddAlt1Outlined,
    size: defaultSize,
  },
  PersonRemoveAlt1Outlined: {
    Name: PersonRemoveAlt1Outlined,
    size: defaultSize,
  },
  CheckOutlined: {
    Name: CheckOutlined,
    size: defaultSize,
  },
  NotificationsOutlined: {
    Name: NotificationsOutlined,
    size: defaultSize,
  },
  Person: {
    Name: Person,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '8px',
      },
    },
  },
  Language: {
    Name: Language,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '8px',
      },
    },
  },
  ToggleOnOutlined: {
    Name: ToggleOnOutlined,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '8px',
      },
    },
  },
  Logout: {
    Name: Logout,
    size: defaultSize,
    type: {
      menu: {
        color: theme => theme.palette.grey[600],
        marginRight: '8px',
      },
    },
  },
  Link: {
    Name: Link,
    size: defaultSize,
  },
  Percent: {
    Name: Percent,
    size: defaultSize,
  },
  Key: {
    Name: Key,
    size: defaultSize,
  },
  Tag: {
    Name: Tag,
    size: defaultSize,
  },
  Groups: {
    Name: Groups,
    size: defaultSize,
  },
  InsertDriveFileOutlined: {
    Name: InsertDriveFileOutlined,
    size: defaultSize,
  },
  ContentPasteOutlined: {
    Name: ContentPasteOutlined,
    size: defaultSize,
  },
  ShuffleOutlined: {
    Name: ShuffleOutlined,
    size: defaultSize,
  },
  PieChartOutline: {
    Name: PieChartOutline,
    size: defaultSize,
  },
  Sync: {
    Name: Sync,
    size: defaultSize,
  },
  NightsStay: {
    Name: NightsStay,
    size: defaultSize,
  },
  LightMode: {
    Name: LightMode,
    size: defaultSize,
  },
  RequestPage: {
    Name: RequestPage,
    size: defaultSize,
  },
  Payment: {
    Name: Payment,
    size: defaultSize,
  },
  Download: {
    Name: Download,
    size: defaultSize,
  },
  UploadFile: {
    Name: UploadFile,
    size: defaultSize,
  },
};

export default icons;
