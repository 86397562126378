const billetsReducers = {
  getBilletsRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ queryParams }) => {
      return {
        payload: {
          queryParams,
        },
      };
    },
  },
  getBilletsSuccess: {
    reducer: (state, action) => {
      const { data } = action.payload;

      state.list = data;
      state.loading = false;
    },
    prepare: ({ data }) => {
      return {
        payload: {
          data,
        },
      };
    },
  },
  getBilletsFailure: state => {
    state.loading = false;
  },

  getDetailsBilletRequest: {
    reducer: state => {
      state.detail = null;
      state.isDetailLoading = true;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  getDetailsBilletSuccess: {
    reducer: (state, action) => {
      const { billet } = action.payload;

      state.detail = billet;
      state.isDetailLoading = false;
    },
    prepare: billet => {
      return { payload: { billet } };
    },
  },
  getDetailsBilletFailure: state => {
    state.isDetailLoading = false;
  },

  deleteBilletRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ id, external_billet_id }) => {
      return { payload: { id, external_billet_id } };
    },
  },
  deleteBilletSuccess: {
    reducer: (state, action) => {
      state.list = state.list.filter(billet => billet.id !== action.payload.id);

      state.loading = false;
    },
    prepare: id => {
      return { payload: { id } };
    },
  },
  deleteBilletFailure: state => {
    state.loading = false;
  },

  issueBilletRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: data => {
      return { payload: { data } };
    },
  },
  issueBilletSuccess: {
    reducer: (state, action) => {
      const { data } = action.payload;

      state.loading = false;
      state.billet = data;
      state.billetIssueModalOpen = false;
      state.billetPrintModalOpen = true;
    },
    prepare: data => {
      return { payload: { data } };
    },
  },
  issueBilletFailure: state => {
    state.loading = false;
  },

  openModalBilletIssue: state => {
    state.billetIssueModalOpen = true;
  },
  closeModalBilletIssue: state => {
    state.billetIssueModalOpen = false;
  },

  openModalBilletPrint: state => {
    state.billetPrintModalOpen = true;
  },
  closeModalBilletPrint: state => {
    state.billetPrintModalOpen = false;
  },

  resetListBillets: state => {
    state.list = [];
  },
  resetDetailsBillet: state => {
    state.detail = null;
  },
};

export default billetsReducers;
