const addressesReducers = {
  getAddresses: {
    reducer: (state, action) => {
      state.list = action.payload.addresses;
    },
    prepare: addresses => {
      return { payload: { addresses } };
    },
  },
  createAddressRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, address, person_route) => {
      return { payload: { person_id, address, person_route } };
    },
  },
  createAddressSuccess: {
    reducer: (state, action) => {
      const { is_main } = action.payload.address;

      if (is_main) {
        state.list = state.list.map(address => ({
          ...address,
          is_main: false,
        }));
      }

      state.list.unshift(action.payload.address);
      state.loading = false;
      state.addressModalOpen = false;
    },
    prepare: address => {
      return { payload: { address } };
    },
  },
  createAddressFailure: state => {
    state.loading = false;
  },
  updateAddressRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, address_id, newAddress, person_route) => {
      return { payload: { person_id, address_id, newAddress, person_route } };
    },
  },
  updateAddressSuccess: {
    reducer: (state, action) => {
      const { newAddress } = action.payload;
      const { is_main } = newAddress;
      let data = [];

      if (is_main) {
        const removeMain = state.list.map(address => ({
          ...address,
          is_main: false,
        }));

        data = removeMain;
      } else {
        data = state.list;
      }

      state.list = data.map(address =>
        address.id === newAddress.id ? newAddress : address
      );

      state.detail = null;
      state.loading = false;
      state.addressModalOpen = false;
    },
    prepare: newAddress => {
      return { payload: { newAddress } };
    },
  },
  updateAddressFailure: state => {
    state.loading = false;
  },
  deleteAddressRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: (person_id, address_id, person_route) => {
      return { payload: { person_id, address_id, person_route } };
    },
  },
  deleteAddressSuccess: {
    reducer: (state, action) => {
      const { address_id } = action.payload;

      state.list = state.list.filter(address => address.id !== address_id);

      if (state.detail?.id && address_id === state.detail.id) {
        state.detail = null;
      }

      state.loading = false;
    },
    prepare: address_id => {
      return { payload: { address_id } };
    },
  },
  deleteAddressFailure: state => {
    state.loading = false;
  },
  resetListAddress: state => {
    state.list = [];
  },
  openModalAddress: {
    reducer: (state, action) => {
      const { address_id } = action.payload;

      state.detail =
        state.list.find(address => address.id === address_id) || null;

      state.addressModalOpen = true;
    },
    prepare: address_id => {
      return { payload: { address_id } };
    },
  },
  closeModalAddress: state => {
    state.addressModalOpen = false;
    state.detail = null;
  },
};

export default addressesReducers;
