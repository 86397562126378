import { createSlice } from '@reduxjs/toolkit';

import { billsToPayActions } from '../billsToPay/billsToPaySlice';
import billToPayCostCentersReducers from './billToPayCostCentersReducers';

const initialState = {
  list: [],
  detail: null,
  loading: false,
  billToPayCostCenterModalOpen: false,
};

const billToPayCostCentersSlice = createSlice({
  name: 'billToPayCostCenters',
  initialState,
  reducers: billToPayCostCentersReducers,
  extraReducers: builder => {
    builder.addCase(billsToPayActions.resetDetailsBillToPay, state => {
      state.billToPayCostCenterModalOpen = false;
      state.detail = null;
      state.list = [];
    });
  },
});

export const { actions: billToPayCostCentersActions } =
  billToPayCostCentersSlice;
export default billToPayCostCentersSlice.reducer;
