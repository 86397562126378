import React, { useState } from 'react';
import { useLocation, NavLink as RouterNavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import 'simplebar-react/dist/simplebar.min.css';
import { Typography, Collapse, List, Drawer } from '@mui/material';

import routes from '~/routes';

import CompanySection from './CompanySection';
import SidebarCategory from './SidebarCategory';
import SidebarFooter from './SidebarFooter';
import SidebarLink from './SidebarLink';
import StyledScrollbar from './StyledScrollbar';

export default function DashboardSidebar({ ...rest }) {
  const location = useLocation();

  const auth = useSelector(state => state.auth);

  const initOpenRoutes = () => {
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach(route => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = !!(route.containsHome && pathName === '/');

      _routes = { ..._routes, [route.path]: isActive || isOpen || isHome };

      if (route.children?.length > 0) {
        route.children.forEach(childrenRoute => {
          if (childrenRoute.children?.length > 0) {
            const isChildrenActive = pathName.indexOf(childrenRoute.path) === 0;
            const isChildrenOpen = childrenRoute.open;
            const isChildrenHome = !!(
              childrenRoute.containsHome && pathName === '/'
            );

            _routes = {
              ..._routes,
              [childrenRoute.path]:
                isChildrenActive || isChildrenOpen || isChildrenHome,
            };
          }
        });
      }
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = route => {
    // Collapse all elements
    setOpenRoutes(routesOpened => {
      Object.keys(openRoutes).forEach(item => {
        return {
          ...routesOpened,
          [item]: false,
        };
      });

      return routesOpened;
    });

    // Toggle selected element
    setOpenRoutes(openRoutesData => ({
      ...openRoutesData,
      [route]: !openRoutes[route],
    }));
  };

  function renderSidebarCategoriesLinks(routesChildren) {
    if (routesChildren.children) {
      return (
        <React.Fragment key={routesChildren.path}>
          <SidebarCategory
            isOpen={!openRoutes[routesChildren.path]}
            isCollapsable
            name={routesChildren.id}
            icon={routesChildren.icon}
            button
            onClick={() => toggle(routesChildren.path)}
          />

          <Collapse in={openRoutes[routesChildren.path]} timeout="auto">
            {routesChildren.children.map(nestedRoute => {
              return (
                <SidebarLink
                  key={nestedRoute.path}
                  name={nestedRoute.name}
                  to={nestedRoute.path}
                  icon={nestedRoute.icon}
                  badge={nestedRoute.badge}
                />
              );
            })}
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <SidebarCategory
        isCollapsable={false}
        name={routesChildren.id}
        to={routesChildren.path}
        activeClassName="active"
        component={RouterNavLink}
        icon={routesChildren.icon}
      />
    );
  }

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          width: 260,
        },
      }}
      {...rest}
    >
      <CompanySection />

      <StyledScrollbar autoHide clickOnTrack={false}>
        <List disablePadding>
          {routes
            .filter(route => {
              if (!auth?.user) {
                return false;
              }
              if (!auth?.user?.types || !Array.isArray(auth?.user?.types)) {
                return false;
              }
              if (
                auth?.user?.types[0]?.id === '5' &&
                route.path === '/contacts'
              ) {
                return false;
              }
              return true;
            })
            .map(category => {
              return (
                <React.Fragment key={category.path}>
                  {category.header ? (
                    <Typography
                      sx={{
                        marginTop: 2,
                        marginLeft: 3,
                        marginBottom: 0.5,
                        paddingRight: 3,
                      }}
                    >
                      {category.header}
                    </Typography>
                  ) : null}

                  {renderSidebarCategoriesLinks(category)}
                </React.Fragment>
              );
            })}
        </List>
      </StyledScrollbar>

      <SidebarFooter />
    </Drawer>
  );
}
