const contactAddressesReducers = {
  getContactAddresses: {
    reducer: (state, action) => {
      const { addresses } = action.payload;

      state.list = addresses;
    },
    prepare: addresses => {
      return {
        payload: {
          addresses,
        },
      };
    },
  },

  resetListContactAddresses: state => {
    state.list = [];
  },
};

export default contactAddressesReducers;
