const orderItemReducers = {
  // Order items list
  getOrderItems: {
    reducer: (state, action) => {
      const { items } = action.payload;

      state.list = items;
    },
    prepare: ({ items }) => {
      return {
        payload: {
          items,
        },
      };
    },
  },
  // Create order item
  createOrderItemRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({
      orderId,
      item,
      isSaveAndNew = false,
      resetFormOnSaveAndNew = null,
    }) => {
      return {
        payload: { orderId, item, isSaveAndNew, resetFormOnSaveAndNew },
      };
    },
  },
  createMultipleOrderItems: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({
      orderId,
      items,
      isSaveAndNew = false,
      resetFormOnSaveAndNew = null,
    }) => {
      return {
        payload: { orderId, items, isSaveAndNew, resetFormOnSaveAndNew },
      };
    },
  },
  createOrderItemSuccess: {
    reducer: (state, action) => {
      const { item, isSaveAndNew } = action.payload;

      state.list.push(item);
      state.loading = false;

      if (!isSaveAndNew) {
        state.orderItemModalOpen = false;
      }
    },
    prepare: ({ item, isSaveAndNew }) => {
      return { payload: { item, isSaveAndNew } };
    },
  },
  createOrderItemFailure: state => {
    state.loading = false;
  },
  // Update order item
  updateOrderItemRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ orderId, itemId, newItem }) => {
      return { payload: { orderId, itemId, newItem } };
    },
  },
  updateOrderItemSuccess: {
    reducer: (state, action) => {
      const { newItem } = action.payload;

      state.list = state.list.map(orderItem => {
        return orderItem.id === newItem.id ? newItem : orderItem;
      });
      state.detail = {};
      state.loading = false;
      state.orderItemModalOpen = false;
    },
    prepare: ({ newItem }) => {
      return { payload: { newItem } };
    },
  },
  updateOrderItemFailure: state => {
    state.loading = false;
  },
  // Delete order item
  deleteOrderItemRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ orderId, itemId, updateCart }) => {
      return { payload: { orderId, itemId, updateCart } };
    },
  },
  deleteOrderItemSuccess: {
    reducer: (state, action) => {
      const { itemId } = action.payload;

      state.list = state.list.filter(orderItem => orderItem.id !== itemId);
      state.loading = false;

      if (state.detail.id && state.detail.id === itemId) {
        state.detail = {};
      }
    },
    prepare: ({ itemId }) => {
      return { payload: { itemId } };
    },
  },
  deleteOrderItemFailure: state => {
    state.loading = false;
  },
  // Reset list
  resetListOrderItem: state => {
    state.list = [];
  },
  // Item modal
  openOrderItemModal: {
    reducer: (state, action) => {
      const { itemId } = action.payload;

      state.detail = {};
      state.orderItemModalOpen = true;

      if (itemId) {
        [state.detail] = state.list.filter(
          orderItem => orderItem.id === itemId
        );
      }
    },
    prepare: (itemId = null) => {
      return { payload: { itemId } };
    },
  },
  closeOrderItemModal: state => {
    state.orderItemModalOpen = false;
    state.detail = {};
  },
};

export default orderItemReducers;
