import { createSlice } from '@reduxjs/toolkit';

import companiesReducers from './companyReducers';

const initialState = {
  list: [],
  detail: {},
  isDetailLoading: false,
  requestError: false,
  loading: false,
  companyObservationModalOpen: false,
  newBranchActivity: null,
  newCovenant: null,
  openFileDialog: false,
};

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: companiesReducers,
  extraReducers: builder => {
    builder.addCase('auth/signOut', state => {
      state.list = [];
    });
  },
});

export const { actions: companyActions } = companySlice;
export default companySlice.reducer;
